import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingComponent } from './pricing.component';
import { RouterModule, Routes } from '@angular/router';
import { FooterModule } from '../footer/footer.module';
import { HeaderNewModule } from '../modules/header-new/header-new.module';
import { AditPricingComponent } from './components/adit-pricing/adit-pricing.component';
import { PricingService } from './pricing.service';
import { CustomTabLayoutModule } from '../modules/custom-tab-layout/custom-tab-layout.module';
import { TaxmannCommonModule } from '../taxmann-common/taxmann-common.module';
import { GeneralPricingComponent } from './components/general-pricing/general-pricing.component';
// import {AditComponent} from "../bookstore/adit/adit.component";

const pricing_routes: Routes = [
  {
    path: 'adit/pricing',
    component: PricingComponent,
    children: [
      // {
      //   path: '',
      //   component: AditComponent,
      // },
      {
        path: '',
        component: AditPricingComponent,
      }
    ]
  }
];

@NgModule({
  declarations: [PricingComponent, AditPricingComponent, GeneralPricingComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(pricing_routes),
    FooterModule,
    HeaderNewModule,
    CustomTabLayoutModule,
    TaxmannCommonModule
  ],
  providers: [PricingService]
})
export class PricingModule { }
