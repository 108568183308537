import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[alphaNumeric]'
})
export class AlphaNumericDirective {

    @Input() alphaNumeric: string;
    regexStr = /^[a-zA-Z0-9 ]+$/;

    constructor() {
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        let e = <KeyboardEvent>event;
        if (this.alphaNumeric) {
            if (e.keyCode == 190 || e.keyCode == 46 || e.keyCode == 110 || e.keyCode == 187) {
                e.preventDefault();
                return;
            }
            if (
                [46, 8, 9, 27, 13, 110, 190, 171].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (e.keyCode === 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+V
                (e.keyCode === 86 && e.ctrlKey === true) ||
                // Allow: Ctrl+X
                (e.keyCode === 88 && e.ctrlKey === true) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39) ||
                (e.keyCode === 107)) {
                // let it happen, don't do anything
                return;
            }
            let ch = e.key;
            let regEx = new RegExp(this.regexStr);
            if (!regEx.test(ch)) {
                e.preventDefault();
                return;
            }
        } else {
            return;
        }
    }
}
