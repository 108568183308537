import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';
import { HomeService } from 'src/app/service/bookstore-service/home.service';
import { CommonAlertService } from 'src/app/service/common-alert.service';
import { TaxmannHelperService } from 'src/app/service/taxmann-service/taxmann-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import {
	CheckoutFrom,
	constants,
	PaymentPlatformType,
	PublishEvents,
	RegisterPlatformType
} from 'src/constants/constant';
import { CarouselConfig } from 'src/constants/owl-carousel.constant';
import { ProductType, TaxmannProducts } from 'src/enums/enum';
import {CheckoutInformation} from "../../../interfaces/interface";
import { UserService } from 'src/app/modules/user/user.service';
import { AuditMetaData } from 'src/constants/metatag-constant';
import { MetaService } from 'src/app/service/meta.service';


@Component({
  selector: 'app-adit',
  templateUrl: './adit.component.html',
  styleUrls: ['./adit.component.scss']
})
export class AditComponent implements OnInit {
  @ViewChild('sticky_header', { static: true }) stickyHeader: ElementRef;
  refPosDown: number;
  refEnroll: number;
  refAcademic: number;
  refStories: number;
  refCall: number;
  refMainHeader: number;
  refStickyHeader: number;
  selectedTab: string;
  flag: boolean = false;
  combinedHeadersHeight: number;//height of bookstore header
  emailShare = new FormControl('', [Validators.email, Validators.required]);
  faqList: any[] = [];
  featuredbooks: any[] = [];
  eBookUrl: string = '';
  carouselConfig = CarouselConfig;
  contactForm: FormGroup
  downloadKitForm: FormGroup
  professiondata: any[] = [];
  scrollListen;
  imageUrl = this.globalUtility.makeWebpImageUrl();
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
  utm_cid: string;
  utm_statsid: string;
	taxmannProducts = TaxmannProducts;
  otherprofessionerro: boolean = false;

  constructor(private globalUtility: GlobalUtility,
    private renderer: Renderer2,
    private router: Router,
    private _taxmannHelperService: TaxmannHelperService,
    private _homeService: HomeService,
    private _commonAlertService: CommonAlertService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private metaService:MetaService,
    private activatedRoute: ActivatedRoute) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkForHeight();
  }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((value) => {
      if (value?.nav == 'contactUs') {
        setTimeout(() => {
          document.getElementById('contact-us').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          })
        }, 1000);
      };
    })
    this.metaService.updateMetaInfoInCurrentPage(AuditMetaData.metaTitle, AuditMetaData.metaDescription, window.location.href, null, AuditMetaData.metaDescription,AuditMetaData.metaKeywords,AuditMetaData.metaTitle, '', AuditMetaData.metaRobots);
    this.getCampaignData();
    if (this.globalUtility.isLoggedIn()) {
    }
    this.checkForHeight();
    this.getFeaturedbooks();
    this.getFaqs();
    this.initEvents();
    this.initContactUsForm();
    this.initdownloadKitFormForm()
    this.getProfessionData()

  }

  initContactUsForm() {
    this.contactForm = this.formBuilder.group({
      emailid: ['', [Validators.required, Validators.pattern(constants.emailPattern)]],
      userName: ['', Validators.required],
      mobile: ['', [Validators.pattern(constants.mobileNumber), Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
    });
  }

  initdownloadKitFormForm() {
    this.downloadKitForm = this.formBuilder.group({
      userName: ['', Validators.required],
      emailid: ['', [Validators.required, Validators.pattern(constants.emailPattern)]],
      mobile: ['', [Validators.pattern(constants.mobileNumber), Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      profession:['', Validators.required],
      pincode:['', Validators.required],
      other:['']
    });
  }

  getProfessionData() {
		this.userService
			.getStateProfessionAOIData('91')
			.subscribe((res) => {
        if(res?.ResponseType == 'SUCCESS'){
				this.professiondata = res?.Data?.professionList	
        }
		});
	}

  initEvents() {
    this.scrollListen = this.renderer.listen('document', 'scroll', (e) => {
      let currentPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (currentPos >= this.refPosDown) {
        this.stickyHeader.nativeElement.classList.add('fixed-sub-menu');
        if (currentPos >= this.refPosDown && currentPos < this.refEnroll) {
          this.selectedTab = '1';
        } else if (currentPos >= this.refEnroll && currentPos < this.refAcademic) {
          this.selectedTab = '2';
        } else if (currentPos >= this.refAcademic && currentPos < this.refStories) {
          this.selectedTab = '3';
        } else if (currentPos >= this.refStories && currentPos < this.refCall) {
          this.selectedTab = '4';
        }
        else if (currentPos >= this.refCall) {
          this.selectedTab = '5';
        }
        else {
          this.selectedTab = undefined
        }
      }
      else if (currentPos < this.refPosDown) this.stickyHeader.nativeElement.classList.remove('fixed-sub-menu');
    });
  }




  /**
   * get faq list
   */
  getFaqs() {
    let payload = {
      type: 'adit',
      subject: 'adit',
      searchtext: ''
    }
    this._homeService.getPricingFAQ(payload).subscribe((response) => {
      if (response.Status === 'DATA_FOUND' && response.Data) {
        this.faqList = response.Data.lst;
      }
    }, error => {
    });
  }

  /**
   * get featured books list
   */

  getFeaturedbooks() {
    this.globalUtility.showLoader = true;
    this._homeService.getNewADITBooks().subscribe((response) => {
      if (response.data) {
        this.featuredbooks = response.data;
      }
      this.globalUtility.showLoader = false;
    }, error => {
      this.globalUtility.showLoader = false;
    });
  }


  checkForHeight() {
    this.refMainHeader = document?.getElementById('header')?.getBoundingClientRect().height;
    let stickyelem = document?.getElementById('sticky-header');
    stickyelem.style.top = this.refMainHeader?.toString() + 'px';
    this.refStickyHeader = stickyelem?.getBoundingClientRect()?.height;
    this.combinedHeadersHeight = this.refMainHeader + this.refStickyHeader;
    this.refPosDown = document.getElementById('dummy')?.offsetTop - this.combinedHeadersHeight;
    this.refEnroll = document.getElementById('enroll')?.offsetTop - this.combinedHeadersHeight;
    this.refAcademic = document.getElementById('academic')?.offsetTop - this.combinedHeadersHeight;
    this.refStories = document.getElementById('stories')?.offsetTop - this.combinedHeadersHeight;
    this.refCall = document.getElementById('contact-us')?.offsetTop - this.combinedHeadersHeight;
  }

  getCampaignData() {
    this.activatedRoute.queryParams.subscribe((res) => {
      console.log("response of campaign====>", res);
      this.utm_campaign = res.utm_campaign;
      this.utm_medium = res.utm_medium;
      this.utm_source = res.utm_source;
      this.utm_cid = res.utm_cid;
      this.utm_statsid = res.utm_statsid;
      console.log("utm campaign====>", this.utm_campaign);
    });
  }

  selectActiveTab(id: string) {
    this.selectedTab = id;
  }

  /**
   * add to cart book
   * @param productId
   * @param type
   */
  addToCart(productId: number, type?: number) {
    if (this.eBookUrl == '') {
      if (this.globalUtility.isLoggedIn()) {
        let cartItemModel = {
          'prodID': productId,
          'qTy': 0,
          'cartSource': CheckoutFrom.CART
        };
        this._homeService.addUpdateCartItem(cartItemModel).subscribe((response) => {
          this._taxmannHelperService.publishEvent(PublishEvents.ADD_TO_CART);
          this._commonAlertService.successToast('Item added to your cart');
          this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
          if (type == ProductType.JOURNAL) {
			  this.globalUtility.redirectToReactCart()

		  }
        });
      } else {
        this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
		  this.globalUtility.redirectToReactLogin()
      }
    }
  }

	/**
	 * add to cart book
	 * @param productId
	 * @param type
	 */
	addToReactCart(productId: number, type?: number) {
		if (this.eBookUrl == '') {
			if (this.globalUtility.isLoggedIn()) {
				let cartItemModel = {
					productEditionId: productId,
					cartSource: "C",
					type: RegisterPlatformType.BOOKSTORE,
					quantity: 1
				};
				this._homeService.addUpdateReactCartItem(cartItemModel).subscribe((response) => {
					this._taxmannHelperService.publishEvent(PublishEvents.ADD_TO_CART);
					this._commonAlertService.successToast('Item added to your cart');
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
					// if (type == ProductType.JOURNAL) {
					// 	this.globalUtility.redirectToReactBookstoreCart()
					// }
				});
			} else {
				this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
				this.globalUtility.redirectToReactLogin()
			}
		}
	}
  /**
   * buy now book
   * @param productId
   * @param productType
   */
  buyNow(productId: number, productType: number) {

        if (this.globalUtility.isLoggedIn()) {
          let cartItemModel = {
			  productEditionId: productId,
			  cartSource: "B",
			  type: RegisterPlatformType.BOOKSTORE,
			  quantity: 1
          };
          this._homeService.addUpdateReactCartItem(cartItemModel).subscribe((response) => {
            this._taxmannHelperService.publishEvent(PublishEvents.ADD_TO_CART);
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TYPE_FROM, CheckoutFrom.BUY_NOW);
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
			  let checkoutData: CheckoutInformation =
				  this.globalUtility.getDataFromLocalStorage("checkoutData") &&
				  JSON.parse(this.globalUtility.getDataFromLocalStorage("checkoutData"));

			  if (!checkoutData) {
				  const checkoutData: CheckoutInformation = JSON.parse(this.globalUtility.getDataFromLocalStorage('checkoutData'));
				  checkoutData.checkoutCart = 'GENERAL_PRODUCT'
				  checkoutData.cartSource = 'B'
				  checkoutData.type = 'BOOKSTORE'
				  this.globalUtility.setDataOnLocalStorage('checkoutData', JSON.stringify(checkoutData));
			  }
            this.globalUtility.redirectToReactCart()
          });
        } else {
          this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
			this.globalUtility.redirectToReactLogin()

     }
  }
	/**
	 * buy now book
	 * @param productId
	 * @param productType
	 */
	reactBuyNow(productId: number, productType: number) {

				if (this.globalUtility.isLoggedIn()) {
					let cartItemModel = {
						productEditionId: [productId],
						cartSource: "B",
						type: RegisterPlatformType.BOOKSTORE,
						quantity: 1
					};
					this._homeService.buyNowADITCartItem(cartItemModel).subscribe((response) => {
						this._taxmannHelperService.publishEvent(PublishEvents.ADD_TO_CART);
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TYPE_FROM, CheckoutFrom.BUY_NOW);
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
						// this.router.navigate(['store/checkout']);
						this.globalUtility.redirectToReactCart()
					});
				} else {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
					this.globalUtility.redirectToReactLogin()
				}

	}

  /**
   * download adit kit
   */
  downloadKit() {
    if (!this.downloadKitForm.valid) {
      if(this.downloadKitForm.get('profession')?.value == '10' && !this.downloadKitForm.get('other')?.value){
        this.otherprofessionerro = true
      }
      return this.globalUtility.validateAllFormFields(this.downloadKitForm);
    }
    if(this.downloadKitForm.get('profession')?.value == '10' && !this.downloadKitForm.get('other')?.value){
      this.otherprofessionerro = true
      return
    }
    if (this.downloadKitForm.valid) {
      let filemodel = {"email":this.downloadKitForm.get('emailid')?.value,
      "name":this.downloadKitForm.get('userName')?.value,
      "mobile":this.downloadKitForm.get('mobile')?.value,
      "professionId":this.downloadKitForm.get('profession')?.value,
      "professionOther":this.downloadKitForm.get('other')?.value,
      "pinCode":this.downloadKitForm.get('pincode')?.value,
      "utmsource":  this.utm_source || "",
      "utm_medium":this.utm_medium || "",
      "utm_campaign":this.utm_campaign || '',
    }
      
      this._homeService.getKitFileData(filemodel).subscribe((response) => {
        this.utmDetails();
        this._homeService.pushUtmSourceInformation("Download Kit Action in ADIT.");
        this.downloadKitForm.reset();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this._commonAlertService.successToast('A link to download kit has been sent to your email.');
      });
    } //else this._commonAlertService.errorToast('Please provide valid email address.');
  }


  /**
   * go to adit pricing
   */
  routeTo() {
    this.router.navigate(['/adit/pricing']);
  }


  ngOnDestroy() {
    if (this.scrollListen) {
      this.scrollListen();
    }
  }

  /**
   * request callback
   */

  requestCallBack() {
    if (!this.contactForm.valid) {
      return this.globalUtility.validateAllFormFields(this.contactForm);
    }

    const obj = {
      "emailid": this.contactForm.get('emailid').value,
      "userName": this.contactForm.get('userName').value,
      "mobile": this.contactForm.get('mobile').value
    }

    this.globalUtility.showLoader = true;
    this._homeService.getADITCallBack(obj).subscribe((res) => {
      this.globalUtility.showLoader = false;
      this._commonAlertService.successToast('Your request has been successfully submitted');
      this.contactForm.reset();
    }, error => {
      this.globalUtility.showLoader = false;
    })

  }

  utmDetails() {
    let obj = {
      utmsource: this.utm_source || "",
      utmmedium: this.utm_medium || "",
      utmcampaign: this.utm_campaign,
      statsid: this.utm_statsid || "",
      campaignid: this.utm_cid || "",
      url: window.location.href,
      sourcename: window.location.pathname,
      EmailID: this.emailShare.value,
      OrderNo: "",
    };
    this._homeService.saveUtmDetails(obj).subscribe((res) => {
      if (res.ResponseType == "SUCCESS") {
        console.log("success api hit");
      }
    });
  }
	getAllAuthorNames(authors: any) {
		return authors && authors?.length &&
			authors?.map((author: any, index: number) => {
				return (authors?.length > 1) ? ( " " + author?.name):   author?.name;
			})
	}

	getRatingArr(rating: any) {
		let arr = [];
		if (rating) {

			arr = Array(Math.floor(rating)).fill(1)
			if (rating - Math.floor(rating)) {
				arr?.push(rating-Math.floor(rating));
			}
		}
		return arr
	}

}
