import { Injectable } from '@angular/core';
import { HttpHelperService } from 'src/app/service/http-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { Observable } from 'rxjs';
import { APIResponse } from 'src/app/models/interfaces/api-response';
import { ResearchAPiUrl } from 'src/app/api-url/research-api-url.constant';
import { map } from 'rxjs/operators';

@Injectable()
export class SignedLandingService {

  constructor(private _httpHelperService: HttpHelperService, private globalUtility: GlobalUtility) { }


  getNewsListing(categroyId: string, limit: number): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(ResearchAPiUrl.GET_RESEARCH_NEWS_UPDATES);
    url += `/${categroyId}/${limit}`
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getMostViewedNews(categroyId: string, limit: number): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(ResearchAPiUrl.GET_RESEARCH_MOST_VIEWS_NEWS);
    url += `/${categroyId}/${limit}`
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getEPFSUpdates(categroyId: string,type:string ,limit: number): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(ResearchAPiUrl.GET_RESEARCH_EPFS_NEWS);
    url += `/${categroyId}/${type}/${limit}`
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }
  
  getOpinions(categroyId: string, limit: number): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(ResearchAPiUrl.GET_RESEARCH_EXPERT_OPINIONS);
    url += `/${categroyId}/${limit}`
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getBreakingNews(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(ResearchAPiUrl.GET_RESEARCH_BREAKING_NEWS);
    return this._httpHelperService.getData(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

  showBudgetMarathonTv(){
      let url = this.globalUtility.makeUrl(ResearchAPiUrl.BUDGET_MARATHON_TV);
      return this._httpHelperService.get(url, {}, false).pipe(map(response => response));
  }

}
