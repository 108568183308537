import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { connectWithUsData } from '../taxmann-data/data';
import { TaxmannProducts } from 'src/enums/enum';
import { PricingService } from './pricing.service';

@Component({
	selector: 'app-pricing',
	templateUrl: './pricing.component.html',
	styleUrls: ['./pricing.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PricingComponent implements OnInit {
	faqList = [];
	connectWithUsList = connectWithUsData;
	taxmannProducts = TaxmannProducts;
	constructor(private pricingService: PricingService) { }

	ngOnInit() {
		// adit faqs not required on pricing page
		//this.getPricingFAQ();
	}

	getPricingFAQ() {
		let payload = {
			type: 'adit',
			subject: 'adit',
			searchtext: ''
		}
		this.pricingService.getPricingFAQ(payload).subscribe((response) => {
			if (response.Status === 'DATA_FOUND' && response.Data) {
				this.faqList = response.Data.lst;
			}
		});
	}

}
