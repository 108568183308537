import { Injectable } from '@angular/core';
import { openDB, DBSchema } from "idb";

@Injectable({
	providedIn: 'root'
})
export class IndexedDBService {

	taxPracticeDB;

	constructor() {
		this.connectToTaxPracticeDB();
	}

	async connectToTaxPracticeDB() {
		this.taxPracticeDB = await openDB<TaxPracticeDB>('taxpracticedb', 1, {
			upgrade(db) {
				db.createObjectStore('user-history');
				db.createObjectStore('inserting-user-new-history');
			}
		})
	}

	async addUserHistory(storeKey: string, storeValueKey: string, searchHistory: string[]) {
		if (this.taxPracticeDB) {
			await this.taxPracticeDB.put(storeKey, searchHistory, storeValueKey);
		}
	}


	async getUserHistoryForTaxPractice(storeKey: string, storeValueKey: string): Promise<string[]> {
		let userHistory: string[] = [];
		if (this.taxPracticeDB) {
			userHistory = await this.taxPracticeDB.get(storeKey, storeValueKey)
				.then(userHistory => userHistory || [])
				.catch(error => { });
			return userHistory;
		}
	}

	async getUserNewHistoryForTaxPractice(): Promise<string[]> {
		let userHistory: string[] = [];
		if (this.taxPracticeDB) {
			userHistory = await this.taxPracticeDB.get('inserting-user-new-history', 'searchHistory')
				.then(userHistory => userHistory || [])
				.catch(error => { });
			return userHistory;
		}
	}

	async clearStoreValue(storeKey: string) {
		await this.taxPracticeDB.clear(storeKey);
	}
}


interface TaxPracticeDB extends DBSchema {
	'user-history': {
		key: string;
		value: string[];
	},
	'inserting-user-new-history': {
		key: string;
		value: string[];
	},
}