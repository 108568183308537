import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxStarsComponent } from 'ngx-stars';
@Component({
  selector: 'tx-rating-star',
  templateUrl: './rating-review.component.html',
  styleUrls: ['./rating-review.component.scss']
})
export class RatingReviewComponent implements OnInit {

  @Input() ratingReview?: NgxStarsComponent;
  @Input() set ratingStarProps(val:any) {
          if(val)  {
            if (val.hasOwnProperty('color')) {
              this.reviewProps.color = val.color;
            }
            if (val.hasOwnProperty('readonly')) {
              this.reviewProps.readonly = val.readonly;
            }
            if (val.hasOwnProperty('size')) {
              this.reviewProps.size = val.size;
            }
            if (val.hasOwnProperty('customPadding')) {
              this.reviewProps.customPadding = val.customPadding;
            }
            if (val.hasOwnProperty('maxStars')) {
              this.reviewProps.maxStars = val.maxStars;
            }
            if (val.hasOwnProperty('wholeStars')) {
              this.reviewProps.wholeStars = val.wholeStars;
            }
            if (val.hasOwnProperty('initialStars')) {
              this.reviewProps.initialStars = val.initialStars;
            }
          }
  };
  @Input() set ratingValue(val: any) {
      if(val instanceof Array && val && val.length > 0) {
        this.reviewProps.initialStars = this.getFilledStar(val) || 0;
      } else {
        this.reviewProps.initialStars = val || 0;
      }
  };
  @Output() starRatingOutput = new EventEmitter<any>();
  public reviewProps = {
    color: 'gold',
    initialStars: 0,
    customPadding: "0.4rem",
    size: 1,
    maxStars: 5,
    wholeStars: false,
    readonly: true
  }
  constructor() { }

  ngOnInit(): void {
  }

  getFilledStar(rating) {
    if (!rating) {
      return 0
    }
    if (rating && rating.length > 0) {
      return rating.filter((rating) => +rating === 1).length || 0
    }
  }

  ratingOutput(value) {
      this.starRatingOutput.emit(value);
  }

}
