import { Component, Input } from '@angular/core';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';
import { ArchiveSharedService } from '../service/archive-shared.service';
import { GlobalUtility } from '../utility/global-utility';
import { FooterListModel } from './classes/list-model';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    @Input() toggleCssClass;
    @Input() hasSideBar: boolean;

    paymentAcceptArray = FooterListModel.paymentAcceptArray;
    socialShareIconArray = FooterListModel.socialShareIconArray;
    productArray = FooterListModel.productArray;
    aboutCompanyArray = FooterListModel.aboutCompanyArray;
    //catalogueData = FooterListModel.cataloguedData;
    catalogueData;
    catalogueFirstHalfData;
    catalogueSecondHalfData;
    businessAndSupportArray = FooterListModel.businessAndSupportArray;
    bookstoreSupportArray = FooterListModel.bookstoreSupportArray;
    legalArray = FooterListModel.legalArray;
    useDomain: string;
    userIp: string;
    isIPUser: boolean;
    isProxyUser: boolean;
    imageUrl = this.globalUtility.makeImageUrl();
    catalogueListSlice = 0

    constructor(private archiveSharedService: ArchiveSharedService,
      private globalUtility: GlobalUtility,private router: Router,
      public sanitizer: DomSanitizer     ) {
    }

     desiredOrder = [
      "Academic Publications",
      "Subscriptions & Online Resources",
      "Advisory",
      "Compliance",
      "Professional – Law & Taxation Publications",
      "Training & Professional Courses"
  ]; 
  
   academicPublicationsSubcategoryOrder = [
      "Textbooks & Workbooks",
      "CA | CS | CMA",
      "Financial Literacy"
  ];

   trainingCoursesSubcategoryOrder = [
      "Taxmann Academy (TAP Courses)",
      "Diplomas & Certifications"
  ];

    ngOnInit() {
      this.setIPInfo(2000);
      this.setIPInfo(5000);
      this.setIPInfo(10000);
      this.setIPInfo(20000);
      this.setIPInfo(2500);
      this.setIPInfo(30000);
      this.setIPInfo(35000);
      this.setIPInfo(40000);
      this.setIPInfo(45000);
      this.setIPInfo(50000);
this.getTaxmannProductCatalogue();
    }

    setIPInfo(time){
      setTimeout(() => {
      let domain = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_DOMAIN)
      this.useDomain = domain ? domain : window.location.hostname;
      this.userIp = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
      let iPUser = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.ISIPUSER);
      let proxyUser = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER);
      this.isIPUser = iPUser === 'true' ? true : false
      this.isProxyUser = proxyUser === 'true' ? true : false;
      console.log("is ip user", this.isIPUser);
    }, time);
    }
    getTaxmannProductCatalogue(){
        this.archiveSharedService.getProductCatalogue().subscribe((res)=>{
          if(res && res.data && res?.data?.catalogueList){
            this.catalogueListSlice = Math.round((res?.data?.catalogueList?.length/2))
            this.catalogueData = res?.data;
            const sortedData: any = [...res.data.catalogueList].sort((a, b) => {
              return this.desiredOrder.indexOf(a.categoryName) - this.desiredOrder.indexOf(b.categoryName);
          });

          sortedData.forEach((category: any) => {
              if (category.categoryName === "Academic Publications") {
                  category.subcategories.sort((a:any, b:any) => {
                      return this.academicPublicationsSubcategoryOrder.indexOf(a.subcategoryName) - this.academicPublicationsSubcategoryOrder.indexOf(b.subcategoryName);
                  });
              }
              if (category.categoryName === "Training & Professional Courses") {
                  category.subcategories.sort((a: any, b: any) => {
                      return this.trainingCoursesSubcategoryOrder.indexOf(a.subcategoryName) - this.trainingCoursesSubcategoryOrder.indexOf(b.subcategoryName);
                  });
              }
              
             this.catalogueFirstHalfData = sortedData.slice(0, 4);
             this.catalogueSecondHalfData = sortedData.slice(4);
          });
          }
        }, (error)=>{
        }
        );
      }

      routeToProduct(url,queryParams){
        if(queryParams){
        if(url.includes('advisory') || url.includes('academy')){
          this.router.navigate([url], {
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        })
        setTimeout(() => {
          window.location.reload();
        }, 200);

        } else {
          this.router.navigate([url], {
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        })
        }
      }
      else {
        if(url.includes('advisory') || url.includes('academy')){
          this.router.navigate([url])
        setTimeout(() => {
          window.location.reload();
        }, 200);

        } else {
          this.router.navigate([url])
        }
      }
      }

    scroll() {
        window.scroll(0, 0);
    }
    @gaEventTrack('Footer', '', 'Click',-1,0)
    eventFooterItem(labelIndex){

    }
}
