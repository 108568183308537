import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIResponse } from 'src/app/models/interfaces/api-response';

import { TaxPracticeAPiUrl } from 'src/app/api-url/tax-practice-url.enum';

import { HttpHelperService } from 'src/app/service/http-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { IndexedDBService } from 'src/app/service/indexed-db.service';
import * as _ from 'lodash';
import { TaxmannUrl } from 'src/app/api-url/taxmann-url.enum';


@Injectable()
export class TaxPracticeSuggestionService {
	constructor(
		private _httpHelperService: HttpHelperService,
		private _globalUtility: GlobalUtility,
		private _indexedDBService: IndexedDBService,
	) { }

	getTaxPracticeAutoCompleteContent(paramObj: { query: string }): Observable<APIResponse> {
		let url = this._globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.GLOBAL_TAXPRACTICE_SUGGESTIONS);
		return this._httpHelperService.post(url, paramObj, false)
			.pipe(map(this._globalUtility.filterResponse));
	}

	async insertUserSearchHistory() {
		let userHistory = await this._indexedDBService.getUserNewHistoryForTaxPractice();
		if (_.isEmpty(userHistory) || userHistory.length < 5) {
			return;
		}
		await this.insertSearchUserHistoryInS3();
	}

	async insertSearchUserHistoryInS3() {
		let userHistory = await this._indexedDBService.getUserNewHistoryForTaxPractice();
		if (_.isEmpty(userHistory)) {
			return;
		}
		await this._indexedDBService.clearStoreValue('inserting-user-new-history');
		const insertSearchUserHistoryUrl: string = this._globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.INSERT_USER_SEARCH_HISTORY);
		this._httpHelperService.put(insertSearchUserHistoryUrl, { searchHistory: userHistory }, true)
			.subscribe()
	}

	getUserSearchHistory(): Observable<APIResponse> {
		const getUserSearchHistoryUrl: string = this._globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.GET_USER_SEARCH_HISTORY);
		return this._httpHelperService.get(getUserSearchHistoryUrl, {}, true)
	}

	deleteFromUserSearchHistory(paramObj: { deletingHistoryKeyWord: string }): Observable<APIResponse> {
		const deleteFromUserSearchHistoryUrl: string = this._globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.DELETE_FROM_SEARCH_HISTORT);
		return this._httpHelperService.delete(deleteFromUserSearchHistoryUrl, paramObj, false, true)
	}
	postSearchLogs(obj: any, search: string): Observable<APIResponse> {
		let url = this._globalUtility.makeRedisUrl(TaxmannUrl.SEARCH_LOG);
		let searchTextNew = search.replace(/[`'\\]/gi, '');
		searchTextNew = encodeURIComponent(searchTextNew);
		obj['searchKeyword'] = searchTextNew;
		return this._httpHelperService.post(url, obj, true)
			.pipe(map(this._globalUtility.filterResponse));
	}
}