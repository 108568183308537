import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalType } from '../../../../constants/constant';
import { modalAnimation } from '../../../../animations/modal.animation';

// import * as $ from "jquery";
@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [modalAnimation]
})
export class PopupComponent implements OnInit {
  @Input() header: string;
  @Input() modalType: string = ModalType.LARGE;
  @Input() showCross: boolean;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalClass;
  showModal = true
  constructor() { }

  ngOnInit() {
    if (!this.modalClass) {
      this.modalClass = '';
    }

    // this.onActivate();
  }

  ngAfterViewInit() {
    let element = document.querySelector('.modal')
    if (element && getComputedStyle(element).position != 'absolute') {
      document.body.classList.add("modal-open");
      
    }
    // if($('.modal').css('position') != 'absolute'){
    //   document.body.classList.add("modal-open");
    // }
  }
  /**
   * close popup event
   * @param e 
   */

  onCloseClick(e) {
    this.showModal = false;
    setTimeout(() => {
      this.onClose.emit(true);
    }, 300);


  }

//   onActivate() {
//     window.scroll(0,0);
//     //or document.body.scrollTop = 0;
//     //or document.querySelector('body').scrollTo(0,0)
// }

  ngOnDestroy() {
    document.body.classList.remove("modal-open");
  }
  
}
