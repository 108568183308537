import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TaxmannUrl } from '../api-url/taxmann-url.enum';
import { LocalStorageDataModel } from '../../constants/local-storage-data-model';
import { APIResponse } from '../models/interfaces/api-response';
import { GlobalUtility } from '../utility/global-utility';
import { HttpHelperService } from './http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private globalUtility: GlobalUtility, private httpHelperService: HttpHelperService) { }

  checkActiveSession(data): Observable<APIResponse> {
    const headers = {};
    let content_token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.CONTENT_TOKEN)
    if (content_token) {
      headers[LocalStorageDataModel.CONTENT_TOKEN] = content_token;
    }
    data['ipAddress'] =  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
    let url = this.globalUtility.makeDashboardUrl(TaxmannUrl.CHECK_ACTIVE_SESSION);
    return this.httpHelperService.postwithoutCheckResponseAndErrorHandler(url, data, true, false, headers).pipe(
			map(res => {
        if(res?.data) {
          res["data"]= res.data;
        }
        return res;
      }));
  }

  logout() {
    const headers = {};
    let content_token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.CONTENT_TOKEN)
    if (content_token) {
      headers[LocalStorageDataModel.CONTENT_TOKEN] = content_token;
    }

    let ireader_token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.I_READER_TOKEN)
    if (ireader_token) {
      headers[LocalStorageDataModel.I_READER_TOKEN] = ireader_token;
    }

    let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.AUTHLOGOUT);
    return this.httpHelperService.post(url, {}, true, false, headers)
      .pipe(map(this.globalUtility.filterResponse));
  }

  checkIReaderActiveSession(): Observable<APIResponse> {
    const headers = {};
    let ireader_token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.I_READER_TOKEN)
    if (ireader_token) {
      headers[LocalStorageDataModel.I_READER_TOKEN] = ireader_token;
    }
    // let isIpUser = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.ISIPUSER)
    let body = {
      //'isIpUser' : isIpUser === 'true' ? true : false
      'category' : 'I_READER',
      'ipAddress':  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP)
     }
      let url = this.globalUtility.makeDashboardUrl(TaxmannUrl.CHECK_ACTIVE_IREADER_SESSION);
    return this.httpHelperService.post(url, body, true, false, headers);
    //  .pipe(map(this.globalUtility.filterResponse));
  }


  checkActiveSessionWithNoPreFilter(data): Observable<APIResponse> {
        const headers = {};
        let content_token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.CONTENT_TOKEN)
        if (content_token) {
          headers[LocalStorageDataModel.CONTENT_TOKEN] = content_token;
        }
        data['ipAddress'] =  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
        let url = this.globalUtility.makeDashboardUrl(TaxmannUrl.CHECK_ACTIVE_SESSION);
        return this.httpHelperService.postwithoutCheckResponseAndErrorHandler(url, data, true, false, headers).pipe(
          map(res => {
            if(res?.data) {
              res["Data"]= res.data;
            }
            return res;
          }));
      }

      checkIReaderActiveSessionWithNoPreFilter(): Observable<APIResponse> {
        const headers = {};
        let ireader_token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.I_READER_TOKEN)
        if (ireader_token) {
          headers[LocalStorageDataModel.I_READER_TOKEN] = ireader_token;
        }
        // let isIpUser = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.ISIPUSER)
        let body = {
          //'isIpUser' : isIpUser === 'true' ? true : false
          'category' : 'I_READER',
          'ipAddress':  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP)
         }
          let url = this.globalUtility.makeDashboardUrl(TaxmannUrl.CHECK_ACTIVE_IREADER_SESSION);
        return this.httpHelperService.post(url, body, true, false, headers)
        .pipe(
          map(res => res)
        );      
      }
    
}
