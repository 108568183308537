const imageUrlCdn = "https://cdn.taxmann.com/taxmann-images/logos/";

const CTCimageUrlCdn = "https://cdn.taxmann.com/taxmann-images/ctc/ctconline-logo.png"

export const printPreviewCss = `    
@media screen {
    
    .print
    {
    display:block;
    padding-right:20px;
    font-family: Times New Roman;
    -webkit-font-family: Times New Roman;
    -o-font-family: Times New Roman;
     font-size: 11pt;
    -webkit-font-size: 11pt;
    -o-font-size: 11pt;
     color: #000000;
    -webkit-color: #000000;
    -o-color: #000000;
     font-weight: bold;
    -webkit-font-weight: bold;
    -o-font-weight: bold;
    
    }
    }
    @media print {
    
    .print
    {
    display:none;
    padding-right:20px;
    font-family: Times New Roman;
    -webkit-font-family: Times New Roman;
    -o-font-family: Times New Roman;
     font-size: 11pt;
    -webkit-font-size: 11pt;
    -o-font-size: 11pt;
     color: #000000;
    -webkit-color: #000000;
    -o-color: #000000;
     font-weight: bold;
    -webkit-font-weight: bold;
    -o-font-weight: bold;
    
    }
        .a_b_row { display: flex; margin-top:20px; } 
        .a_b_row .a_b_bx {max-width:45%; margin-bottom: 25px;margin-right: 30px;display: flex;align-items: center;} 
        .a_b_row .a_b_img {min-width:82px; width:82px;height: 82px;overflow: hidden;border-radius: 100px;margin-right: 15px;}
        .a_b_row .a_b_img img {width: 100%;height: 100%;}
        .a_b_row .a_b_details .a_b_name {font-size: 22px;font-weight: 500;letter-spacing: 0.5px;color:#4b4b4b;margin-bottom: 4px;} 
        .a_b_row .a_b_details .a_b_n_d {font-size: 14px; color: #aaa;margin-bottom: 0px; text-align: left;} 
    }`

export const printActsCss = `.print-area .top-area {
    border: 1px solid black;
    padding: 2px;
  }
  .print-area .top-area .info-data {
    display: flex;
    justify-content: space-between;
  }
  .print-area .top-area .info-data .info-item {
    display: flex;
    width: 100%;
  }
  .print-area .top-area .info-data .info-item .tilte {
    padding: 5px;
    border: 1px solid white;
    width: 50%;
    font-weight: 500;
    font-size: 16px;
    color: black;
    background-color: #c0c0c0;
  }
  .print-area .top-area .info-data .info-item .tilte .normal {
    font-weight: normal;
  }
  .print-area .top-area .info-footer {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: 16px;
    font-weight: 500;
  }
  .print-area .data-area {
    margin-top: 20px;
  }
  .print-area .data-area tr:nth-child(odd) {
    background-color: #fff;
  }
  .print-area .data-area tr:nth-child(even) {
    background-color: #c0c0c0;
  }
  .print-area .data-area th {
    background-color: #c0c0c0;
    border: 2px solid white;
    padding-left: 5px;
  }
  .print-area .data-area td {
    border: 2px solid white;
    padding-left: 5px;
  }
  .print-area .data-area .chapter-heading {
    background-color: #c0c0c0;
    color: black;
    font-weight: 14px;
    padding-left: 5px;
    margin-top: 5px;
  }
  .print-area .data-area .sub-list {
    background-color: white !important;
    border-bottom  : 1px solid #c0c0c0;
  }
  .print-area .data-area .sub-list .chapter-heading {
    background-color: white !important;
    padding-left: 15px;
  }
  .print-area .data-area .chapter-section {
    color: black;
    font-weight: 10px;
    padding-left: 30px;
  }
  .text-lg-left {  text-align: left!important;}
  .text-lg-right {  text-align: right!important;}
  .col-lg-3 {float:left; width:25%;}
 .col-lg-6 {float:left; width:50%;}
  .col-lg-9 {float:left; width:75%;}
  .print-area .list-col-print-1.row-even-odd-bg .row-l-col-p:nth-child(odd) {
    background: silver;
}
.print-area .list-col-print-1 .l-col-p { border: 2px solid #fff; padding-left: 5px;}
.print-area .col-12 { max-width: 100%; position: relative; width: 100%; padding-right: 15px; padding-left: 15px;}
.print-area .list-col-print-1 .header-lcp { font-weight: bold;}
.print-area .list-col-print-1.list-print-colls-1 { border-bottom: 1px dotted black; padding-top: 5px; padding-bottom: 5px;}
`

export const letterheadHtml = `
   <table style="margin-bottom:5px;" width="100%" cellpadding="0" cellspacing="0" border="0">
    <tbody>
     <tr>
        <td valign="top"><img alt="header"
                src="${imageUrlCdn}research-doc-logo.png" height="56">
        </td>
        <td valign="top" align="right"></td>
    </tr>
    </tbody>
</table>`

export const practiceLetterheadHtml = `
   <table style="margin-bottom:5px;" width="100%" cellpadding="0" cellspacing="0" border="0">
    <tbody>
     <tr>
        <td valign="top"><img alt="header"
                src= "${imageUrlCdn}practice-doc-logo.png" height="56">
        </td>
        <td valign="top" align="right"></td>
    </tr>
    </tbody>
</table>`


export const treatyComparatorPrintCss = `
.open-compare-files .open-compare-header {
  background: orange;
  color: #fff;
  padding: 0;
}
.open-compare-files .open-compare-header .coll-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: normal;
}
.open-compare-files .open-compare-header .coll-files-heading {
  font-size: 1.2rem;
  font-weight: normal;
}
.open-compare-files .btn-remove-files {
  width: 20px;
  height: 20px;
  text-align: center;
  background: orange;
  color: #fff;
  font-size: 0.8rem;
  border-radius: 50%;
  display: flex;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
}
.open-compare-files .coll-files-heading {
  width: 10%;
  padding: 0.5rem 1%;
  font-size: 1.2rem;
  font-weight: 600;
}
.open-compare-files .coll-files {
  width: 30%;
  padding: 0.5rem 1%;
}
.open-compare-files .heading-h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.open-compare-files .heading-h2 {
  font-size: 14px;
  font-weight: 600;
}
.open-compare-files .heading-h3 {
  font-size: 14px;
  font-weight: 600;
}
.open-compare-files .row-alternative-t1:nth-child(odd) {
  background: #fffbf5;
}

.btn-remove-files{
  display:none !important;
}
`

export const ActRuleComparatorCSS = `
.open-compare-files .open-compare-header {
  background: orange;
  color: #fff;
  padding: 0;
}
.open-compare-files .open-compare-header .coll-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
}
.open-compare-files .open-compare-header .coll-files-heading {
  font-size: 14px;
  font-weight: normal;
}
.open-compare-files .coll-files-heading {
  width: 10%;
  padding: 0.5rem 1%;
  font-size: 1.2rem;
  font-weight: 600;
}
.open-compare-files .coll-files {
  width: 50%;
  padding: 0.5rem 1%;
}
.open-compare-files .heading-h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.open-compare-files .heading-h2 {
  font-size: 14px;
  font-weight: 600;
}
.open-compare-files .heading-h3 {
  font-size: 14px;
  font-weight: 600;
}

`

export const modelReportPrintCSS = `
.file-open-actions {
  display: none;
}
.heading-file-open {
  font-size: 20px;
  font-weight: 700;
}
.file-open-content .heading-section-1 {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 22px;
  color: orange;
}
.file-open-content h5 {
  font-size: 20px;
}
`

export const asDeffCalcResultPrintCSS = `
.asdef-result .custom-table {
      border: 1px solid rgba(0, 0, 0, .125);
  }
  .asdef-result .custom-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 4px;
      border-bottom :1px solid rgba(0, 0, 0, .125);
  }
     .heading-row {
          background: #8A8A8A;
          font-weight: bold;
          color: white;
          border-bottom :none;
      }

       .result-row {
          background: #FFFAF6;
          font-weight: bold;
          border-bottom :none;
      }
`

export const CTCletterheadHtml = `
   <table style="margin-bottom:5px;" width="100%" cellpadding="0" cellspacing="0" border="0">
    <tbody>
     <tr>
     <td style="width:50%"> &nbsp; </td>
        <td valign="top" align="center"><img alt="header"
                src="${CTCimageUrlCdn}" style="max-width:100px;">
        </td>
        <td valign="top" align="right" style="width:50%"> &nbsp;  </td>
    </tr>
    </tbody>
</table>`

export const letterFooterHtml = `
<div style="padding-top:50px;">
        Source: The Chamber's Journal. To subscribe to the Chamber's Journal, click here
        <a href="https://ctconline.org/become-a-member-of-chamber/" >Become a Member of Chamber - The Chamber of Tax Consultants</a>
</div>`
