import { Component, OnInit } from '@angular/core';
import { CustomerStory } from '../../taxmann-data/data';
import { TaxmannHomeService } from '../../service/taxmann-service/taxmann-home.service';
import { CarouselConfig } from '../../../constants/owl-carousel.constant';

@Component({
  selector: 'app-taxmann-customer-stories',
  templateUrl: './taxmann-customer-stories.component.html',
  styleUrls: ['./taxmann-customer-stories.component.scss']
})
export class TaxmannCustomerStoriesComponent implements OnInit {

  carouselConfig = CarouselConfig.customercard;
  customerStoriesList: CustomerStory[] = [];

  constructor(private _taxmannHomeService: TaxmannHomeService) { }

  ngOnInit() {
    this.customerStoriesList = this._taxmannHomeService.getCustomerStoriesList();
  }


}