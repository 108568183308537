import { GlobalUtility } from './utility/global-utility';
import { Component } from '@angular/core';
import { ConfirmationService } from './taxmann-common/services/confirmation.service';
import { MetaService } from './service/meta.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageDataModel } from '../constants/local-storage-data-model';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { takeWhile } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import * as $ from 'jquery'
import { Location } from '@angular/common';
import { RESEARCH_OLD_URL, BOOKSTORE_OLD_URL } from '../constants/old-url.constant';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'
import * as workerTimers from 'worker-timers';
import { UserDeviceTrackingService } from "./service/taxmann-service/userDeviceTrack.service";
import { IndexDBService } from "./service/indexdb.service";
import { APIResponse } from "./models/interfaces/api-response";
import { ApiStatus, PLATFORM_REDIRECTION } from "../constants/constant";
import { HomeService } from './service/bookstore-service/home.service';
import { JsonLDService } from './utility/jsonld.service';
import { BudgetVideoSchemaConfif, TaxAuditSchemaConfig } from './utility/JsonLdSchemas/jsonld.schema';
import { v4 as uuidv4 } from 'uuid';
import { NETCORE_API_FIELD_ID_MAPPING_LIST, NETCORE_EVENT_LIST } from 'src/constants/netcore.eventList';
import { TaxmannCryptoJS } from './crypt';
import * as _ from 'lodash';
// import { ENDMILE_API_EVENT_LIST, ENDMILE_API_FIELD_ID_MAPPING_LIST } from 'src/constants/endMileAPI.event.list';
declare var ga: Function;
declare var gtag: Function;
// declare var smartech: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	isCompatible: boolean;
	deviceInfo: any;
	currentRoute;
	alive = true;
	login = this.globalUtility.isLoggedIn();
	imageUrl = this.globalUtility.makeImageUrl();
	utmObj={};
	constructor(
		public confirmationService: ConfirmationService,
		private _metaService: MetaService,
		public globalUtility: GlobalUtility,
		private deviceService: DeviceDetectorService, private router: Router,
		private meta: Meta,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private userDeviceTrackingService: UserDeviceTrackingService,
		private indexDbService: IndexDBService,
		private homeService: HomeService,
		private jsonLDService: JsonLDService
	) {
		this._metaService.updateSiteName(window.location.origin);
		this._metaService.updateFacebookDomainVerification('facebook-domain-verification', "iyeu3swkxk361uj1wivqo0k384k289")
		window['taxmannCrypt'] = new TaxmannCryptoJS();
	}


	ngOnInit() {
		// let env = this.globalUtility.setENV();
		// let netCoreRegisterKey = environment[env].NETCORE_REGISTER_KEY;
		// smartech('register', netCoreRegisterKey);
		if (!this.login) {
			this.globalUtility.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.PRACTICE_STRIP_VIEW, '1', 365 * 24 * 60 * 60 * 100);
		}
		if (this.globalUtility.getDataFromLocalStorageWithExpiry(LocalStorageDataModel.PRACTICE_STRIP_VIEW, true) == null) {
			this.globalUtility.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.PRACTICE_STRIP_VIEW, '1', 365 * 24 * 60 * 60 * 100);
		}
		this.activatedRoute.queryParams.subscribe((params) => {
			if(params.utm_medium && params.utm_medium.toLowerCase().includes('cid')){
	            let utm_medium_arr =  params.utm_medium.toLowerCase().split('cid');
	           let cid = utm_medium_arr[1].match(/\d+/g).join('');
	           this.globalUtility.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.ENDMILE_EVENTS_CID_NUMBER, cid, 24 * 60 * 60 * 100);
}
			if (RESEARCH_OLD_URL.includes(window.location.pathname + window.location.search)) {
				this.router.navigate(['/research']);
			} else if (BOOKSTORE_OLD_URL.includes(window.location.pathname + window.location.search)) {
				this.router.navigate(['/bookstore']);
			} else if (PLATFORM_REDIRECTION.hasOwnProperty(window.location.pathname)) {
				window.open(PLATFORM_REDIRECTION[window.location.pathname], '_self');
			}
		})
		 this.utmObj = this.globalUtility.createUtmObj();
		this.initEvents();
		//this.updateNoindexTag();
		this.deviceInfo = this.deviceService.getDeviceInfo();
		//if (this.login) {
		//this.pushUrlToIndexDb()
		//	this.saveUserDeviceVisitedRouteInfo();
		//}
		console.log(this.deviceInfo)
		if (this.deviceInfo && this.deviceInfo.browser && this.deviceService.browser.toLowerCase() === 'IE'.toLowerCase()) {
			this.isCompatible = false;
		} else {
			this.isCompatible = true;
		}

		let viewedDocumentsList: any = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.VIEWED_DOCUMENTS);
		if (viewedDocumentsList && viewedDocumentsList.trim()) {
			viewedDocumentsList = JSON.parse(viewedDocumentsList);
			if (viewedDocumentsList && viewedDocumentsList.length) {
				this.globalUtility.viewedDocumentsListSub.next(viewedDocumentsList);
				this.globalUtility.viewedDocumentsList = viewedDocumentsList;
			}
		}
		if (environment.production) {
			this.subscribeToRouterEvents();
		}
		this.initEvent();
		this.enableChartSupport();
		this.storeUtmInfo();
		setTimeout(() => {
			this.bindChatbotClick();
		}, 3000);
	}

	initEvents() {
		this.globalUtility.bodyScrollOff.pipe(takeWhile(_ => this.alive)).subscribe((res) => {
			if (res) {
				document.body.classList.add("scroll-off");
			} else {
				document.body.classList.remove("scroll-off");
			}
		});
		// this.globalUtility.saveUserVisitedRoutes.subscribe((value: any) => {
		// 	if (value) {
		// 		this.saveUserDeviceVisitedRouteInfo();
		// 	}
		// });
	}
	updateNoindexTag() {

		if (location.hostname.indexOf('preprod') !== -1) {
			this.meta.addTag({ name: "Robots", content: "noindex" });
		}
		if (location.hostname.indexOf('mctl') !== -1) {
			this.meta.addTag({ name: "Robots", content: "noindex" });
		}
		if (location.hostname.indexOf('lsupport') !== -1) {
			this.meta.addTag({ name: "Robots", content: "noindex" });
		}
		if (location.hostname.indexOf('mdtl') !== -1) {
			this.meta.addTag({ name: "Robots", content: "noindex, nofollow, noarchive, nosnippet" });
		}
	}

	checkURLRedirectionToCACS(event: NavigationEnd) :any { // /bookstore/product/10233-abcde
		if(window.location.pathname === "/" || window.location.pathname === "/home") {
			let bookstoreCACSRouteStorage = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.BOOKSTORE_CA_CS_ROUTE);
			let routURls = window['BOOKSTORE_CA_CS_ROUTE'] && window['BOOKSTORE_CA_CS_ROUTE'].length ?  window['BOOKSTORE_CA_CS_ROUTE'] : JSON.parse(bookstoreCACSRouteStorage) ;
			let matched:any = ( routURls || []).find((e) => (window.location.pathname != e.cacsurl && window.location.pathname == e.bookstoreurl ));
			if (_.isEmpty(matched)) {
				return
			} else {
				window.open(matched.cacsurl, '_self');
			}
		} else {
			if ( !(event?.url?.includes('/virtualbooks/flipbook/') || event?.url?.includes('/DigitalFootPrint'))  && ( event?.url?.includes('/bookstore') ||  event?.url?.includes('/user') || event?.url?.includes('/virtualbooks') || event?.url?.includes('/auth') ) && !(event?.url?.includes('/search')) && !(event?.url?.includes('/podcast')) ) {
				let bookstoreCACSRouteStorage = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.BOOKSTORE_CA_CS_ROUTE);
				let routURls = window['BOOKSTORE_CA_CS_ROUTE'] && window['BOOKSTORE_CA_CS_ROUTE'].length ?  window['BOOKSTORE_CA_CS_ROUTE'] : JSON.parse(bookstoreCACSRouteStorage) ;
				let matched:any = ( routURls || []).find((e) => (window.location.pathname != e.cacsurl && window.location.pathname?.includes(e.bookstoreurl) ));
				if (_.isEmpty(matched)) {
					return
				} else {
					window.open(matched.cacsurl, '_self');
				}
			}
		}
	}

	subscribeToRouterEvents() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {

				this.removeJsonLdStructure();
			}
			if (event instanceof NavigationEnd) {
				this.checkURLRedirectionToCACS( event);
				let netCorePayload = {
					[NETCORE_API_FIELD_ID_MAPPING_LIST.PAGE_URL]: window.location.href
				}
				if(this.utmObj && Object.keys(this.utmObj) && Object.keys(this.utmObj).length){
					netCorePayload = {...netCorePayload,...this.utmObj}
				}else{
				let utmObj = this.globalUtility.createUtmObj();
				netCorePayload = {...netCorePayload,...utmObj}
				}
				this.globalUtility.netCoreEventTrack(NETCORE_EVENT_LIST.PAGE_BROWSE, netCorePayload)
				if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.PREVIOUS_URL) == '/auth/logout') {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.PREVIOUS_URL);
				}
				if (this.router.url.includes('/store/payment') || this.router.url.includes('/webinars/')
					|| this.router.url.includes('/paymentallied') || this.router.url.includes('/paymenttppl') || this.router.url.includes('/order/pay-online/') ) {
					this.initializeRazorpayCheckout();
				}

				/* changes for loading go arena scripts */

				// if(this.globalUtility.getDataFromLocalStorage('areanAdded') == '1'){
				// 	this.globalUtility.removeFromLocalStorage('areanAdded');
				// 	window.location.reload();
				// }

				this._metaService.updateRobots();
				this.loadJsonLdStructure();
				const newRoute = event.urlAfterRedirects || '/';
				if (newRoute !== this.currentRoute) {
					try {
						gtag('event', 'page_view', { 'send_to': 'UA-31282975-1' });
						this.routeGATrackChanged();
				//End Mile event track code starts
				      // if(this.login){
						//let cidNumber = this.globalUtility.getDataFromLocalStorageWithExpiry(LocalStorageDataModel.ENDMILE_EVENTS_CID_NUMBER) || '';
						// let trackData = [{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.FIRST_NAME_LAST_NAME,"value" : this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME) },
						// {"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.PHONE_NUMBER,"value":'' },
						// {"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.LOCATION,"value": '' },
						// {"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.PAGE_URL,"value":window.location.href},
						// {"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.PAGE_CATEGORY,"value":"internal"},
						// {"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.PAGE_TITLE,"value":"internal"},
						// {"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.CID,"value":cidNumber}]					;
						// let payload = this.globalUtility.createEndmileAPITrackObject(ENDMILE_API_EVENT_LIST.PAGE_VISITED ,trackData);
						 // stop page visited api to send
                        //  this.homeService.subscribeTopostEndmileAPIEventTrack(payload);
					  // }
									//	End Mile event track code ends

					} catch (e) {
						console.log(e);
					} finally {
						this.currentRoute = newRoute;
					}
				}

				// push the route to index db for user route tracking with device details - in login and logout mode both
				//if (this.login) {
					//this.pushUrlToIndexDb()
				//}
				// call user site tracking api only after ip adress recieved
				this.promise1.then((value) => {
					this.homeService.sendUserDeviceTracking();
				});
					 	}


		});
	}

	@gaEventTrack('event', 'page_view', '')
	routeGATrackChanged() { }

	ngOnDestroy() {
		this.alive = false;
	}
	initEvent() {
		let pathName = window.location.pathname;
		console.log("pathanme", pathName, pathName.indexOf('reader') !== -1)
	}

	enableChartSupport() {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params.hasOwnProperty('enableChatSupport') && params.enableChatSupport) {
				var intervalId = setInterval(() => {
					var ele = $('.verloop-livechat-logo');
					if (ele.length) {
						ele.click()
						clearInterval(intervalId);
						this.location.replaceState('/');
					}
				}, 800);

			}
		})
	}

	closeTaxpracticeStrip() {
		this.globalUtility.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.PRACTICE_STRIP_VIEW, '0', 24 * 60 * 60 * 1000);
	}

	navigateToTaxPracticeHomePage(event) {
		event.preventDefault();
		window.open('academy', '_self')
	}
	get showpracticeStrip() {
		return !!((+this.globalUtility.getDataFromLocalStorageWithExpiry(LocalStorageDataModel.PRACTICE_STRIP_VIEW, true)))  && !window.location.pathname.includes('book-view') && !window.location.pathname.includes('practice') && !window.location.pathname.includes('research') && !window.location.pathname.includes('preview-document') && !window.location.pathname.includes('budget') && !window.location.href.includes('/tools/income-tax-calculator?type=') && !window.location.href.includes('/compliance-etds');
	}
	@gaEventTrack('', 'Chatbot', 'Click', 0)
	chatBotClicked(categoryIndex) {
		return null;
	}
	bindChatbotClick() {
		var ele = $('.verloop-livechat-logo');
		let pathname = window.location.pathname;
		let pathArr = pathname.split('/')
		let pageName = pathArr[1]
		ele.on('click', () => {
			this.chatBotClicked(pageName);
		})
	}

	pushUrlToIndexDb(): void {
		const data = this.userDeviceTrackingService.getUserSiteTrackingData();
		const allUserTrackingObservable: any = this.indexDbService.getAllData('user_device_route_tracking');
		allUserTrackingObservable.subscribe((allUserTrackingData: any) => {
			const isSameData = allUserTrackingData?.filter((i: any)=> i.componentURL === data.componentURL)
			if (data && isSameData?.length == 0) {
				this.indexDbService.addData('user_device_route_tracking', 'data', data);
			}
		})
	}

	saveUserDeviceVisitedRouteInfo(): void {
		//if (this.login) {
			workerTimers.setInterval(() => {
				//if (this.login) {
					setTimeout(() => {
						const allUserTrackingObservable = this.indexDbService.getAllData('user_device_route_tracking');
						allUserTrackingObservable.subscribe((allUserTrackingData: any) => {
							const data = { TrackingList: [] }
							allUserTrackingData.forEach((item: any) => {
								if (item && item?.data) {
									if (item.data.ipAddress) {
										data.TrackingList.push(item.data);
									}
								}
							})
							if (data.TrackingList.length > 0) {
								const arrayUniqueByKey = [...new Map(data.TrackingList.map(item =>
									[item["componentURL"], item])).values()];
									let payload = {TrackingList: arrayUniqueByKey};
									// directly using global utitlity function here to check login, instead of login variable due to need of getting login info run time as app component start runs only single time, and doesn't update login varibale in real time
									if(this.globalUtility.isLoggedIn()){}else{
										payload['verifyToken'] = 'NO';
									}
								this.userDeviceTrackingService.postUserSiteTracking(payload).subscribe((resp: APIResponse) => {
									if (resp.ResponseType === ApiStatus.SUCCESS) {
										this.indexDbService.clearStore('user_device_route_tracking').subscribe(res => {

										});
									}
								})
							}
						})
					}, 200)
				//}
			}, 15000);
		//}
	}


	storeUtmInfo() {
		this.activatedRoute.queryParams.subscribe({
			next: (params: any) => {
				if (params.utm_campaign) {
					let utm_campaign_uuid = this.globalUtility.getDataFromLocalStorage('utm_campaign_uuid') || '';
					if (!utm_campaign_uuid) {
						utm_campaign_uuid = uuidv4();
						this.globalUtility.setDataOnLocalStorage('utm_campaign_uuid', utm_campaign_uuid);
					}
					let utmInfo = {};
					Object.keys(params).forEach((key: string) => {
						if (key.includes('utm_')) {
							utmInfo[key] = params[key];
						}
					});
					this.globalUtility.setDataOnLocalStorage(params.utm_campaign, JSON.stringify(utmInfo));
					this.globalUtility.setDataOnLocalStorage('activeCampaign', params.utm_campaign);
					this.globalUtility.setDataOnLocalStorage('activeCampaignUrl', this.router.url);
					this.homeService.pushUtmSourceInformation('User Initial visit by ' + params.utm_campaign)
				}
			}
		})
	}


	loadJsonLdStructure() {
		if (this.router.url.includes(TaxAuditSchemaConfig.url)) {
			this.jsonLDService.insertSchema(TaxAuditSchemaConfig.schema, TaxAuditSchemaConfig.className);
		}
		if (this.router.url.includes(BudgetVideoSchemaConfif.url)) {
			this.jsonLDService.insertSchema(BudgetVideoSchemaConfif.schema, BudgetVideoSchemaConfif.className);
		}

	}

	removeJsonLdStructure() {
		if (this.router.url.includes(TaxAuditSchemaConfig.url) || this.router.url.includes(BudgetVideoSchemaConfif.url)) {
			this.jsonLDService.removeStructuredData([TaxAuditSchemaConfig.className])
		}
	}

	initializeRazorpayCheckout() {
		this.globalUtility.intializeScript('razorpayScript', "https://checkout.razorpay.com/v1/checkout.js");
	}

	// ngAfterViewInit(){
		// let env = this.globalUtility.setENV();
		// let netCoreRegisterKey = environment[env].NETCORE_REGISTER_KEY;
		// smartech('register', netCoreRegisterKey);
	// }

	// sendUserDeviceTracking(){
	// 	let payload = {};
	// 	payload['TrackingList'] = [this.userDeviceTrackingService.getUserSiteTrackingData()];
	// 	if(this.globalUtility.isLoggedIn()){}else{
	// 		payload['verifyToken'] = 'NO';
	// 	}
	// 	this.userDeviceTrackingService.postUserSiteTracking(payload).subscribe();
	// }
	  
	 promise1 = new Promise((resolve, reject) => {
		const loop = () => this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP) ? resolve(this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP)) : setTimeout(loop)
		loop();
	  });
	  
	  
}
