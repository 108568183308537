const imageUrl = "https://d3lzbbhyvqc4k.cloudfront.net/images-webp/";

export const customerStoryData = [
    {
        'customerId': 1,
        'customerName': 'Mr. Gangadhar Panda',
        'customerImg': imageUrl+  'gangadhar_panda_p.webp',
        'customerDesignation': 'CIT (OSD), FATF Cell, Dept. of Revenue',
        'customerCompany': 'Ministry of Finance',
        'customerReview': 'During my long association with Taxmann spanning over 25 years, I have been regularly referring to Taxmann publication’s books and journals for my professional work. I find online resources available in Taxmann portal very comprehensive as it displays real-time updates on tax news, circulars, notifications and amendments to various fiscal regulations. Also, the Taxmann portal facilitates browsing of various Acts and Rules at the click of a mouse and provides easy access to vast database of analytical articles adding value to my professional works and research efforts with accuracy, efficiency, and quality.'
    },
    {
        'customerId': 2,
        'customerName': 'CA. Amit Rustagi',
        'customerImg': imageUrl + 'amit_rustagi_p.webp',
        'customerDesignation': 'Group Head Taxation',
        'customerCompany': 'Security and Intelligence Services India Limited',
        'customerReview': 'My association with Taxmann is more than 20 years old now. The Taxmann website, apart from giving the latest news, circulars and notifications relevant for professionals, also serves as a “Knowledge Portal”, and is an excellent source of information. It includes analytical articles and updates on diverse subjects... They have the largest online database on taxation including direct tax, indirect tax and international taxation. The online research platform is very useful and enable us in tax planning activities. Taxmann App and Taxmann Twitter account is an indispensable tool for practicing professionals as well as those in the industry.'
    },
    {
        'customerId': 3,
        'customerName': 'CA. Pankaj Shah',
        'customerImg': imageUrl + 'capankaj_p.webp',
        'customerDesignation': 'Ex Chairman',
        'customerCompany': 'ICAI, Indore and Partner - G. J. Shah & Co.',
        'customerReview': 'Taxmann is a robust repository of knowledge which is very user friendly and my first and last resort for any research. Articles, commentaries and latest decision alerts are the USPs of Taxmann. The section wise digest and master guide make it a whole code from an amateur to a leading lawyer.'
    },
    {
        'customerId': 4,
        'customerName': 'Prof.(Dr.) Yogendra Kumar Srivastava',
        'customerImg': imageUrl + 'prof_yogendra_p.webp',
        'customerDesignation': 'Director',
        'customerCompany': 'JLU School of Law, Bhopal',
        'customerReview': 'Taxmann has been amazing in there service to academics especially to Jagran Lakecity University - School of Law. Their products are comprehensive and cover various aspects of law for the better interest of students. Promptness in their service and outstanding support in carrying out academic and extracurricular activities like moots, conferences and symposium puts it among the top publishers in the arena and definitely for the betterment of academics in the society'
    },
    {
        'customerId': 5,
        'customerName': 'CA. Sukesh Choradiya',
        'customerImg': imageUrl + 'sukesh_p.webp',
        'customerDesignation': 'Secretary',
        'customerCompany': 'CIRC of ICAI, Jabalpur',
        'customerReview': '"Taxmann Publications" content is best and most knowledgeable. The material in the field of Direct and Indirect taxes, Corporate laws, International taxation etc. is available for both students and practitioners through acts, referencers and much more. We use it in our day to day practice.'
    }
];

export const taxationData = [
    {
        'taxationId': 1,
        'taxationName': 'RESEARCH',
        'taxationDescription': 'Get real time updates & more authentic database on Case Laws, Acts, Rules, Circulars & Notifications, Articles and Commentary',
        'taxationClass': 'green',
        'taxationImg':imageUrl +  'homepage-icons/research.webp',
        'url': '/research'
    },
    {
        'taxationId': 2,
        'taxationName': 'PRACTICE',
        'taxationDescription': 'Complete solution to your Income Tax, GST and Company Law Practice',
        'taxationClass': 'blue',
        'taxationImg': imageUrl + 'homepage-icons/practice.webp'
    },
    {
        'taxationId': 3,
        'taxationName': 'EXAMS',
        'taxationDescription': 'Your comprehensive learning & assesment platform for professional exams',
        'taxationClass': 'magenta',
        'taxationImg': imageUrl + 'homepage-icons/exams.webp'
    },
    {
        'taxationId': 4,
        'taxationName': 'COMPLIANCE',
        'taxationDescription': 'Automated solutions for all your Income-tax compliances and XBRL filings',
        'taxationClass': 'purple',
        'taxationImg': imageUrl +  'homepage-icons/compliance.webp'
    },
];

export const partnerData = [
    {
        'imgPath': imageUrl + 'homepage/partners/moca.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/uber.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/supreme.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/dlf.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/amazon.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/itindia.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/reliance.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/kpmg.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/airtel.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/asianpaints.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/deloitte.webp'
    },
    {
        'imgPath': imageUrl + 'homepage/partners/pwc.webp'
    }
];


export const alphaData = ['All', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const faqData = [
    {
        ques: 'At what rate firms are required to pay tax on their income?',
        ans: 'HTML stands for HyperText Markup Language. HTML is the standard markup language for describing the structure of web pages.'
    },
    {
        ques: 'What is Bootstrap?',
        ans: 'Bootstrap is a sleek, intuitive, and powerful front-end framework for faster and easier web development. It is a collection of CSS and HTML conventions.'
    },
    {
        ques: 'Does the tax liability of an individual get affected due to his residential status? If yes, explain.',
        ans: 'CSS stands for Cascading Style Sheet. CSS allows you to specify various style properties for a given HTML element such as colors, backgrounds, fonts etc.'

    },
    {
        ques: 'What is deferred tax?',
        ans: "A tax liability that a company has to pay but does not pay at that current point and it will be responsible for paying it in future is termed a deferred tax. Deferred tax occurs due to the difference in a company's balance sheet, due to the differences between accounting practices and tax regulations."
    }
];

export const connectWithUsData = [
    {
        title: 'Call Assistance',
        des: 'Connect with a Taxmann Customer Support Associate who can help you fix your issue.',
        imgUrl: imageUrl + 'pricing/call.webp',
        href: 'tel:011-45562222'
    },
    {
        title: 'Mail Assistance',
        des: 'Connect with a Taxmann Customer Support Associate who can help you fix your issue.',
        imgUrl: imageUrl + 'pricing/mail.webp',
        href: 'mailto:sales@taxmann.com'
    },
    {
        title: 'Live Chat Assistance',
        des: 'Connect with a Taxmann Customer Support Associate who can help you fix your issue.',
        imgUrl: imageUrl + 'pricing/chat.webp',
        href:  location.href + "?enableChatSupport=true"
    }

];

export interface CustomerStory {
    customerId: number,
    customerName: string,
    customerImg: string,
    customerDesignation: string,
    customerCompany: string,
    customerReview: string
}

export interface Taxation {
    taxationId: number,
    taxationName: string,
    taxationDescription: string,
    taxationClass: string,
    taxationImg: string,
    url?: string
}

export interface Partner {
    imgPath: string
}

export const connectWithContactUsData = [
    {
        title: 'Call Assistance',
        des: '011-45562222',
        imgUrl: imageUrl + 'call.webp',
        href: 'tel:011-45562222',
        target: '_self'
    },
    {
        title: 'Mail Assistance',
        des: 'sales@taxmann.com',
        imgUrl: imageUrl + 'mail.webp',
        href: 'mailto:sales@taxmann.com',
        target: '_self'
    },
    {
        title: 'Whatsapp Assistance',
        des: '9910274007',
        imgUrl: imageUrl + 'whatsappic.webp',
        href: "https://wa.me/+919910274007?lang=en",
        target: '_blank'
    }

];



