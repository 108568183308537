import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class TitleGuard implements CanActivate {

    constructor(private titleService: Title) { }

    canActivate(route: any): Observable<boolean> | Promise<boolean> | boolean {
        if (route.data && route.data.title) {
            this.titleService.setTitle(route.data.title);
        }else{
            this.titleService.setTitle('Taxmann');
        }

        return true;
    }

}
