export let RESEARCH_OLD_URL = [
    '/caselaws.aspx?t=qs?st=',
    '/sessionex.aspx',
    '/income-tax-acts.aspx',
    '/income-tax.aspx',
    '/login.aspx',
    '/gst.taxmann.com/parentrefreshpage.aspx',
    '/circulars-notifications.aspx',
    '/all-about-income-tax-act.aspx',
    '/login.aspx?continue=https://gst.taxmann.com/parentrefreshpage.aspx'
]

export let BOOKSTORE_OLD_URL = [
    '/BookStore/search.aspx'
]