import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  console.log = (...args) => { } // override console.log for not printing in prod mode
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));



// // googletagmanager added
// if (window.location.hostname.indexOf('www.taxmann.com') !== -1) {
//   console.log("google tag manager added")
//   const head = document.getElementsByTagName('head')[0];
//   const script = document.createElement('script');
//   script.type = 'text/javascript';
//   script.src = "https://www.googletagmanager.com/gtag/js?id=UA-31282975-1";
//   script.async = true;
//   head.appendChild(script);
//
//   // ga script 4
//   (function (w: any, d, s, l, i) {
//     w[l] = w[l] || [];
//     w[l].push({
//       'gtm.start': new Date().getTime(),
//       event: 'gtm.js'
//     });
//     var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
//     const head1 = document.getElementsByTagName('head')[0];
//     const script1 = document.createElement('script');
//     script1.type = 'text/javascript';
//     script1.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
//     script1.async = true;
//     head1.appendChild(script1);
//     //j.async=true;
//     //j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
//     f.parentNode.insertBefore(j, f);
//   })(window, document, 'script', 'dataLayer', 'GTM-MTJ44P');
// }

// if ((window.location.href.indexOf('preview-document') === -1 &&
//   location.pathname.toLowerCase().indexOf('/virtualbooks/reader/') < 0 && location.pathname.toLowerCase().indexOf('/virtualbooks/virtualreader/') < 0 &&
//   location.pathname.toLowerCase().indexOf('/book-view/') < 0) && location.pathname.toLowerCase().indexOf('/bookstore/product-catalogues') < 0
//   && location.pathname.toLowerCase().indexOf('/bookstore/dealer-catalogues') < 0) {
//     function Verloop() {
//       this.setRecipe("46Feab8CDfZa8CN4R");
//     };
//   (function(w: any, d, s, u) {
//     w.Verloop = function(c) { w.Verloop._.push(c) }; w.Verloop._ = []; w.Verloop.url = u;
//     var h = d.getElementsByTagName(s)[0], j: any = d.createElement(s); j.async = true;
//     j.src = 'https://taxmann.verloop.io/livechat/script.min.js';
//     h.parentNode.insertBefore(j, h);
//   })(window, document, 'script', 'https://taxmann.verloop.io/livechat');

//}
