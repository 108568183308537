import { AfterViewInit, Component, Input  } from '@angular/core';
declare const $: any;
@Component({
  selector: 'app-taxmann-carousel',
  templateUrl: './taxmann-carousel.component.html',
  styleUrls: ['./taxmann-carousel.component.scss']
})
export class TaxmannCarouselComponent implements AfterViewInit {

  @Input() carouselId: string;
  @Input() carouselConfig: any;
  constructor() { }

  ngAfterViewInit() {
    $(`#${this.carouselId}`).owlCarousel(this.carouselConfig);
  }


}
