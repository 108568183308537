import * as CryptoJS from "crypto-js"
/**
 * @author Manoj Yadav
 * @description encrypt and decrypt the request/response
 */
export class TaxmannCryptoJS {
    SECRET_KEY = ''
    constructor(){
      
    }
    setPrivateKey(key) {
        this.SECRET_KEY = key;
    }

    encrypt(value:string) {
        if (value === undefined) {
            return value;
        }
        let salt = CryptoJS.lib.WordArray.random(this.encryptionConfigs.saltSize);
        let key = CryptoJS.PBKDF2(this.SECRET_KEY.trim(), salt, {
            keySize: this.encryptionConfigs.keySize,
            iterations: this.encryptionConfigs.iterations
        });

        let iv = CryptoJS.lib.WordArray.random(this.encryptionConfigs.ivSize);
        let encrypted = CryptoJS.AES.encrypt(value, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC
        });
        let encryptedString = salt.toString() + ':' + iv.toString() + ':' + encrypted.toString();
        return encryptedString;
    }

    decrypt(encryptedString:string) {
        let encryptedStringParts = encryptedString.split(':');
        let salt = CryptoJS.enc.Hex.parse(encryptedStringParts[0]);
        let iv = CryptoJS.enc.Hex.parse(encryptedStringParts[1])
        let encrypted = encryptedStringParts[2];

        let key = CryptoJS.PBKDF2(this.SECRET_KEY.trim(), salt, {
            keySize: this.encryptionConfigs.keySize,
            iterations: this.encryptionConfigs.iterations
        });

        let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC
        }).toString(CryptoJS.enc.Utf8);
        try {
            decrypted = JSON.parse(decrypted);
        } catch (e) { console.log("decrypted failed", e); }
        return decrypted;
    }

    get encryptionConfigs() {
        return {
            keySize: 256 / 32,
            ivSize: 128 / 8,
            saltSize: 128 / 8,
            ivLength: 32,
            saltLength: 32,
            iterations: 100,
            headerKeySize: 4
        }
    }
}
