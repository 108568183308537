declare var gtag: Function;

export function gaEventTrack(category, label, action,categoryIndex=-1,labelIndex=-1,preCatContent=-1,postCatContent=-1,preLabContent=-1,postLabContent=-1) {
    return function (target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
        const original = descriptor.value;


        descriptor.value = async function  (...args: any[]) {
           if(window.location.hostname.indexOf('www.taxmann.com') !== -1){



            try {
                console.log("event recieved");
                console.log("args====>",args);
                console.log(args[preLabContent]);
                console.log(args[postLabContent]);
                // let params = "";
                let categoryText = category;
                let labelText = label;
                if(args.length>0 && categoryIndex >=0){
                    categoryText= category+args[categoryIndex]
                    }
                    if(preCatContent>=0){
                        categoryText=args[preCatContent]+"_"+categoryText
                    }
                    if(postCatContent>=0){
                        categoryText=categoryText+'_'+args[postCatContent]
                    }
                if(args.length>0 && labelIndex >=0){
                    labelText= label+args[labelIndex]
                    }
                    if(preLabContent>=0){
                        console.log(args[preLabContent]);

                        labelText=args[preLabContent]+"_"+labelText
                    }
                    if(postLabContent>=0){
                        console.log(args[postLabContent]);
                        labelText=labelText+'_'+args[postLabContent]
                    }
                gtag( 'event', action, {
                    event_category: categoryText,
                    event_label: labelText,
                });

                const result = original.apply(this, args);
                return result;

            } catch (error) {
                console.log(error);
                return null
            }
       } else{
           const result = original.apply(this, args);
               return result;
       }
        };

        return descriptor;
    };

}
