export const EServicesDetails = [{
    key: 0,
    name: 'Daily Tax & Corporate Laws Digest',
    Data: [
        {
            type: 'li',
            text: 'All The Case Laws and Statutory Updates reported at Taxmann.com are succinctly Summarized for you.',
            hasChild: false
        },
        {
            type: 'li',
            text: 'Get Every Update in a Single Glance, on:',
            hasChild: true,
            details: {
                type: 'ul',
                list: [{
                    text: 'Income-tax'
                },
                {
                  text:  'International Taxation'
                },
                {
                    text: 'SEBI Laws'
                },
                {
                    text: 'Accounts and Audit'
                },
                {
                    text: 'GST'
                },
                {
                    text: 'Companies Act'
                },
                {
                    text: 'Banking Laws'
                },
                {
                    text: 'Indirect Taxes'
                }
                            ]         
                              }
        },
        /*{
            type: 'li',
            text: 'A section-wise digest is provided to help one in keeping himself abreast with all such updates.', 
            hasChild: false
        }*/
    ]
},
{
    key: 1,
    name: 'Accounts & Audit Daily',
    Data: [
        {
            type: 'li',
            text: 'Never Stay Behind on Important Updates.',
            hasChild: false
        },
        {
            type: 'li',
            text: 'Keeping You abreast in Real-time, this email service covers updates on:',
            hasChild: true,
            details: {
                type: 'ul',
                list: [{
                    text: 'Accounting Standards'
                },
                {
                  text:  'Indian Accounting Standards'
                },
                {
                    text: 'CARO'
                },
                {
                    text: 'Companies Act'
                },
                            ]          
                             }
        },
    ]
},
{
    key: 2,
    name: 'Corporate Laws Daily',
    Data: [
        {
            type: 'li',
            text: 'E-mails That Keep You Ahead of The Crowd.',
            hasChild: false,
        },
        {
            type: 'li',
            text: "Get Updates on 'All Important Happenings' on:",
            hasChild: true,
            details: {
                type: 'ul',
                list: [{
                    text: 'Companies Act'
                },
                {
                  text:  'Insolvency & Bankruptcy Code'
                },
                {
                    text: 'SEBI'
                },
                {
                    text: 'FEMA, Banking and Insurance Laws'
                },
                {
                    text: 'Competition Law'
                },
                            ]      
                                 }
                                 
        },
        {
            type: 'li',
            text: 'Delivered to you on a Real-time basis.',
            hasChild: false,
        }
    ]
},

/* ppc */
{
    key: 3,
    name: 'Daily Tax & Corporate Laws Digest',
    Data: [
        {
            type: 'li',
            text: 'All The Case Laws and Statutory Updates reported at Taxmann.com are succinctly Summarized for you.',
            hasChild: false
        },
        {
            type: 'li',
            text: 'Get Every Update in a Single Glance, on:',
            hasChild: true,
            details: {
                type: 'ul',
                list: [{
                    text: 'Income-tax'
                },
                {
                  text:  'International Taxation'
                },
                {
                    text: 'SEBI Laws'
                },
                {
                    text: 'Accounts and Audit'
                },
                {
                    text: 'GST'
                },
                {
                    text: 'Companies Act'
                },
                {
                    text: 'Banking Laws'
                },
                {
                    text: 'Indirect Taxes'
                }
                            ]         
                              }
        },
        /*{
            type: 'li',
            text: 'A section-wise digest is provided to help one in keeping himself abreast with all such updates.', 
            hasChild: false
        }*/
    ]
},
{
    key: 4,
    name: 'GST Daily',
    Data: [
        {
            type: 'li',
            text: 'Get Daily Emails on GST and stay apprised of the most important stories of the day',
            hasChild: false
        }
       
    ]
},
{
    key: 5,
    name: 'Taxmann Daily',
    Data: [
        {
            type: 'li',
            text: 'Get Daily Emails to keep you apprised of the most important story of the day',
            hasChild: false
        }
       
    ]
},
{
    key: 6,
    name: 'Corporate Laws Daily',
    Data: [
        {
            type: 'li',
            text: 'E-mails That Keep You Ahead of The Crowd.',
            hasChild: false,
        },
        {
            type: 'li',
            text: "Get Updates on 'All Important Happenings' on:",
            hasChild: true,
            details: {
                type: 'ul',
                list: [{
                    text: 'Companies Act'
                },
                {
                  text:  'Insolvency & Bankruptcy Code'
                },
                {
                    text: 'SEBI'
                },
                {
                    text: 'FEMA, Banking and Insurance Laws'
                },
                {
                    text: 'Competition Law'
                },
                            ]      
                                 }
                                 
        },
        {
            type: 'li',
            text: 'Delivered to you on a Real-time basis.',
            hasChild: false,
        }
    ]
},

{
    key: 7,
    name: 'Accounts & Audit Daily',
    Data: [
        {
            type: 'li',
            text: 'Never Stay Behind on Important Updates.',
            hasChild: false
        },
        {
            type: 'li',
            text: 'Keeping You abreast in Real-time, this email service covers updates on:',
            hasChild: true,
            details: {
                type: 'ul',
                list: [{
                    text: 'Accounting Standards'
                },
                {
                  text:  'Indian Accounting Standards'
                },
                {
                    text: 'CARO'
                },
                {
                    text: 'Companies Act'
                },
                            ]          
                             }
        },
    ]
},
{
    key: 8,
    name: 'Taxmann - This Week',
    Data: [
        {
            type: 'li',
            text: 'Updates reported at taxmann.com throughout the week, analytically summarized just for you',
            hasChild: false
        }      
    ]
},




]




