import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { ProductCatalogueService } from '../cms-forms/modules/taxmann-product-catelogues/taxmann-product-catelogues.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TaxmannCommonModule } from '../taxmann-common/taxmann-common.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    OverlayPanelModule,
    TaxmannCommonModule
  ],
  exports: [FooterComponent],
  providers: [ProductCatalogueService]
})
export class FooterModule { }