import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalUtility } from '../../utility/global-utility';
import { LocalStorageDataModel } from '../../../constants/local-storage-data-model';

@Injectable()
export class ResearchSignedGuard {
	constructor(private router: Router, private globalUtility: GlobalUtility) {
	}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		const loggdIn = this.globalUtility.isLoggedIn();
		if (loggdIn) {
			return true;
		}
		this.router.navigate(['/research'])
		return false;
	}

}


@Injectable()
export class ResearchUnsignedGuard {
	constructor(
		private router: Router,
		private globalUtility: GlobalUtility
	) { }

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		const loggdIn = this.globalUtility.isLoggedIn();
		if (!loggdIn) {
			return true;
		}
		//for redirection of User to last Accessed Product
		const lastAccessBaseURLByUser = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.LAST_ACCESS_BASE_URL_BY_USER);
		// lastAccessBaseURLByUser ? this.router.navigate([lastAccessBaseURLByUser]) : this.router.navigate(['/']);
		lastAccessBaseURLByUser ? this.router.navigate([lastAccessBaseURLByUser]) : this.router.navigate(['/research'])
		return false;
	}

}

@Injectable()
export class ResearchUnsignedHomeGuard {
	constructor(
		private router: Router,
		private globalUtility: GlobalUtility
	) { }

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		const loggdIn = this.globalUtility.isLoggedIn();
		if (!loggdIn) {
			return true;
		}
      this.router.navigate(['/research'])
		return false;
	}

}
