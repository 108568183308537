import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { scale } from 'src/animations/common.animation';


@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [scale]
})
export class ScrollToTopComponent implements OnInit, OnDestroy {
  showFloatingBtn: boolean;
  scrollTop: number = 100;

  private timerRef;
  private listener;
  constructor(private ref: ChangeDetectorRef, private renderer: Renderer2) {
  }


  ngOnInit() {
    this.listener = this.renderer.listen('document', 'scroll', (e) => {
      this.onscroll(e);
    })
  }


  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onscroll(e) {
    if (this.timerRef) clearTimeout(this.timerRef); //debouncing
    this.timerRef = setTimeout(() => {
      let currentPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (currentPos > this.scrollTop) {
        this.showFloatingBtn = true;
      }
      else {
        this.showFloatingBtn = false;
      }
      this.ref.detectChanges();
    }, 50)

  }

  ngOnDestroy() {
    if (this.listener) {
      this.listener();
    }
  }
}
