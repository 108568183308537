import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class BrowserAllowedGuard implements CanActivate {

    AllowedBrowsersList=['chrome', 'firefox','edge', 'ms-edge-chromium','Safari','safari'];

  constructor(private router: Router,
    private deviceService: DeviceDetectorService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   let  deviceInfo = this.deviceService.getDeviceInfo();
   let browserInfo = deviceInfo['browser'];
	if(deviceInfo['device'] == 'iPad') {
		return true;
	}
    if(this.AllowedBrowsersList.includes(browserInfo.toLowerCase())){
      return true;
    }else {
        return this.router.navigate(['browser-not-supported']);
    }
  }


}
