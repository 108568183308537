
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { SelectivePreloadingStrategy, routing } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { TaxmannHomeComponent } from './taxmann-home/taxmann-home.component';

import { TaxmannFooterComponent } from './taxmann-footer/taxmann-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StartupService } from './service/taxmann-service/app-start.service';
import { TaxmannCommonModule } from './taxmann-common/taxmann-common.module';
import { ConfirmationService } from './taxmann-common/services/confirmation.service';
import { TaxmannBannerModule } from './modules/taxmann-banner/taxmann-banner.module';
import { TaxmannNewsUpdateModule } from './taxmann-news-update/taxmann-news-update.module';
import { TaxmannOrganizationModule } from './modules/taxmann-organization/taxmann-organization.module';
import { TaxmannCustomerStoriesModule } from './modules/taxmann-customer-stories/taxmann-customer-stories.module';
import { HeaderNewModule } from './modules/header-new/header-new.module';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ScrollToTopModule } from './modules/scroll-to-top/scroll-to-top.module';
import { UserDeviceTrackingService } from './service/taxmann-service/userDeviceTrack.service';
import { ArchiveSharedService } from './service/archive-shared.service';

import { JoyrideModule } from 'ngx-joyride';
import { ResearchUnsignedGuard } from './research/guards/research-login.guard';
import { BrowserGuardComponent } from './browser-guard/browser-guard.component';
import { CommonAlertService } from './service/common-alert.service';
import { LiveStreamModule } from './live-stream/live-stream.module';
import { DBConfig, NgxIndexedDBModule } from "ngx-indexed-db";
import { JsonLDService } from './utility/jsonld.service';
import { ReturnPolicyComponent } from './taxmann-legal/component/return-policy/return-policy.component';
import { PrivacyPolicyComponent } from './taxmann-legal/component/privacy-policy/privacy-policy.component';
import { PaymentTermsComponent } from './taxmann-legal/component/payment-terms/payment-terms.component';
import { EULAComponent } from './taxmann-legal/component/eula/eula.component';
import { DisclamierComponent } from './taxmann-legal/component/disclamier/disclamier.compnent';
import { FooterModule } from './footer/footer.module';
import { ScrollToTopDocumentModule } from './modules/scroll-to-top-document/scroll-to-top-document.module';
import {AditComponent} from "./bookstore/adit/adit.component";
import {PricingModule} from "./pricing/pricing.module";
import {NgxPageScrollModule} from "ngx-page-scroll";
import {TaxmannCarouselModule} from "./modules/taxmann-carousel/taxmann-carousel.module";
import {RatingReviewModule} from "./library/rating-review/rating-review.module";
import {ControlMessageModule} from "./shared/control-message/control-message.module";
import { DropdownModule } from 'primeng/dropdown';

export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.load();
}

const dbConfig: DBConfig = {
  name: 'routeTrackingDB',
  version: 1,
  objectStoresMeta: [{
    store: 'user_device_route_tracking',
    storeConfig: {
      keyPath: 'id', autoIncrement: true
    },
    storeSchema: [
      { name: 'data', keypath: 'data', options: { unique: true } }
    ]
  }]
};

@NgModule({
  declarations: [
    AppComponent,
	  TaxmannHomeComponent,
    TaxmannFooterComponent,
    BrowserGuardComponent,
    ReturnPolicyComponent,
    PrivacyPolicyComponent,
    PaymentTermsComponent,
    EULAComponent,
    DisclamierComponent,
	  AditComponent,
  ],
  imports: [
    routing,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TaxmannCommonModule,
    TaxmannBannerModule,
    TaxmannNewsUpdateModule,
    TaxmannOrganizationModule,
    TaxmannCustomerStoriesModule,
    HeaderNewModule,
    FooterModule,
    ToastModule,
    ScrollToTopModule,
    JoyrideModule.forRoot(),
    LiveStreamModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ScrollToTopDocumentModule,
	  NgxPageScrollModule,
	  TaxmannCarouselModule,
	  RatingReviewModule,
	  ReactiveFormsModule,
	  ControlMessageModule,
	  PricingModule,
    DropdownModule
  ],
  providers: [
    SelectivePreloadingStrategy,
    DeviceDetectorService,
    StartupService,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true
    },
    ConfirmationService,
    MessageService,
    UserDeviceTrackingService,
    ArchiveSharedService,
    ResearchUnsignedGuard,
    CommonAlertService,
    JsonLDService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

