export const CarouselConfig = {
	'newscard': {
		loop: true,
		center: false,
		margin: 20,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true
			},

			768: {
				items: 2
			},

			1024: {
				items: 3
			},

			1170: {
				items: 3
			},

			1500: {
				items: 3
			},
			1700: {
				items: 4
			},
			1800: {
				items: 4
			}
		}
	},
	'organisationcard': {
		loop: true,
		center: false,
		margin: 0,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 2,
				loop: true,
			},

			500: {
				items: 3
			},

			768: {
				items: 3
			},

			1024: {
				items: 3
			},

			1170: {
				items: 3
			},

			1500: {
				items: 3
			}
		}
	},
	'researchnews': {
		loop: true,
		center: false,
		margin: 20,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true
			},

			768: {
				items: 2
			},

			1170: {
				items: 3
			},

			1500: {
				items: 3
			}
		}
	},
	'researchcustomer': {
		loop: true,
		center: false,
		margin: 0,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],

		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true

			},
			768: {
				items: 1
			},
			1170: {
				items: 1
			},
			1500: {
				items: 1
			}
		}
	},
	'opinioncard': {
		loop: true,
		center: false,
		margin: 0,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true
			},

			768: {
				items: 2
			},

			1170: {
				items: 2
			},

			1500: {
				items: 2
			}
		}
	},
	'offercards': {
		autoplayHoverPause: true,
		loop: true,
		center: false,
		margin: 0,
		autoplay: false,
		dots: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true
			},

			768: {
				items: 4
			},

			1024: {
				items: 4
			},

			1170: {
				items: 4
			},

			1500: {
				items: 4
			},
			1700: {
				items: 4
			},
			1800: {
				items: 4
			}
		}
	},
	'mobBooks': {
		loop: true,
		center: false,
		margin: 0,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true
			},

			768: {
				items: 1
			},

			1170: {
				items: 1
			},

			1500: {
				items: 1
			}
		}
	},
	'customercard': {
		loop: true,
		center: false,
		margin: 20,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true

			},
			768: {
				items: 1
			},
			1170: {
				items: 1
			},
			1500: {
				items: 1
			}
		}
	},
	'bookstorebanner': {
		autoplayHoverPause: true,
		loop: true,
		center: false,
		margin: 30,
		autoplay: true,
		dots: true,
		nav: true,
		autoplayTimeout: 5000,
		autoplaySpeed: 1000,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true
			},
			768: {
				items: 1
			},
			1170: {
				items: 1
			},
			1500: {
				items: 1
			}
		}
	},
	'suggestedbooks': {
		loop: false,
		center: false,
		margin: 0,
		autoplay: false,
		dots: true,
		nav: true,
		navText: ["<i class='fa fa-angle-left fa-2x orange' aria-hidden='true'></i>", "<i class='fa fa-angle-right fa-2x orange' aria-hidden='true'></i>"],
		responsive: {
			0: {
				items: 1,
				loop: true,
				center: true
			},
			768: {
				items: 3
			},
			1170: {
				items: 4
			},
			1500: {
				items: 4
			},
			1700: {
				items: 4
			},
			1800: {
				items: 4
			}
		}
	}
}



