import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'control-messages',
	template: `<p *ngIf="errorMessage" class="errorMessage">{{errorMessage}}</p>`,
	styles: [`
		.errorMessage{
			color: red;
			font-size: 14px;
			text-align: left;
			margin-top: 10px;
		}
	`]
})
export class ControlMessages {
	@Input() control: FormControl;
	decimal: number;

	constructor() {
	}

	config(key, validatorValue) {
		const _config = {
			'required': `This field is required.`,
			'invalidEmailAddress': `Please enter a valid email address`,
			'pattern': `Please enter a valid input`,
			'minlength': `This field must be atleast ${validatorValue.minlength ? validatorValue.minlength.requiredLength : ''} characters long.`,
			'maxlength': `This field must be less than ${validatorValue.maxlength ? validatorValue.maxlength.requiredLength : 1} .`,
			'max': `This field must be less than ${validatorValue.max ? validatorValue.max.max : 0 + 1}.`,
			'min': `This field must be greater than or equal to ${validatorValue.min ? validatorValue.min.min : 0 + 1}.`,
			'maxValue': `This field must be less than ${validatorValue.maxValue}.`,
			'lessThan': `This field must be less than ${validatorValue.lessThan}.`,
			'greaterThan': `This field must be greater than ${validatorValue.greaterThan}.`,
			'validateEmail': `Enter a valid email`,
			'email': `Enter a valid email`,
			'equalTo': `This field must be ${validatorValue.lessThan} digits.`,
			'phoneNumberInvalid': `This field must be 10 digits.`, //PhoneNumberInvalid message (for Indian users specific)
		};
		return _config[key];
	}

	get errorMessage() {
		if (this.control.dirty && this.control.touched) {
			for (const propertyName in this.control.errors) {
				if (this.control.errors.hasOwnProperty(propertyName)) {
					return this.control.errors.label || this.config(propertyName, this.control.errors);
				}
			}
		}
		return '';
	}

}
