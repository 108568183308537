import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})

export class IndexDBService {
	constructor(private dbService: NgxIndexedDBService) {
	}

	addData(storeName: string, indexName: any, data: any) {
		this.dbService.getByIndex(storeName, indexName, '').subscribe((existData:any) => {
			if(_.isEmpty(existData)) {
				this.dbService.add(storeName, {data: data}).subscribe((key) => {
					// console.log('key: ', key);
				  });
			}
		  });

	}

	getAllData(storeName: string) {
		return this.dbService.getAll(storeName);
	}

	deleteData(storeName: string, key: string) {
		return this.dbService.deleteByKey(storeName ,key);
	}

	clearStore(storeName: string): any {
		return this.dbService.clear(storeName);
	}

}
