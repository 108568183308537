import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaxmannUrl } from 'src/app/api-url/taxmann-url.enum';
import { APIResponse } from 'src/app/models/interfaces/api-response';
import { HttpHelperService } from 'src/app/service/http-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';

@Injectable()
export class ProductCatalogueService {

  constructor(private _httpHelperService: HttpHelperService, private globalUtility: GlobalUtility) { }


  getProductCatalogue(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.GET_CATALOGUE);
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }
}
