import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopDocumentComponent } from './scroll-to-top-document.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ScrollToTopDocumentComponent],
  exports: [ScrollToTopDocumentComponent]
})
export class ScrollToTopDocumentModule { }
