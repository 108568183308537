import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[autoFocusInput]'
})
export class AutofocusDirective {

    inputEl: HTMLInputElement;
    subs;
    constructor(el: ElementRef, private renderer: Renderer2) {
        this.inputEl = el.nativeElement;
    }

    ngOnInit() {
        if (this.inputEl) {
            this.subs = this.renderer.listen(this.inputEl, 'focus', e => {
                this.inputEl.select()
            })
        }
    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs();
        }
    }
}