import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { TaxPracticeSuggestionService } from "../services/tax-practice-suggestions.service";
import { IndexedDBService } from 'src/app/service/indexed-db.service';

@Component({
	selector: 'app-taxpractice-search',
	templateUrl: './taxpractice-search.component.html',
	styleUrls: ['./taxpractice-search.component.scss']
})
export class TaxpracticeSearchComponent implements OnInit, OnDestroy {

	public queryField: FormControl = new FormControl('');
	public queryFieldValue: string = '';
	public isSuggestionBoxShow = false;
	public suggestionsData = [];
	public suggestionsResponseData = [];
	public userSearchHistory = [];
	public isLoggedIn: boolean;
	public isActive = false
	public arrowkeyLocation = 0;
	public arrowkeyLocationForHistory = 0;
	public showCrossIcon: boolean = false;
	public mobileView: boolean = false;
	private _isGoForSuggestions = true;
	private _isRead = 0;
	private _isNullCategory = 0;

	private _suggestionsSubscription$: Subscription;
	private _queryFieldSubscription$: Subscription;
	private _userHistorySubscription$: Subscription;
	private _routeEventsSubscription$: Subscription;
	private _removeFromHistorySubscription$: Subscription;

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _indexedDBService: IndexedDBService,
		private _globalUtility: GlobalUtility,
		private _taxPracticeSuggestionService: TaxPracticeSuggestionService,
	) { }

	async ngOnInit() {
		this.isMobileView();
		let params = this._activatedRoute.snapshot.queryParams;
		this._paramsHandling(params);
		this._listenEvents();
		// await this._indexedDBService.addUserHistory('user-history', 'searchHistory', []);
		// await this._indexedDBService.addUserHistory('inserting-user-new-history', 'searchHistory', []);
		this.isLoggedIn = this._globalUtility.isLoggedIn();
		if (this.isLoggedIn) {
			this._getUsersHistory();
		//	this._getUserSearchHistory();
		}
	}

	/**
	 * @description listen route change events and global search box change event
	 */
	private _listenEvents() {
		this._routeEventsSubscription$ = this._router.events
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					let params = this._activatedRoute.snapshot.queryParams;
					this._paramsHandling(params);
				}
			});

		this._queryFieldSubscription$ = this.queryField.valueChanges
			.pipe(
				debounceTime(400),
				distinctUntilChanged()
			)
			.subscribe((queryFieldValue) => {
				this.queryFieldValue = queryFieldValue;
				let _queryFieldValue = queryFieldValue;
				this.suggestionsResponseData = [];
				if (_queryFieldValue && _queryFieldValue.trim().length && _queryFieldValue.trim().length > 0) {
					this._isGoForSuggestions = true;
					this._getUsersHistory();
					this._getSuggestions();
				} else {
					this._getUsersHistory();
					this.suggestionsData = [...this.userSearchHistory];
				}
			});
	}

	private _paramsHandling(params) {
		if (params.searchData) {
			this.queryField.setValue(params.searchData, { emitEvent: false });
		} else {
			this.queryField.reset();
		}
	}

	// private _getUserSearchHistory() {
	// 	this._userHistorySubscription$ = this._taxPracticeSuggestionService.getUserSearchHistory()
	// 		.subscribe(async (userSearchHistoryAPIResponse) => {
	// 			await this._indexedDBService.addUserHistory('user-history', 'searchHistory', userSearchHistoryAPIResponse.Data);
	// 		});
	// }

	keyDown(event: KeyboardEvent) {
		let searchText: string;
		if (this.queryField.value && this.queryField.value.trim().length) {
			this.showCrossIcon = true;
			searchText = this.queryField.value.trim();
		}
		switch (event.key) {
			case 'ArrowDown':
				if (this.suggestionsData && this.suggestionsData.length >= this.arrowkeyLocation + 1)
					this.arrowkeyLocation++;
				this._foucsOnActiveIndex();
				break;
			case 'ArrowUp':
				if (this.arrowkeyLocation > 0) {
					this.arrowkeyLocation--;
					this._foucsOnActiveIndex();
				}
				break;
			case 'Enter':
				this.isActive = false;
				let activeSelection = document.getElementsByClassName("filter-select-list s-active");
				if (this.arrowkeyLocation > 0 && activeSelection && activeSelection[0]) {
					(activeSelection[0].children[0] as HTMLElement).click();
					return;
				}
				if (searchText && searchText.trim().length) {
					setTimeout(() => {
						this.postSearchLog(searchText);
					}, 100);
					this._insertUserSearchInHistory(searchText);
					this._goToSearchPage(searchText);
					break;
				}
		}
	}

	onSearchClick() {
		this.isActive = false;
		let searchText: string;
		if (this.queryField.value && this.queryField.value.trim().length) {
			searchText = this.queryField.value.trim();
		} else {
			this.showCrossIcon = true;
		}
		if (searchText && searchText.trim().length) {
			this._insertUserSearchInHistory(searchText);
			this._goToSearchPage(searchText);
		}
	}



	private _foucsOnActiveIndex() {
		let activeSelection = document.getElementsByClassName("filter-select-list s-active");
		if (this.arrowkeyLocation > 0 && activeSelection && activeSelection[0]) {
			(activeSelection[0] as HTMLElement).focus();
		}
	}


	/**
	 * @name _insertUserSearchInHistory
	 * @param userSearchKeyword {string} Search word that user insert in global search
	 * @description insertUserSearchInHistory in indexedDB and if lenght of searches is
	 * 				greater than 3 then also request to save into AWS s3
	 * @author Chetan Upreti
	 */
	private async _insertUserSearchInHistory(userSearchKeyword: string) {
		let userHistory = await this._indexedDBService.getUserHistoryForTaxPractice('user-history', 'searchHistory');
		userHistory.unshift(userSearchKeyword);
		await this._indexedDBService.addUserHistory('user-history', 'searchHistory', _.uniq(userHistory));
		let userNewHistory = await this._indexedDBService.getUserHistoryForTaxPractice('inserting-user-new-history', 'searchHistory');
		userNewHistory.unshift(userSearchKeyword);
		await this._indexedDBService.addUserHistory('inserting-user-new-history', 'searchHistory', _.uniq(userNewHistory));
		//await this._taxPracticeSuggestionService.insertUserSearchHistory();
	}

	private _goToSearchPage(searchText): void {
		this.arrowkeyLocation = 0;
		this.arrowkeyLocationForHistory = 0;
		this.isSuggestionBoxShow = false;
		this._isGoForSuggestions = false;
		const navigationURL = `/practice/search`;
		this._router.navigate([navigationURL], { queryParams: { searchData: searchText } });
	}

	onFocusOut() {
		this.arrowkeyLocation = 0;
		this.arrowkeyLocationForHistory = 0;
	}

	onFocus() {
		this.arrowkeyLocation = 0;
		this.arrowkeyLocationForHistory = 0;
		if (this.queryField.value) {
			this.showCrossIcon = true;
		}
	}


	private _getSuggestions() {
		if (!this._isGoForSuggestions) {
			return;
		}
		const paramObj = {
			query: this.queryFieldValue
		};
		this._suggestionsSubscription$ = this._taxPracticeSuggestionService.getTaxPracticeAutoCompleteContent(paramObj)
			.subscribe(suggestionAPIResponse => {
				this.isSuggestionBoxShow = true;
				this.suggestionsResponseData = suggestionAPIResponse.Data.suggestions;
				this.suggestionsData = [...this.userSearchHistory, ...this.suggestionsResponseData];
			});
	}

	async onSuggestionClick(suggestion) {
		this.isActive = false;
		this.queryField.setValue('');
		this.isSuggestionBoxShow = false;
		this._isGoForSuggestions = false;
		this.suggestionsResponseData.length = 0;
		if (suggestion.isHistory) {
			setTimeout(() => {
				this.postSearchLog(suggestion.history);
			}, 100);
			return this.onHistoryClick(suggestion.history);

		}
		const navigationURL = this._createNavigationURL(suggestion);
		setTimeout(() => {
			this.postSearchLog(suggestion.formatch);
		}, 100);
		if (suggestion.isSimilarHeading) {
			this._globalUtility.setSimilarHeadingIndex(suggestion.similarHeadingIndex);
			this._globalUtility.setIsSimilarHeadingVisible(true);
		} else {
			this._globalUtility.setSimilarHeadingIndex(undefined);
			this._globalUtility.setIsSimilarHeadingVisible(false);
		}
		if (!!this._isRead && this._router.url === `${navigationURL}?cat=read`) {
			this._globalUtility.setIsSimilarRouteForPractice(true);
		}
		else if (!!this._isRead && this._router.url === `${navigationURL}?cat=read&ph=${suggestion.paraId}`) {
			this._globalUtility.setIsSimilarRouteForPractice(true);
		}
		if (!!this._isRead && !!suggestion.isParaHeading) {
			this._router.navigate([navigationURL], { queryParams: { cat: 'read', ph: suggestion.paraId } });
		}
		else if (!!this._isRead && !suggestion.isParaHeading) {
			this._router.navigate([navigationURL], { queryParams: { cat: 'read' } });
		} else {
			this._router.navigate([navigationURL]);
		}
	}
	postSearchLog(searchText) {
		let data = {
			'componentURL': window.location.origin + '/practice/search?searchData=' + encodeURI(searchText),
			'isGlobal': true
		}
		if (!this._globalUtility.isLoggedIn()) {
			data["verifyToken"] = "NO";
		}
		this._taxPracticeSuggestionService.postSearchLogs(data, searchText).subscribe();
	}

	private _createNavigationURL(suggestion): string {
		let { category, text, url, id } = suggestion;
		let { name: categoryName } = category[0];
		categoryName = categoryName.trim().replace(/[^a-z0-9,. ]/gi, '').replaceAll(' ', '-');
		this._isRead = suggestion.isRead ? 1 : 0;
		this._isNullCategory = suggestion.isnullcategory ? 1 : 0;
		text = text.trim().replace(/[^a-z0-9,. ]/gi, '').replaceAll(' ', '-');
		url = !!url ? url.trim().replace(/[^a-z0-9,. ]/gi, '').replaceAll(' ', '-') : text || 'topic';
		if (!!this._isRead) {//&& !!!this._isNullCategory
			return `/practice/${categoryName}/${url}/read/${id}/${this._isRead}/${this._isNullCategory}`;
		} else {
			return `/practice/${categoryName}/${url}/${text}/${id}/${this._isRead}/${this._isNullCategory}`
		}
	}

	public async onSearchBoxClick() {
		this.isActive = true;
		await this._getUsersHistory();
		this.suggestionsData = [...this.userSearchHistory, ...this.suggestionsResponseData];
		this.isSuggestionBoxShow = true;
		this._isGoForSuggestions = true;
	}

	/**
	 *  @name _getUsersHistory
	 *  @description get user history from indexeddb and also filter
	 */
	private async _getUsersHistory() {
		let userHistory = await this._indexedDBService.getUserHistoryForTaxPractice('user-history', 'searchHistory');
		if (!_.isEmpty(userHistory)) {
			if (!this.queryFieldValue || this.queryFieldValue.length == 0) {
				let userSearchHistory: any[] = userHistory.slice(0, 5);
				this.userSearchHistory = [...userSearchHistory.map((history) => {
					return {
						history: history,
						showHtml: history,
						isHistory: true
					}
				})]
			} else {
				let userSearchHistory: any[] = userHistory.filter((history) => {
					return history && history.trim().toLowerCase().includes(this.queryFieldValue.toLowerCase().trim());
				})
				userSearchHistory = userSearchHistory.slice(0, 1);
				this.userSearchHistory = [...userSearchHistory.map((history) => {
					return {
						history: history,
						showHtml: history,
						isHistory: true
					}
				})]

			}
		}
	}

	/**
	 *
	 * @param event
	 */
	onClickOutside(event: Object) {
		if (event && event['value'] === true) {
			this.isActive = false;
			this.isSuggestionBoxShow = false;
			this._isGoForSuggestions = false;
			this.suggestionsData = []
		}
	}

	/**
	 * @description seachKeyWordInResearchModule
	 * @description when click in last option "Search in Taxmann"
	 */
	seachKeyWordInResearchModule(): void {
		if (this.queryFieldValue && this.queryFieldValue.trim().length) {
			const navigationURL = `/research/search?searchData=${this.queryFieldValue}`;
			window.open(navigationURL, "_blank");
		}
	}

	/**
	 *
	 * @param historyKeyWord
	 * @description on click of user's history
	 */
	onHistoryClick(historyKeyWord): void {
		this.isActive = false;
		this._insertUserSearchInHistory(historyKeyWord);
		this._goToSearchPage(historyKeyWord);
	}

	async onRemoveHistoryClick(event, historyKeyWord) {
		event.stopPropagation();
		await this._removeHistoryFromIndexDB(historyKeyWord);
		await this._getUsersHistory();
		this.suggestionsData = [...this.userSearchHistory, ...this.suggestionsResponseData];
		// const paramObj = {
		// 	deletingHistoryKeyWord: historyKeyWord
		// }
		// this._removeFromHistorySubscription$ = this._taxPracticeSuggestionService.deleteFromUserSearchHistory(paramObj)
		// 	.subscribe();
	}

	private async _removeHistoryFromIndexDB(historyKeyWord) {
		let userHistory = await this._indexedDBService.getUserHistoryForTaxPractice('user-history', 'searchHistory');
		_.remove(userHistory, (searchHistory) => searchHistory === historyKeyWord);
		await this._indexedDBService.addUserHistory('user-history', 'searchHistory', _.uniq(userHistory));
		let userNewHistory = await this._indexedDBService.getUserHistoryForTaxPractice('inserting-user-new-history', 'searchHistory');
		_.remove(userNewHistory, (searchHistory) => searchHistory === historyKeyWord);
		await this._indexedDBService.addUserHistory('inserting-user-new-history', 'searchHistory', _.uniq(userNewHistory));
	}

	focusToSearch() {
		document.getElementById('tax-practice-search').focus();
	}

	ngOnDestroy() {
		this._suggestionsSubscription$ && this._suggestionsSubscription$.unsubscribe();
		this._queryFieldSubscription$ && this._queryFieldSubscription$.unsubscribe();
		this._userHistorySubscription$ && this._userHistorySubscription$.unsubscribe();
		this._routeEventsSubscription$ && this._routeEventsSubscription$.unsubscribe();
		this._removeFromHistorySubscription$ && this._removeFromHistorySubscription$.unsubscribe();
	}

	cancelSearch() {
		if (window.location.pathname.indexOf('practice/search') !== -1) {
			this._router.navigate(['practice']);
		} else {
			this.queryField.setValue('');
		}
	}

	focusedOut() {
		console.log("input is focused out");
		this.showCrossIcon = false;
		this.cancelSearch();
	}

	isMobileView() {
		if (this._globalUtility.isMobileView()) {
			this.mobileView = true;
		}
	}
}
