export enum AuthMode {
	LOGIN = 1,
	REGISTER,
}

export enum ActsDataType {
	SECTION = 1,
	CHAPTER,
}

export enum ActsLevels {
	LAWS = 1,
	ACTS,
	SECTION,
}

export enum ActsAppliedFilterType {
	YEAR = 1,
	CHAPTER_WISE,
}

export enum ResearchListingEvents {
	CLICK = 1,
}

export enum DocumentPreviewWindowType {
	SELF = 1,
	NEW,
}

export enum ResearchShimmerType {
	FILTER_AREA = 1,
	RESULT_LIST,
	PREVIEW_DOCUMENT,
}

export enum RulesDataType {
	RULE = 1,
	SUBJECT,
	FORM_WISE,
}

export enum RulesLevel {
	LAWS = 1,
	RULE_COMBO,
	RULE,
}

export enum FormsLevel {
	LAWS = 1,
	FORM_COMBO,
	FORM,
}

export enum ResearchListSortOrder {
	ASC = 0,
	DESC,
}

export enum PrintType {
	SINGLE_PAGE = 1,
	ALL,
	RANGE,
	LIST_DATA,
	CONTENT_LIST,
}

export enum PrintCommand {
	SAVE_HTML = 0,
	PRINT,
	SAVE_RTF,
	SAVE_DOC,
	SAVE_XLS,
	SAVE_PDF,
}

export enum DtlAllListType {
	SECTION_LIST = 1,
	MAIN_LIST,
}

export enum FinanceActView {
	YEAR_WISE = 1,
	IT_ACT = 2,
}

export enum FinanceActsDataType {
	SECTION = 1,
	CHAPTER,
	MAIN_LIST,
}

export enum ResearchViewCrudOperation {
	ADD = 1,
	UPDATE,
	RESULT_ADDED,
	RESULT_UPDATED,
}

export enum ResearchViewSource {
	VIEWS = 1,
	REDIS,
	FRESH,
}

export enum ResearchBoxFlowType {
	DOCUMENT = 1,
	VIEWS,
}

export enum LoginType {
	EMAIL = 1,
	SOCIAL,
}

export enum TaxmannProducts {
	BOOKSTORE = 1,
	RESEARCH,
	VIRTUAL_BOOK,
	COMPLIANCE,
	TAXPRACTICE,
}

export enum KeyCodes {
	UP_ARROW = 38,
	DOWN_ARROW = 40,
	ENTER = 13,
	ESCAPE = 27,
	TAB = 9,
}

export enum AddressType {
	BILLING_ADRESS = 1,
	SHIPPING_ADDRESS,
}

export enum AddType {
	HOME = 1,
	WORK,
}

export enum CrudType {
	ADD = 1,
	UPDATE,
}

export enum BookstoreProduct {
	BOOK = 1,
	DVD,
	JOURNAL,
	E_B0OK,
	WEBSITE,
	ONLINE_COURSE,
	E_JOURNAL,
	E_SERVICES,
	GSP_SERVICE,
	EXAMS,
	VIRTUAL_BOOK,
	VIRTUAL_JOURNAL,
}

export enum UserAuthStatus {
	LOGGED_OUT = 1,
	NOT_SUBSCRIBED,
	INACTIVE_SESSION,
	OK,
	IP_NOT_REGISTERED,
	EXPIRED,

}

export enum CheckoutJournalDeliveryOption {
	BUNDLE_SAME_DAY = 1,
	PAID_GUARANTED_DELIVERY,
	NO_GUARANTED_DELIVERY,
}

export enum PaymentModes {
	RAZORPAY = 1,
	CHEQUE_DD,
	NEFT,
	COD,
	WALLET_NO_PAY,
	SHOPSE,
	RAZORPAY_RECURRING,
}

export enum FileOpenSource {
	FILE_OPEN = 0,
	TOP_STORY,
	BUDGET_STORY,
	FINANCE_SPEECH,
	FINANCE_BILL,
	QUICK_LINK,
	TAXPRACTICE_STORY,
	TAXPRACTICE_READ,
}

export enum HeightAdjustElementType {
	HTML_ELEMENT = 1,
	HTML_ID,
	HTML_CLASS,
}

export enum AdvertisementsLayoutType {
	BOOKSTORE_SLIDER = 1,
	THIRD_PANEL,
	RESEARCH_HOME_UNSIGNED,
	THIRD_PANEL_UP,
	THIRD_PANEL_DOWN,
}

export enum FileModuleType {
	SectionWiseAmendment = "samd",
	IndustryWiseImpact = "iimpact",
	FinanceBill = "fb",
	FinanceSpeech = "fs",
	AtoZDictionary = "a",
	LiveFeed = "l",
	KeyAmendment = "k",
	FirmNewsLetter = "fn",
	TopStory = "t",
	QuickLink = "ql",
}

export enum BudgetQuickLinks {
	financebill = "Finance Bill",
	budgethighlight = "Budget at a Glance",
	memorandum = "Memorandum",
	budgetspeech = "Budget Speech",
}

export enum BudgetQuickLinksUrl {
	financebill = "finance-bill",
	budgethighlight = "budget-at-glance",
	memorandum = "memorandum-to-finance-bill",
	budgetspeech = "budget-speech",
}

export enum ProductType {
	BOOK = 1,
	COMPLIANCE = 2,
	RESEARCH_OFFLINE = 3,
	JOURNAL = 6,
	VIRTUAL_BOOK = 11,
	VIRTUAL_JOURNAL = 12,
	RESEARCH = 20,
	PRACTICE,
}


export enum ReactCartProductType {
	 BOOKSTORE="BOOKSTORE",
	RESEARCH="RESEARCH",
	PRACTICE="PRACTICE",
}
export enum DealerType {
	Dealer = "1",
	Representative = "2",
}

export enum DealerTypeName {
	Dealer = "Dealer",
	Representative = "Representative",
}
