import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { GlobalUtility } from 'src/app/utility/global-utility';

@Component({
	selector: 'app-custom-tab-layout',
	templateUrl: './custom-tab-layout.component.html',
	styleUrls: ['./custom-tab-layout.component.scss']
})
export class CustomTabLayoutComponent implements OnInit {

	isMobile: boolean;
	toggleTabsFlag;

	_tabList: any;
	_selectedTab: any;
	@Input() set tabList(val: any) {
		if (val && val.length) {
			this._tabList = val;
		} else {
			this._tabList = [];
		}
	};
	@Input() set selectedTab(val: any) {
		this._selectedTab = val;
	}
	@Output() tabSelected: EventEmitter<any> = new EventEmitter<any>();
	constructor(private globalUtility: GlobalUtility) { }

	ngOnInit() {
		this.isMobile = this.globalUtility.isMobileView();
		if (this.isMobile) {
			this.onResizeTab(this._selectedTab);
		}
	}


	onSelectTab(tabId: string) {
		if (this.isMobile) {
			this.toggleTabsFlag = false;
			let index = this._tabList.findIndex(obj => obj.id == tabId);
			if (index > 0 && (!this._selectedTab || this._selectedTab != tabId)) {
				const data = JSON.parse(JSON.stringify(this._tabList[index]));
				this._tabList.splice(index, 1);
				this._tabList.unshift(data);
				this.tabSelected.emit(tabId);
			}
		} else {
			if ((!this._selectedTab || this._selectedTab != tabId)) {
				this.tabSelected.next(tabId);
			}

		}

	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.isMobile = this.globalUtility.isMobileView();
		//  this.onResizeTab(this._selectedTab);
	}

	onResizeTab(tabId: string) {
		if (this.isMobile) {
			this.toggleTabsFlag = false;
			let index = this._tabList.findIndex(obj => obj.id == tabId);
			if (index > 0) {
				const data = JSON.parse(JSON.stringify(this._tabList[index]));
				this._tabList.splice(index, 1);
				this._tabList.unshift(data);
				this.tabSelected.emit(tabId);
			}
		} else {
			this._tabList.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
			this.tabSelected.next(tabId);
		}
	}
}
