import { Component, OnInit } from '@angular/core';
import { GlobalUtility } from '../utility/global-utility';

@Component({
  selector: 'app-taxmann-footer',
  templateUrl: './taxmann-footer.component.html',
  styleUrls: ['./taxmann-footer.component.scss']
})
export class TaxmannFooterComponent implements OnInit {
  imageUrl = this.globalUtility.makeImageUrl();

  constructor(private globalUtility: GlobalUtility) { }
  ngOnInit() {}
  scroll(){window.scroll(0,0);}
}
