import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TaxmannUrl} from '../api-url/taxmann-url.enum';
import {APIResponse} from '../models/interfaces/api-response';
import {HttpHelperService} from '../service/http-helper.service';
import {GlobalUtility} from '../utility/global-utility';

@Injectable()
export class PricingService {

	constructor(private _httpHelperService: HttpHelperService, private globalUtility: GlobalUtility) {
	}

	getPricing(): Observable<APIResponse> {
		let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.PRICING);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAditPricing(): Observable<APIResponse> {
		let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.ADIT_PRICING);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getPricingFAQ(payload: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(TaxmannUrl.PRICING_FAQ);
		return this._httpHelperService.post(url, payload, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	addProductToUserReactCart(payload: Object): Observable<APIResponse> {
		if (this.globalUtility.actionForUnAuthUser()) {
			let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.ADD_PRODUCT_TO_REACT_CART);
			return this._httpHelperService.post(url, payload, true)
				.pipe(map(this.globalUtility.filterResponse));
		}
	}
}
