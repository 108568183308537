import { Component } from "@angular/core";
import { MetaService } from "src/app/service/meta.service";
import { GlobalUtility } from 'src/app/utility/global-utility';
import { Legal_Payment_Terms } from "src/constants/metatag-constant";

@Component({
    selector: 'app-payment-terms',
    templateUrl: './payment-terms.component.html',
    styleUrls: ['./payment-terms.component.scss']
})

export class PaymentTermsComponent {
    constructor(private globalUtility: GlobalUtility, private metaService: MetaService) { }

    ngOnInit() {
        this.metaService.updateMetaInfoInCurrentPage(Legal_Payment_Terms.metaTitle,
            Legal_Payment_Terms.metaDescription,
            "",
            "",
            Legal_Payment_Terms.metaDescription,
            Legal_Payment_Terms.metaKeywords,
            Legal_Payment_Terms.metaTitle)
        if (this.globalUtility.isLoggedIn()) {
        }
    }
}
