import { Component } from "@angular/core";
import { MetaService } from "src/app/service/meta.service";
import { GlobalUtility } from 'src/app/utility/global-utility';
import { Legal_Disclaimer } from "src/constants/metatag-constant";

@Component({
    selector: 'app-disclamier',
    templateUrl: './disclamier.component.html',
    styleUrls: ['./disclamier.component.scss']

})

export class DisclamierComponent {
    constructor(private globalUtility: GlobalUtility, private metaService: MetaService) { }

    ngOnInit() {
        this.metaService.updateMetaInfoInCurrentPage(Legal_Disclaimer.metaTitle,
            Legal_Disclaimer.metaDescription,
            "",
            "",
            Legal_Disclaimer.metaDescription,
            Legal_Disclaimer.metaKeywords,
            Legal_Disclaimer.metaTitle)
        if (this.globalUtility.isLoggedIn()) {
        }
    }
}
