import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from '../../services/confirmation.service';
import { layer, scale } from '../../../../animations/common.animation';
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  animations: [layer, scale]

})
export class ConfirmComponent implements OnInit {

  constructor(public confirmationService: ConfirmationService) { }

  ngOnInit() {
    document.getElementById('rejectBtn').focus();
  }

  onYesClick(e) {
    this.confirmationService.onAccept();
  }

  onNoClick(e) {
    this.confirmationService.onReject();
  }

}
