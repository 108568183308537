import { Component, OnInit, Input } from '@angular/core';
import { CarouselConfig } from '../../constants/owl-carousel.constant';
import { INewsListingItem } from 'src/interfaces/interface';
import { CategoryNameLabel } from 'src/constants/constant';
import { SignedLandingService } from '../research/modules/signed-landing/signed-landing.service';
import { ArchiveSharedService } from '../service/archive-shared.service';
import { GlobalUtility } from '../utility/global-utility';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'

@Component({
  selector: 'app-taxmann-news-update',
  templateUrl: './taxmann-news-update.component.html',
  styleUrls: ['./taxmann-news-update.component.scss']
})
export class TaxmannNewsUpdateComponent implements OnInit {

  @Input() condensedLayout: boolean;
  _newsList: INewsListingItem[] = [];
  newsList2 = [];

  categoryNameLabel = CategoryNameLabel;

  @Input() set newsList(val: INewsListingItem[]) {
    if (val) {
      this._newsList = val;
    }
  };
  @Input() categoryId;
  @Input() currentStorySource;
  carouselConfig = CarouselConfig.newscard
  imageUrl = this.globalUtility.makeImageUrl();

  constructor(
    private signedLandingService: SignedLandingService,
    private archiveSharedService: ArchiveSharedService,
    private globalUtility: GlobalUtility) { }

  ngOnInit() {
    if (!(this._newsList && this._newsList.length)) {
      this.getNewsList();
    }
  }

  getNewsList() {
    this.signedLandingService.getNewsListing('all', 15).subscribe((res) => {
      if (res.Data) {
        this._newsList = res.Data;
        this._newsList.forEach(element => {
          let url = element['FileURL'] || element['Heading'];
          let newUrl = url.replace(/[`~!@#$%^&*()_|+=?;:'",.<>’‘”‘’“”\{\}\[\]\\\/]/gi, '');
          element['url'] = newUrl;
        });
        console.log("epfsUpdates", this._newsList)
      }
    });
  }

  @gaEventTrack('Research-Home_', '', 'Click', 0, 2,-1,-1,1,-1)
  goToStory(categoryIndex,labelIndex,postLabContent,data: INewsListingItem) {
    let link = this.categoryId || 'all';
    this.archiveSharedService.clickCurentSource(link);
    if (this.currentStorySource && this.currentStorySource === 'all') {
      this.archiveSharedService.clickCurrentStorySource('all');
    } else {
      this.archiveSharedService.clickCurrentStorySource('');
    }
    //  this.router.navigate(['/research', data.CatUrl, 'top-story', data.FileID, data.FileURL || data.Heading])
  }
  @gaEventTrack('', '', 'Click', 0, 2)
  goToStoryWithLink(categorylabel,data: INewsListingItem,labelIndex) {
    console.log(typeof(labelIndex));

    let link = this.categoryId || 'all';
    this.archiveSharedService.clickCurentSource(link);
    if (this.currentStorySource && this.currentStorySource === 'all') {
      this.archiveSharedService.clickCurrentStorySource('all');
    }
    window.open(`/research/${data.CatUrl}/top-story/${data.FileID}/${data.url}`, '_self')
  }

  @gaEventTrack('', 'News-Update_view-all', 'Click', 0, -1)
  newsViewAllEvent(categorylabel) {
    return null;
  }

  get categorylabel() {
    if (window.location.pathname == '/') { return 'Umberella-Page' } else if (window.location.pathname == '/research/home') {
      return 'Research-Home_Unsigned'
    }

  }

  get pageName(){
    let pathName = window.location.pathname;
    let pathArr = pathName.split('/');
    return pathArr[2]
  }

}
