import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { TaxmannConstants } from 'src/app/models/taxmann-constants';
import { HomeService } from 'src/app/service/bookstore-service/home.service';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'
import { GlobalUtility } from 'src/app/utility/global-utility';


@Component({
	selector: 'app-bookstore-search',
	templateUrl: './bookstore-search.component.html',
	styleUrls: ['./bookstore-search.component.scss'],
	/*  animations:[slideInOut] */
})
export class BookstoreComponent implements OnInit {

	queryField: FormControl = new FormControl('');
	filter: string = "";
	isExpanded: boolean = false;
	arrowkeyLocation: number = 0;
	suggetionCount: number = 0;
	sugestionData: any[] = [];
	lastLocation: number = 0;
	isFilterClick: boolean = true;
	alive = true;
	hideDiv: boolean = false;
	searchField: string;
	qrtext;
	qrtRegex: any = /[!@#$%^&*./''"\\;=:<>?|+-]/gi;
	showCrossIcon: boolean = false;
	mobileView: boolean = false;
	@Output() onEmptySearch = new EventEmitter();
	constructor(
		private _homeService: HomeService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private globalUtility: GlobalUtility
	) { }

	ngOnInit() {
		this.isMobileView();
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['searchtext'] == null || params['searchtext'] == undefined) this.queryField.setValue('');
			else {
				this.hideDiv = true;
				this.searchField = params['searchtext'];
				this.queryField.setValue(params['searchtext']);
				this.getAutoCompleteContent(params['searchtext'], true);
			}
		});
		this.queryField.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(searchText => {
			this.getAutoCompleteContent(searchText);
		});
	}
	onClickOutside(event: Object) {
		if (event && event['value'] === true) {
			this.isExpanded = false;
		} else {
			this.isExpanded = true;
		}
	}

	expand() {

		// let ipElem = document.getElementById('bookstore-global-search');
		// if (ipElem) {
		// 	(ipElem as HTMLInputElement).select();
		// }

		if (this.qrtext != '' && this.qrtext != null && this.qrtext != undefined) {
			this.showCrossIcon = true;
			if (this.qrtext.length == 1) {
				console.log(this.qrtext);
				this.isExpanded = false;
			}
			else if (this.qrtext[1] == " " && this.qrtext.length == 2) { this.isExpanded = false; }
			else this.isExpanded = true;
		}
	}

	getAutoCompleteContent(queryText: string, hideSuggession?: boolean) {

		if (queryText != '' && queryText != undefined && queryText != null) {
			this.qrtext = queryText.replace(this.qrtRegex, "");
			if (this.qrtext.length == 1) {
				this.isExpanded = false;
			}
			else if (this.qrtext[1] == " " && this.qrtext.length == 2) { this.isExpanded = false; }
			else {
				this._homeService.getAutoCompleteContent(this.qrtext).pipe(takeWhile(_ => this.alive)).subscribe((response) => {
					if (response.ResponseType == TaxmannConstants.SUCCESS) {
						this.suggetionCount = response.Data?.length;
						if (this.suggetionCount > 0) {
							this.sugestionData = response.Data;
							// let searchInResearch = {
							// 	Id: 1,
							// authorName: "",
							// categoryId: "",
							// categoryName: "",
							// subjectId: "1",
							// subjectName: "",
							// title: "Search in Research",
							// url: "/research"
							// }
							// this.sugestionData.push(searchInResearch);
							if (this.qrtext !== this.searchField) {
								this.hideDiv = false;
								this.searchField = '';
							}
							// if(this.hideDiv) this.isExpanded = false;
							// else
							this.isExpanded = true;
						}
						else this.sugestionData = [];
					}
					else if (response.Data == null) {
						this.sugestionData = [];
						// if(this.hideDiv) this.isExpanded = false;
						// else this.isExpanded = true;
						this.isExpanded = true;
					}
					if (hideSuggession) {
						this.isExpanded = false;
					}
				});
			}
		} else this.sugestionData = [];

	}

	searchOnSuggestion(searchUrl: string, quryFlag: boolean, external: boolean = false, originalURL: string) {
		if(originalURL?.includes('students/product')) {
			window.open(originalURL, "_blank");
			return
		}
		if (external) {
			this.router.navigate([searchUrl], { queryParams: { searchData: this.queryField.value.replace(this.qrtRegex, '') } });
		} else {
			if (quryFlag) this.router.navigate([searchUrl], { queryParams: { searchtext: this.queryField.value.replace(this.qrtRegex, '') } });
			else this.router.navigate([searchUrl]);
		}
		setTimeout(() => {
			this.isExpanded = false;
		}, 200);
	}

	keyDown(event: KeyboardEvent) {
		switch (event.keyCode) {
			case 219:
			case 222:
				event.preventDefault();
				break;
			case 221: event.preventDefault();
				break;
		}
		// if(event.keyCode === 55 && event.shiftKey){
		//   event.preventDefault();
		// }
		let filterText = this.queryField.value.replace(this.qrtRegex, '');
		this.showCrossIcon = true;
		if (filterText != "" && filterText != undefined && filterText.length > 1 && this.suggetionCount > 0) {
			this.isExpanded = true;
			switch (event.keyCode) {
				case 38:
					if (this.arrowkeyLocation > 0) {
						this.arrowkeyLocation--;
					}
					document.getElementById('list-' + this.arrowkeyLocation).scrollIntoView();
					break;
				case 40:
					if (this.suggetionCount >= this.arrowkeyLocation + 1) {
						this.arrowkeyLocation++;
					}
					document.getElementById('list-' + this.arrowkeyLocation).scrollIntoView();
					break;
				case 13:
					console.log("keydown called", event.keyCode)
					if (this.arrowkeyLocation > 0 && document.getElementsByClassName("filter-select-list active")[0] != undefined) {
						console.log("element clicked");
						var activeText13 = document.getElementsByClassName("filter-select-list active");
						(activeText13[0].children[0] as HTMLElement).click();
					} else if (this.sugestionData.length > 0) {
						console.log("suggestinn length");
						this.router.navigate(['bookstore/product/search/' + filterText + ''], { queryParams: { searchtext: filterText } });
					}
					else if (this.sugestionData.length == 0) {
						console.log("suggestinn zero");
						this.router.navigate(['bookstore/product/search/' + filterText + ""], { queryParams: { searchtext: filterText } });//,flag: 'false'
					}
					this.isExpanded = false;
					break;
				case 27:
					this.isExpanded = false;
					break;
			}
		} else if (filterText != "" && filterText != undefined && this.sugestionData.length == 0 && event.keyCode == 13) {
			console.log("else if caled")
			this.router.navigate(['bookstore/product/search/' + filterText + ""], { queryParams: { searchtext: filterText } });//,flag: 'false'
			this.isExpanded = false;
		} else {
			this.arrowkeyLocation = 0;
			this.suggetionCount = 0;
			this.isExpanded = false;
		}
	}
	ngOnDestroy() {
		this.alive = false;
	}
	productUrlCorrection(prodid: string, produrl: string) {
		var url = prodid + '-' + produrl;
		if (produrl) {
			let str = produrl.split('-')[0];
			if (prodid.toString() === str)
				url = produrl;
		}
		return url;
	}
	@gaEventTrack('Header_Bookstore', 'Search', 'Click')
	onEnterKey(event) {

		console.log("enter key pressed")
		let searchText = this.queryField.value;
		searchText = searchText.trim();
		if (!searchText) {
			this.onEmptySearch.emit(true)
		}
		event.target.blur();
	}
	@gaEventTrack('Header_Bookstore', 'Search', 'Click')
	searchOnClick() {
		console.log("search on click caleed")
		let searchText = this.queryField.value;
		searchText = searchText.trim();
		let filterText = searchText.replace(this.qrtRegex, '');
		if (!searchText) {
			this.onEmptySearch.emit(true)
		} else {
			this.showCrossIcon = true;
		}
		if (filterText != "" && filterText != undefined && filterText.length > 1 && this.suggetionCount > 0) {
			this.router.navigate(['bookstore/product/search/' + filterText + ""], { queryParams: { searchtext: filterText } });//,flag: 'false'
		}
	}

	cancelSearch() {
		this.isExpanded = false;
		if (window.location.pathname.indexOf('bookstore/product/search') !== -1) {
			this.router.navigate(['bookstore']);
		} else if (window.location.pathname.indexOf('bookstore/product/') !== -1) {
			this.router.navigate(['bookstore']);
		} else {
			this.queryField.setValue('');
		}
	}

	focusedOut() {
		console.log("input is focused out");
		this.showCrossIcon = false;
		this.cancelSearch();
	}

	isMobileView() {
		if (this.globalUtility.isMobileView()) {
			this.mobileView = true;
		}
	}

}
