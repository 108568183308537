import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CommonAlertService {

  constructor(private messageService: MessageService) {
  }

  successToast(message: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'success',
      detail: message,
    });
  }

  errorToast(message: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'error',
      detail: message,
    });
  }

  warningToast(message: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'warn',
      detail: message,
    });
  }

  infoToast(message: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'info',
      detail: message,
    });
  }

  errorToastMultipleLine(message: string, message1:string) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'success', 
      summary: message, 
      detail: message1 });
  }

}