import { Pipe, PipeTransform } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Pipe({
    name: 'titlePipe'
})
export class TitlePipe implements PipeTransform {

    constructor(private titleService: Title) { }
    transform(str: string): any {
        if (str) {
            this.titleService.setTitle(str + ' - Taxmann')
        }
        return str;
    }
}