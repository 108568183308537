import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingReviewComponent } from './rating-review.component';
import { NgxStarsModule } from 'ngx-stars';


@NgModule({
  declarations: [RatingReviewComponent],
  imports: [
    CommonModule,
    NgxStarsModule
  ],
  exports: [RatingReviewComponent]
})
export class RatingReviewModule { }
