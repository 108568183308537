import { Component, OnInit } from '@angular/core';
import { Partner } from '../../taxmann-data/data';
import { TaxmannHomeService } from '../../service/taxmann-service/taxmann-home.service';

@Component({
  selector: 'app-taxmann-organization',
  templateUrl: './taxmann-organization.component.html',
  styleUrls: ['./taxmann-organization.component.scss']
})
export class TaxmannOrganizationComponent implements OnInit {
  partnerList: Partner[] = [];
  constructor(private _taxmannHomeService: TaxmannHomeService) { }

  ngOnInit() {
    this.partnerList = this._taxmannHomeService.getPartnerList();

  }

}
