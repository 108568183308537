import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  ModalType } from 'src/constants/constant';



@Component({
  selector: 'app-research-renewal-notification-popup',
  templateUrl: './research-renewal-notification-popup.component.html',
  styleUrls: ['./research-renewal-notification-popup.component.scss']
})
export class ResearchRenewalNotificationPopupComponent implements OnInit {
  modalType = ModalType;
  @Input() showResearchRenewalNotificationPopup;
  @Input() calculatedResearchRenewalNotificationPopupData;
  @Output() hideResearchRenewalNotificationPopup: EventEmitter<number> = new EventEmitter<number>();
  noOfDaysSelected: number = 1;
  @Input()  isPlanExpired:any

  constructor() { }



  ngOnInit(): void {
  }

  closeResearchRenewalNotificationPopup() {
    this.showResearchRenewalNotificationPopup = false;
  }

  onClickOkayResearchRenewalNotificationPopup() {
    this.hideResearchRenewalNotificationPopup.emit(this.noOfDaysSelected);
  }

  

  onChangeResearchRenewalNotificationDays(days){
        this.noOfDaysSelected = days;
  }
}

