import { Component, OnInit } from '@angular/core';
import { MetaService } from 'src/app/service/meta.service';
import { Legal_Privacy_Policy } from 'src/constants/metatag-constant';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private metaService: MetaService) { }

  ngOnInit(): void {
    this.metaService.updateMetaInfoInCurrentPage(Legal_Privacy_Policy.metaTitle,
      Legal_Privacy_Policy.metaDescription,
      "",
      "",
      Legal_Privacy_Policy.metaDescription,
      Legal_Privacy_Policy.metaKeywords,
      Legal_Privacy_Policy.metaTitle)
  }

}
