import { animate, style, transition, trigger } from '@angular/animations';

export const modalAnimation =
    trigger('modalAnimation', [
        transition(':enter', [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            animate('.3s linear', style({ 'opacity': 1, transform: 'translateY(0)' }))
        ]),
        transition(':leave', [
            style({ opacity: 1, transform: 'translateY(0)' }),
            animate('.3s linear', style({ 'opacity': 0, transform: 'translateY(-100px)' }))
        ])
    ]);
