export const ENDMILE_API_EVENT_LIST = {
    PAGE_VISITED: 'Page Visited',
    PRODUCT_VIEWED : 'Product viewed',
    ADD_TO_CART : 'Product_clicked_add_to card',
    BUY_NOW : 'product_buynow_clicked',
    ADD_TO_WISHLIST : 'product_wishlist_clicked',
    PROCEED_TO_CHECKOUT : 'cart_proceedtocheckout_clicked',
    PROCEED_TO_PAYMENT : 'checkout_proceedtopay_clicked',
    PAYMENT_INITIATED : 'payment_initiated',
    PAYMENT_SUCCESSFUL : 'payment_successfull',
    PAYMENT_FAILED : 'payment_failed',
    ORDER_CONFIRMED : 'order_confirmed',
    SIGN_UP_INITIATE : 'user_signup_initiated',
    SIGN_UP_CONFIRMED : 'user_signup_confirmed',
    SIGNED_IN : 'user_signedin',
    STUDENT_PAYMENT_INITIATED : 'Student_payment_initiated',
    STUDENT_PAYMENT_SUCCESSFUL : 'Student_payment_successfull',
    STUDENT_PAYMENT_FAILED : 'Student_payment_failed',
    SUDENT_ORDER_CONFIRMED : 'Student_order_confirmed',
    STUDENT_SIGN_UP_INITIATE : 'Student_user_signup_initiated',
    STUDENT_SIGN_UP_CONFIRMED : 'Student_user_signup_confirmed',
    STUDENT_SIGNED_IN : 'Student_user_signedin',
    STUDENT_REGISTRATION:'Student_registration',
    STUDENT_PURCHASED_AUTO:'Student_purchased_auto',
    QRCODE:'QR Code',
    WEB_TRIAL:'Web Trial'
}

export const ENDMILE_API_FIELD_ID_MAPPING_LIST = {
    FIRST_NAME_LAST_NAME: '8',
    PRRODUCT_SERVICES:'25',
    ACTIVATON_DATE: '26',
    EXPIRY_DATE:'27',
    PHONE_NUMBER: '36',
    LOCATION:'53',
    CITY:'53',
    DESGNATION:'56',
    PROFESSION:'60',
    CID:'61',
    PRODUCT_SUB_CATEGORY:'70',
    PRODUCT_NAME:'73',
    AREA_OF_SPECIALIZATION:'75',
    STATE:'76',
    PAGE_URL:'171',
    PAGE_CATEGORY:'172',
    PAGE_TITLE:'173',
    PRODUCT_ID: "210",
   PRODUCT_TYPE:"175",
   CART_SIZE:'176',
   ORDER_VALUE:'177',
   PAYMENT_MODE:'178',
   PROMO_CODE:'179',
   TRANSACTION_ID:'180',
   TRANSACTION_AMOUNT:'181',
   TRANSACTION_DATE:'182',
   TRANSACTION_TIME:'183',
   PAYMENT_METHOD:'184',
   ORDER_ID:'185',
   ORDER_AMOUNT:'186',
   ORDER_DATE:'187',
   ORDER_TIME:'188',
   COURSE:'189',
   COURSE_LEVEL:'190',
   YEAR_OF_ATTEMPT:'191',
   MONTH_OF_ATTEMPT:'192',
   COURSE_SUBJECT:'193',
   PRODUCT_CATEGORY: '214'
}

export const ENDMILE_API_CONSTANT = {
    CID:'cid',
    INTERNAL:'internal',
    OID:'OID',
    TID:'TID'
}

export const FREE_TRIAL_PRODUCT_NAME = {
    RESEARCH:'Research Free Trial',
    PRACTICE:'Practice Free Trial'
}