import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asDefNumber'
})
export class AsDefNumberPipe implements PipeTransform {

    transform(value: string, liablity: boolean): any {
        let val;
        value = Number.parseFloat(value).toFixed(2);
        val = value;
        if (liablity) {
            val = '(' + Math.abs(+value) + ')'
            return val;
        }

        if(+value < 0){
            val = `(${Math.abs(+value)})`
        }
        return val;
    }
}
