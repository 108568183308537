import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { HomeService } from '../service/bookstore-service/home.service';

@Component({
	selector: 'app-live-stream',
	templateUrl: './live-stream.component.html',
	styleUrls: ['./live-stream.component.scss']
})
export class LiveStreamComponent implements OnInit {
	showVideo = false;
	liveTvStatus: number = 0;
	liveTvUrl: string = "";
	liveTvUrlSafe: SafeResourceUrl;
	liveVideoTitle: string = '';
	visibleUrlArr = [];
	routeUrl = "";

	constructor(
		private router: Router,
		private _homeService: HomeService,
		private sanitizer: DomSanitizer
	) { }

	ngOnInit(): void {
		this.routeUrl = this.router.url;
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.routeUrl = event.url;
				if (!this.visibleUrlArr?.length) {
					this.getLiveVideoStreamDetails();
				} else {
					this.matchUrl();
				}
			}
		});

	}


	getLiveVideoStreamDetails() {
		this._homeService.showBudgetMarathonTv().subscribe((res) => {
			if (res.Status === 'DATA_FOUND') {
				this.liveTvStatus = res?.Data?.status || 0;
				this.liveTvUrl = res?.Data?.streamurl || '';
				this.visibleUrlArr = res?.Data?.taxmannurllist || [];
				this.liveVideoTitle = res?.Data?.title || "";
				this.liveTvUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.liveTvUrl);
				this.matchUrl();
			}
		})
	}

	matchUrl() {
		if(this.routeUrl) {
			let matchedUrlArr = this.visibleUrlArr.filter((url: string) => {
				// let matchUrl = url;
				if(url == '/advisory' || url == '/advisory/') {
					return (this.routeUrl === "/advisory") || (this.routeUrl === "/advisory/");
				} else if(url == '/academy' || url == '/academy/') {
					return (this.routeUrl === "/academy") || (this.routeUrl === "/academy/");
				} else {
					return this.routeUrl === url;
				}
			});
			if (matchedUrlArr?.length) {
				this.showVideo = true;
			} else {
				this.showVideo = false;
			}
		}

	}

}
