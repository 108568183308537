import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { scale } from 'src/animations/common.animation';


@Component({
  selector: 'app-scroll-to-top-document',
  templateUrl: './scroll-to-top-document.component.html',
  styleUrls: ['./scroll-to-top-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [scale]
})
export class ScrollToTopDocumentComponent implements OnInit, OnDestroy {
  showFloatingBtn: boolean = true;
  scrollTop: number = 100;
	@Output() movetotop: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() showBtn;
  constructor() {
  }


  ngOnInit() {
  }

  scrollToTop() {
this.movetotop.emit(true)
  }

  ngOnDestroy() {
  }
}
