import { OnInit, Component } from '@angular/core';
import { GlobalUtility } from '../utility/global-utility';


@Component({
    selector: 'app-browser-guard',
    templateUrl: './browser-guard.component.html',
    styleUrls: ['./browser-guard.component.scss']
  })
  export class BrowserGuardComponent implements OnInit {
    imageUrl = this.globalUtility.makeImageUrl();

      constructor(private globalUtility: GlobalUtility,
        ) { }
    
      ngOnInit(){
              }
  }  