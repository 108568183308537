import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class CTCRedirectGuard implements CanActivate {
	constructor( private router: Router) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if(state && state.url && state.url.includes('/ctc') ){
			let url = state.url;
			url =  url.replace('ctc', 'CTC');
			this.router.navigate([url]);;
			return true
		}else if(state && state.url && state.url.includes('/api-preview-document') ){
			let url = window.location.origin + state.url;
			url =  url.replace('api-preview-document', 'research/api-preview-document');
			window.open(url, '_self');;
			return true
		}else if(state && state.url && state.url.includes('/dropbox-signin-success') ){
			let url = window.location.origin + state.url;
			url =  url.replace('dropbox-signin-success', 'research/dropbox-signin-success');
			window.open(url, '_self');;
			return true
		}else if(state && state.url && state.url.includes('/preview-document') ){
			let url = window.location.origin + state.url;
			url =  url.replace('preview-document', 'research/preview-document');
			window.open(url, '_self');;
			return true
		}else{
		return true;
	}
	
}
}
