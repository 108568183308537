import { Component } from "@angular/core";
import { MetaService } from "src/app/service/meta.service";
import { GlobalUtility } from 'src/app/utility/global-utility';
import { Legal_Return_Policy } from "src/constants/metatag-constant";

@Component({
    selector: 'app-return-policy',
    templateUrl: './return-policy.component.html',
    styleUrls: ['./return-policy.component.scss']
})


export class ReturnPolicyComponent {
    constructor(private globalUtility: GlobalUtility, private metaService: MetaService) { }

    ngOnInit() {
        this.metaService.updateMetaInfoInCurrentPage(Legal_Return_Policy.metaTitle,
            Legal_Return_Policy.metaDescription,
            "",
            "",
            Legal_Return_Policy.metaDescription,
            Legal_Return_Policy.metaKeywords,
            Legal_Return_Policy.metaTitle)
        if (this.globalUtility.isLoggedIn()) {
        }
    }
}
