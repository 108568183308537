import { Component, OnInit } from '@angular/core';
import { Taxation } from '../taxmann-data/data';
import { TaxmannHomeService } from '../service/taxmann-service/taxmann-home.service';

@Component({
  selector: 'app-taxmann-home',
  templateUrl: './taxmann-home.component.html',
  styleUrls: ['./taxmann-home.component.scss']
})
export class TaxmannHomeComponent implements OnInit {

  bannerHeader = `India's #1 source of everything on Income Tax, GST, International & Corporate Laws, and more.`;
  bannerContent = `Start your 7-day free trial of full access of the biggest authentic database of Books, Journals, Case Laws and a lot more.`;
  taxationList:Taxation[]=[];

  constructor(private _taxmannHomeService:TaxmannHomeService) { }

  ngOnInit() {
    this.getHomepageData();
  }

  getHomepageData(){
    this.taxationList=this._taxmannHomeService.getTaxationList();
  }

  trackById(index,taxation) {
    return taxation.id;
  }


}