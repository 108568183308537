import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customFilterDate'
})
export class CustomFilterDatePipe implements PipeTransform {

    transform(value: string): any {
        if (value && value.length == 8) {
            const year = value.substr(0, 4);
            const month = value.substr(4, 2);
            const day = value.substr(6, 2)
            value = `${day}-${month}-${year}`;
        } else {
            value = '-'
        }
        return value;
    }
}
