import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaxmannUrl } from 'src/app/api-url/taxmann-url.enum';
import { GlobalUtility } from 'src/app/utility/global-utility';

import { APIResponse } from '../../models/interfaces/api-response';
import {
  CustomerStory,
  customerStoryData,
  Partner,
  partnerData,
  Taxation,
  taxationData,
} from '../../taxmann-data/data';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class TaxmannHomeService {

  constructor(private _httpHelperService:HttpHelperService,private globalUtility :GlobalUtility) { }

  getCustomerStoriesList():Array<CustomerStory>{
    return customerStoryData;
  }

  getTaxationList():Array<Taxation>{
    return taxationData;
  }

  getPartnerList():Array<Partner>{
    return partnerData;
  }

  forgotPassword(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.FORGOT_PASSWORD);
    return this._httpHelperService.post(url, data, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

  resetPassword(data:any):Observable<APIResponse>{
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.RESET_PASSWORD);
    return this._httpHelperService.post(url, data, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

}