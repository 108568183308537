import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ControlMessages } from './control-message.component';


@NgModule({
  declarations: [
    ControlMessages
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ControlMessages
  ]
})
export class ControlMessageModule { }
