import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'textCutter'
})
export class TextCutter implements PipeTransform {

	public transform(value: string, cutLenght: number, isDotNeeded: boolean = true): string {
		if (typeof value === 'string' && value && value.trim().length) {
			if (value.length > cutLenght) {
				let iIndex = value.substring(0, cutLenght).lastIndexOf(" ");
				if (iIndex > 0) {
					value = value.substring(0, iIndex);
				}
				if (isDotNeeded) {
					return `${value.trim()}...`
				} else {
					return value;
				}
			} else {
				return value;
			}
		}
	}
}