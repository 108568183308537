import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveStreamComponent } from './live-stream.component';

@NgModule({
  declarations: [LiveStreamComponent],
  imports: [
    CommonModule
  ],
  exports: [LiveStreamComponent]
})
export class LiveStreamModule { }
