import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxmannCarouselComponent } from './taxmann-carousel.component';

@NgModule({
  declarations: [TaxmannCarouselComponent],
  imports: [
    CommonModule,
  ],
  exports: [TaxmannCarouselComponent]
})
export class TaxmannCarouselModule { }
