export const NETCORE_EVENT_LIST = {
    PRODUCT_VIEWED : 'product_viewed',
    ADD_TO_CART : 'product_addtocart_clicked',
    BUY_NOW : 'product_buynow_clicked',
    ADD_TO_WISHLIST : 'product_wishlisticon_clicked',
    PROCEED_TO_CHECKOUT : 'cart_proceedtocheckout_clicked',
    PROCEED_TO_PAYMENT : 'checkout_proceedtopay_clicked',
    PAYMENT_INITIATED : 'payment_initiated',
    PAYMENT_SUCCESSFUL : 'payment_successful',
    PAYMENT_FAILED : 'payment_failed',
    ORDER_CONFIRMED : 'order_confirmed',
    PAGE_BROWSE : 'page_visited',
    SIGN_UP_INITIATE : 'user_signup_initiated',
    SIGN_UP_CONFIRMED : 'user_signup_confirmed',
    SIGNED_IN : 'user_signedin',
    DOCUMNET_RESEARCH_FREE_TRIAL_CLICKED:'document_get_research_freetrial_clicked',
    PRICING_RESEARCH_FREE_TRIAL_CLICKED :'pricing_get_research_freetrial_clicked',
    HOME_RESEARCH_FREE_TRIAL_CLICKED: 'home_get_research_freetrial_clicked',
    HOME_RESEARCH_BLOCK_FREE_TRIAL_CLICKED: 'home_research_block_get_research_freetrial_clicked',
    RESEARCH_FREE_TRIAL_VERIFED: 'research_freetrial_email_verified',
    RESEARCH_FREE_TRIAL_ACTIVATED: 'research_freetrial_activated',
    DOCUMNET_PRACTICE_FREE_TRIAL_CLICKED: 'document_get_practice_freetrial_clicked',
    PRICING_PRACTICE_FREE_TRIAL_CLICKED: 'pricing_get_practice_freetrial_clicked',
    HOME_BLOCK_PRACTICE_FREE_TRIAL_CLICKED: 'home_practice_block_get_practice_freetrial_clicked',
    PRACTICE_FREE_TRIAL_VERIFIED: 'practice_freetrial_email_verified',
    PRACTICE_FREE_TRIAL_ACTIVATED: 'practice_freetrial_activated'
}

export const NETCORE_API_FIELD_ID_MAPPING_LIST = {
    FIRST_NAME:'FIRST_NAME',
    LAST_NAME:'LAST_NAME',
    PROFESSION:'PROFESSION',
    DESIGNATION:'DESIGNATION',
    MOBILE_NO:'MOBILE_NO',
    AREA_OF_SPECIALISATION:'AREA_OF_SPECIALISATION',
    CITY:'CITY',
    STATE:'STATE',
    COUNTRY:'COUNTRY',
    PRODUCT_CATEGORY_SUBJECT:'PRODUCT_CATEGORY_SUBJECT',
    PRODUCT_TYPE:'PRODUCT_TYPE',
    COMPANY_TYPE:'COMPANY_TYPE',
    SUBSCRIPTION_TYPE:'SUBSCRIPTION_TYPE',
    EMAIL:'email',
    PAGE_URL:'pageUrl',
    PAGE_CATEGORY:'pageCategory',
    PRODUCT_ID: "productId",
    PRODUCT_NAME:'productName',
    PRODUCT_FORMAT:'productFormat',
    QUANTITY:'Qty',
    PRODUCT_SUBJECT:'productSubject',
    CART_SIZE:'cartSize',
    TRANSACTION_ID:'transactionID',
    TRANSACTION_AMOUNT:'transactionAmount',
    TRANSACTION_DATE:'transactionDate',
    TRANSACTION_TIME:'transactionTime',
    PAYMENT_MODE:'paymentMode',
    ORDER_ID:'orderID',
    ORDER_AMOUNT:'orderAmount',
    ORDER_DATE:'orderDate',
    ORDER_TIME:'orderTime',
    ORDER_VALUE:'orderValue',
    PROMO_CODE:'promoCode',
    COURSE:'course',
    COURSE_LEVEL:'courseLevel',
    YEAR_OF_ATTEMPT:'yearOfAttempt',
    MONTH_OF_ATTEMPT:'monthOfAttempt',
    COURSE_SUBJECT:'courseSubject',
    PRODUCT_COURSE:'productCourse',
    PRODUCT_COURSE_level:'productCourseLevel',  
    UTM_SOURCE:'utm_source',
    UTM_MEDIUM:'utm_medium',
    UTM_CAMPAIGN:'utm_campaign',
    PRRODUCT_SERVICES:'productServices',
    ACTIVATON_DATE: 'activationDate',
    EXPIRY_DATE:'expiryDate',
    PHONE_NUMBER: 'phoneNumber',
    PRODUCT_SUB_CATEGORY:'productSubCategory',
    AREA_OF_SPECIALIZATION:'areaOfSpecialization',
    PAGE_TITLE:'pageTitle',
    PAYMENT_METHOD:'paymentMethod',
    PRODUCT_CATEGORY:'productCategory',
    DVD_LICENSE_TYPE:'DVDLicenseType'
}