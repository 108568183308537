import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TaxPracticeAPiUrl } from "src/app/api-url/tax-practice-url.enum";
import { APIResponse } from "src/app/models/interfaces/api-response";
import { HttpHelperService } from "src/app/service/http-helper.service";
import { GlobalUtility } from "src/app/utility/global-utility";

@Injectable()
export class PracticeBotService {
	constructor(
		private _httpHelperService: HttpHelperService,
		private _globalUtility: GlobalUtility
	) { }

	private getHeaders(): any {
		const taxmannauthorization = localStorage.getItem('TaxmannAuthorization');
		const chatauthtoken = localStorage.getItem('chatAuthToken');

		return {
			'taxmannauthorization': taxmannauthorization || '',
			'chatauthtoken': chatauthtoken || ''
		};
	}

	public createChat(requestObj): Observable<APIResponse> {
		const createChatUrl: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.CREATE_CHAT);
		const headers = this.getHeaders();
		return this._httpHelperService.post(createChatUrl, requestObj, false, false, headers)
	}
	public getChatsData(requestObj): Observable<APIResponse> {
		const createChatUrl: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.GET_CHAT);
		const headers = this.getHeaders();
		return this._httpHelperService.post(createChatUrl, requestObj, false, false, headers)
	}
	public removeChat(requestObj): Observable<APIResponse> {
		const createChatUrl: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.REMOVE_CHAT);
		const headers = this.getHeaders();
		return this._httpHelperService.post(createChatUrl, requestObj, false, false, headers)
	}
	public renameChat(requestObj): Observable<APIResponse> {
		const createChatUrl: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.RENAME_CHAT);
		const headers = this.getHeaders();
		return this._httpHelperService.post(createChatUrl, requestObj, false, false, headers)
	}
	public pinChat(requestObj): Observable<APIResponse> {
		const createChatUrl: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.PIN_CHAT);
		const headers = this.getHeaders();
		return this._httpHelperService.post(createChatUrl, requestObj, false, false, headers)
	}
	public checkLimit(): Observable<APIResponse> {
		const createChatUrl: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.CHECK_LIMIT);
		const headers = this.getHeaders();
		return this._httpHelperService.get(createChatUrl, {}, false, headers)
	}

	public getChatListData(): Observable<APIResponse> {
		let chat_type_id = 3;
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(`${TaxPracticeAPiUrl.CHAT_LIST}?chat_type_id=${chat_type_id}`);
		const headers = this.getHeaders();
		return this._httpHelperService.get(customizeURL, {}, false, headers)
	}
	public getQuerySuggestion(requestObj): Observable<APIResponse> {
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(`${TaxPracticeAPiUrl.QUERY_SUGGESTION}?chat_id=${requestObj?.chat_id}&thread_id=${requestObj?.thread_id}`);
		const headers = this.getHeaders();
		return this._httpHelperService.get(customizeURL, {}, false, headers)
	}
	public getMoreChatRequest(): Observable<APIResponse> {
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(`${TaxPracticeAPiUrl.LIMIT_REQUEST}`);
		const headers = this.getHeaders();
		return this._httpHelperService.get(customizeURL, {}, false, headers)
	}
	public getPromptQueryData(requestObj): Observable<APIResponse> {
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.QUERY);
		const headers = this.getHeaders();
		return this._httpHelperService.post(customizeURL, requestObj, false, false, headers)
	}
	public sendFeedBack(requestObj): Observable<APIResponse> {
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.FEEDBACK);
		const headers = this.getHeaders();
		return this._httpHelperService.post(customizeURL, requestObj, false, false, headers)
	}

	public checkAuthForPracticeBot(requestObj): Observable<APIResponse> {
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.CHECK_AUTH);
		return this._httpHelperService.postMethodWithoutErrorHandling(customizeURL, requestObj, false)
	}
	public practiceBotUserEvent(requestObj): Observable<APIResponse> {
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.USER_EVENT);
		const headers = this.getHeaders();
		return this._httpHelperService.post(customizeURL, requestObj, false, false, headers)
	}
	public sendFeedbackEmail(requestObj): Observable<APIResponse> {
		const customizeURL: string = this._globalUtility.makeAIAdminUrl(TaxPracticeAPiUrl.SEND_FEEDBACK_EMAIL);
		const headers = this.getHeaders();
		return this._httpHelperService.post(customizeURL, requestObj, false, false, headers)
	}
}