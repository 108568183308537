import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TaxmannHelperService } from "src/app/service/taxmann-service/taxmann-helper.service";
import { GlobalUtility } from "src/app/utility/global-utility";

import { UserDeviceTrackingService } from "src/app/service/taxmann-service/userDeviceTrack.service";
import {ApiStatus, ModalType, RegisterPlatformType} from "src/constants/constant";
import { PricingService } from "src/app/pricing/pricing.service";
import { EServicesDetails } from "../pricing.constants";
import { LocalStorageDataModel } from "src/constants/local-storage-data-model";
import { CommonAlertService } from "src/app/service/common-alert.service";
import {CheckoutInformation} from "../../../../interfaces/interface";
import {APIResponse} from "../../../models/interfaces/api-response";
import {ReactCartProductType} from "../../../../enums/enum";

@Component({
	selector: "app-general-pricing",
	templateUrl: "./general-pricing.component.html",
	styleUrls: ["./general-pricing.component.scss"],
})
export class GeneralPricingComponent implements OnInit {
	productList: any;
	tabList: any[] = [];
	comboData: any[] = [];
	selectedTab;
	filteredTabData: any[] = [];
	selectionList: any[] = [];
	totalAmount: number = 0;
	isMobile: boolean = false;
	sectionHeading: string =
		"Your space for growth, update and building up knowledge";
	sectionSubHeading: string = "Offering plans as per your needs";
	loggedIn;
	toggleTabsFlag;
	comboIndex = 0;
	showData: boolean;
	showIndividualDetails: boolean = false;
	modalType = ModalType;
	showEServicesDetails: boolean = false;
	IndividualProductText: string = "";
	IndividualProductDescription: string = "";
	eServicesDetails = EServicesDetails;
	eServicesData;
	imageUrl = this.globalUtility.makeImageUrl();
	registerPlatformType = RegisterPlatformType;
	localStorageDataModel = LocalStorageDataModel;
	constructor(
		protected pricingService: PricingService,
		protected _taxmannHelperService: TaxmannHelperService,
		protected globalUtility: GlobalUtility,
		protected router: Router,
		protected userDeviceTrackingService: UserDeviceTrackingService,
		protected commonAlertService: CommonAlertService
	) {}

	ngOnInit() {
		this.loggedIn = this.globalUtility.isLoggedIn();
		if (this.loggedIn) {
		}
		this.isMobile = this.globalUtility.isMobileView();
		this.getPricing();
		console.log("general pricing");
	}

	getPricing() {
		this.pricingService.getPricing().subscribe((response: any) => {
			let pricingData = response.data;
			this.tabList = pricingData.TabList;
			this.productList = pricingData.ProductList;
			this.comboData = pricingData.PricingData;
			this.tabSelectEvent(this.tabList[0].id);
			this.showData = true;
		});
	}

	// onSelectTab(tabId: string) {
	//   this.totalAmount = 0;
	//   this.comboIndex = undefined;
	//   if (this.isMobile) {
	//     this.toggleTabsFlag = false;
	//     let index = this.tabList.findIndex(obj => obj.id == tabId);
	//     if (index > 0 || !this.selectedTab) {
	//       const data = JSON.parse(JSON.stringify(this.tabList[index]));
	//       this.tabList.splice(index, 1);
	//       this.tabList.unshift(data);
	//       this.helperCall(tabId);
	//     }
	//   } else {
	//     this.helperCall(tabId);
	//   }

	// }

	tabSelectEvent(tabId) {
		this.totalAmount = 0;
		this.comboIndex = undefined;
		this.helperCall(tabId);
	}

	helperCall(tabId: string) {
		this.selectedTab = tabId;
		this.filteredTabData = [];
		this.selectionList = [];
		this.totalAmount = 0;
		let filteredTab = this.comboData.find(
			(obj) => obj.TabId == tabId.toString()
		);
		this.filteredTabData = filteredTab.TabData;
		if (!this.isMobile) {
			this.adjustHeight();
		}
	}

	onSelection(id: number, name: string, amount: any) {
		let price = Number(amount);
		if (!this.selectionList) {
			this.selectionList.push({ id: id, name: name, price: price });
			this.totalAmount = this.totalAmount + price;
		} else {
			if (this.selectionList.findIndex((obj) => obj.name == name) != -1) {
				let obj = this.selectionList.findIndex(
					(obj) => obj.name == name
				);
				this.totalAmount =
					this.totalAmount - this.selectionList[obj].price;
				this.selectionList.splice(obj, 1);
			} else {
				this.selectionList.push({ id: id, name: name, price: price });
				this.totalAmount = this.totalAmount + price;
			}
		}
	}

	buyNowADITPlan(planDetails: any) {
		if(this.globalUtility.isLoggedIn()){
		let utmObj: any = {};
		let utmCampaign = this.globalUtility.getDataFromLocalStorage('activeCampaign') || '';
		if (utmCampaign) {
			utmObj = JSON.parse(this.globalUtility.getDataFromLocalStorage(utmCampaign));
		}
		const payload = {
			productEditionId: [planDetails?.ComboID],
			cartSource: "B",
			quantity: 1,
			type: ReactCartProductType.BOOKSTORE,
			utmsource: utmObj?.utm_source || 'Direct',
			utmMedium: utmObj?.utm_medium || 'Bookstore_page',
			utmCampaign: utmObj?.utm_campaign || ''
		}


		this.pricingService.addProductToUserReactCart(payload).subscribe({
			next: (response: APIResponse) => {
				if (response?.ResponseType === ApiStatus.SUCCESS) {

					if (!this.globalUtility.getDataFromLocalStorage('checkoutData')) {
						this.globalUtility.initializeCheckoutData();
					}
					const checkoutData: CheckoutInformation = JSON.parse(this.globalUtility.getDataFromLocalStorage('checkoutData'));
					checkoutData.checkoutCart = 'GENERAL_PRODUCT'
					checkoutData.cartSource = 'B'
					checkoutData.type = 'BOOKSTORE'
					this.globalUtility.setDataOnLocalStorage('checkoutData', JSON.stringify(checkoutData));
					this.globalUtility.redirectToReactCart()
				}
			}
		})
	}else{
		this.globalUtility.redirectToReactLogin();
	}
	}

	buyNow(productId?: number, type?: string, i?: number) {

		this.globalUtility.removeFromLocalStorage(this.localStorageDataModel.PRACTICE_MONTHLY);
		// if (this.comboIndex != i) {
		// 	this.commonAlertService.errorToast(
		// 		`Please select ${this.filteredTabData[i]["ComboName"]} first.`
		// 	);
		// 	return;
		// }
		if (type == "single") {
			this._taxmannHelperService.buyNow(productId);
		} else if (type == "combo") {
			let ProductList = [];
			ProductList.push(Number(productId));
			for (let product of this.selectionList) {
				if (product.comboid == productId)
					ProductList.push(Number(product.productid));
			}
			this._taxmannHelperService.buyNowMultiple(
				ProductList,
				RegisterPlatformType.RESEARCH
			);
		} else {
			let ProductList = [];
			for (let product of this.selectionList) {
				ProductList.push(Number(product.id));
			}
			this._taxmannHelperService.buyNowMultiple(
				ProductList,
				RegisterPlatformType.RESEARCH
			);
		}
	}

	adjustHeight() {
		setTimeout(() => {
			this.productList.forEach((res) => {
				const elem = document.getElementById(`prod-name${res.id}`);
				if (elem) {
					Array.from(
						document.getElementsByClassName(
							`prod-check-flag${res.id}`
						)
					).forEach((res2: HTMLElement) => {
						res2.style.height = elem.clientHeight + "px";
					});
				}
			});
		}, 100);
	}

	onSelectCombo(index: number, force?: boolean) {
		if (force) {
			if (this.comboIndex === index) {
				this.comboIndex = undefined;
			} else {
				this.comboIndex = index;
			}
		} else {
			this.comboIndex = index;
		}
	}

	closeIndividualDetails() {
		this.showIndividualDetails = false;
		this.IndividualProductText = "";
		this.IndividualProductDescription = "";
	}

	closeEServicesDetails() {
		this.showEServicesDetails = false;
		this.eServicesData = "";
	}
	showViewDetails(text, description) {
		this.showIndividualDetails = true;
		this.IndividualProductText = text;
		this.IndividualProductDescription = description;
	}

	onChangeProduct(event, comboid, productid) {
		if (event.target.checked) {
			this.selectionList.push({ comboid: comboid, productid: productid });
		} else {
			let obj = this.selectionList.findIndex(
				(obj) => obj.comboid == comboid && obj.productid == productid
			);
			this.selectionList.splice(obj, 1);
		}
	}

	ShowEServicesDetails(index) {
		this.showEServicesDetails = true;
		this.eServicesData = this.eServicesDetails[index];
	}

	setRegistrationReferrer() {
		let referrer = this.registerPlatformType.RESEARCH;
		this.globalUtility.setDataOnLocalStorage(
			LocalStorageDataModel.INTERNAL_REFRERRER_URL,
			referrer
		);

		window.open('/gp/auth/register?src=trialrequestfromsite', "_self")
	}
}
