import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TaxmannProducts } from 'src/enums/enum';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalSearchComponent implements OnInit {


  @Input() productName;
  taxmannProducts = TaxmannProducts
  constructor(private router: Router) { }



  ngOnInit() {
    if (!this.productName) {
      this.productName = TaxmannProducts.RESEARCH
    }
  }
  moveToRelativeHomePage(flag = false) {
    if (!flag) {
      return;
    } else {
      if (this.productName === this.taxmannProducts.RESEARCH) {
        this.router.navigate(['research'])
      } else if (this.productName === this.taxmannProducts.BOOKSTORE) {
        this.router.navigate(['bookstore'])
      } else if (this.productName === this.taxmannProducts.VIRTUAL_BOOK) {
        this.router.navigate(['virtualbooks'])
      } else {
        // this.router.navigate(['compliance-etds'])
      }
    }

  }

}
