import { Component } from "@angular/core";
import { MetaService } from "src/app/service/meta.service";
import { GlobalUtility } from 'src/app/utility/global-utility';
import { Legal_EULA } from "src/constants/metatag-constant";

@Component({
    selector: 'app-eula',
    templateUrl: './eula.component.html',
    styleUrls: ['./eula.component.scss']
})

export class EULAComponent {
    constructor(private globalUtility: GlobalUtility, private metaService: MetaService) { }

    ngOnInit() {
        this.metaService.updateMetaInfoInCurrentPage(Legal_EULA.metaTitle,
            Legal_EULA.metaDescription,
            "",
            "",
            Legal_EULA.metaDescription,
            Legal_EULA.metaKeywords,
            Legal_EULA.metaTitle)
        if (this.globalUtility.isLoggedIn()) {
        }
    }
}
