import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'absoluteNumber'
})
export class AbsoluteNumberPipe implements PipeTransform {

    transform(value: string): any {
        let val
        value = Number.parseFloat(value).toFixed(2);
        val = value;
        val = `${Math.abs(+value)}`
        return val;
    }
}
