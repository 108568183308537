import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeGuard implements CanActivate {

    public themeSettings = {
        default: {
            theme: '#F88822',
            theme_background: '#FFFBF5',
            theme_hover: '#e47816',
            theme_hover_background: '#FFFAF6',
            theme_filter_background: '#FEE6D6'
        },
        bookstore: {
            theme: '#F88822',
            theme_background: '#FFFBF5',
            theme_hover: '#e47816',
            theme_hover_background: '#FFFAF6',
            theme_filter_background: '#FEE6D6'
        },
        research: {
            theme: '#25C16F',
            theme_background: '#F5FFFE',
            theme_hover: '#0EA757',
            theme_hover_background: '#d8fde8',
            theme_filter_background: '#F5FFFE'
        },
        reader: {
            theme: '#805e9b',
            theme_background: '#ece5f1',
            theme_hover: '#6c458b',
            theme_hover_background: '#f4e9fb',
            theme_filter_background: '#ece5f1'
        },
        practice: {
            theme: '#118af1',
            theme_background: '#ebf6ff',
            theme_hover: '#b9dfff',
            theme_hover_background: '#ebf6ff',
            theme_filter_background: '#ebf6ff'
        },
        etds: {
            theme: '#150B82',
            theme_background: '#E2EBFF',
            theme_hover: '#150B82',
            theme_hover_background: '#E6F6FF',
            theme_filter_background: '#E2EBFF'
        }
    }
    constructor() { }

    canActivate(route: any): Observable<boolean> | Promise<boolean> | boolean {
        let data = {};
        if (this.themeSettings[route.data.theme]) {
            data = this.themeSettings[route.data.theme]
        } else {
            data = this.themeSettings.default;
        }

        let arr = Object.keys(data);

        arr.forEach((key) => {
            let property = key.split('_').join('-');
            let value = data[key];
            document.documentElement.style.setProperty(`--${property}`, value);
        });
        return true;
    }

}
