export const ACTION_PRACTICE_LAUNCH_MODULE = {
            skip: 'skip',
            finish: 'finish',
           remindMeLater: 'remindMeLater'
}

export const RIGHT_PANEL_TOPICS_TYPE = {
    TRENDING: 'TRENDING',
    NEW: 'NEW',
   INTEREST: 'INTEREST'
}

export const RIGHT_PANEL_STORIES_TYPE = {
    TRENDING: 'TRENDING',
    INTEREST: 'INTEREST'
}

export const RIGHT_PANEL_TOPICS_TITTLE = {
    TRENDING: 'Trending Topics',
    NEW: 'New Topics',
   INTEREST: 'Topics of your Interest'
}

export const RIGHT_PANEL_STORIES_TITTLE = {
    TRENDING: 'Trending Stories',
    INTEREST: 'Stories of your Interest'
}

export const ROUTE_CATEGORY_DISCOVER_TOPICS = {
    incometax: 'Incometax',
    GST: 'GST',
    FEMA: 'FEMA',
    Accounting: 'Accounting'
}


export const CATEGORY_DISCOVER_TOPICS = {
    Incometax: 'Income_Tax',
    GST: 'GST',
    FEMA: 'FEMA',
    Accounting: 'Accounting'
}

export const CATEGORY_DISCOVER_TOPICS_TITLE = {
    Incometax: 'Income Tax',
    GST: 'GST',
    FEMA: 'FEMA',
    Accounting: 'Accounting'
}

export const PRACTICE_CATEGORY_NAME = {
Incometax: 'Income-tax',
GST: 'GST',
FEMA: 'FEMA',
Accounting: 'Accounting'
}

export const PRACTICE_CATEGORY_NAME_SUBSCRIPTION = {
    Incometax: 'Income',
    GST: 'GST',
    FEMA: 'FEMA',
    Accounting: 'Accounting'
    }

    export const PRACTICE_CATEGORY_NAME_CHECK_SUBSCRIPTION = {
        Incometax: 'Income-tax',
        GST: 'gst',
        FEMA: 'FEMA',
        Accounting: 'Accounting'
        }
        