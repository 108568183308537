export enum BookstoreUrl {
    LEFT_MENU_DATA = 'bookStoreLeftMenu/allCategories',
    OFFERS    = 'bookstore/offers',
    ALL_CATEGORIES_BOOK = 'BookStoreCustomProducts/getHomePageTopLatestProducts/6',
    RECOMMENDED_BOOK = 'BookStoreCustomProducts/getHomePageRecommendedProducts/',
    PRODUCT_DETAIL = 'BookStoreProductDetails/getProductsDetails/',
    VIEW_REVIEW = 'BookStoreProductDetails/getProductsRatingDetails/',
    ADD_REVIEW = 'BookStoreProductDetails/updateReview',
    SPECIFIC_PRODUCT = 'BookStoreCustomProducts/getHomePageAllProducts/',
    CATEGORIZED_PRODUCT = 'BookStoreProductDetails/GetProductsList/',
    CHECK_DELIVERY = 'BookStoreProductDetails/getDeliveryCheck/',
    ALL_CART_ITEMS = 'BookStoreProductDetails/GetCartData',
    DELETE_CART_ITEMS = 'BookStoreProductDetails/DeleteProductFromCart/',
    ADD_UPDATE_CART_ITEMS = 'BookStoreProductDetails/AddProductToCart/',
    ADD_UPDATE_REACT_CART_ITEMS = 'cart/addProductsToUserCart',
    SHIPPING_ADDRESS = 'BookStoreCustomerData/GetShippingAddress/',
    BILLING_ADDRESS = 'BookStoreCustomerData/GetBillingAddress/',
    DELETE_ADDRESS = 'BookStoreCustomerData/DeleteUserAddress/',
    ADD_UPDATE_SHIPPING_ADDRESS = 'BookStoreCustomerData/PostShippingAddress/',
    ADD_UPDATE_BILLING_ADDRESS = 'BookStoreCustomerData/PostBillingAddress/',
    COUNTRY_STATE_LIST='BookStoreCustomerData/getCountryStates/',
    NOTIFY_ME = 'BookStoreProductDetails/notifyme',
    AUTHOR_LIST='BookStoreAuthorData/getAuthorList/',
    AUTHOR_DETAIL='BookStoreAuthorData/getAuthorDetailsByID/',
    VIEW_GSTIN_DETAIL = 'BookStoreCustomerData/getGstnDetails/',
    ADD_GSTIN_DETAIL = 'BookStoreCustomerData/updateGstnDetails',
    All_ENTITY_TYPES = 'BookStoreCustomerData/getEntityTypes/',
    REFFERED_ORDER_SOURCE = 'BookStoreCustomerData/getOrderSource/',
    DELETE_GSTIN = 'BookStoreCustomerData/deleteGstnDetails/',
    COUPON_CODE_INFO = 'BookStoreProductDetails/applyCouponCode/',
    SAVE_TEMP_ORDER = 'order/savetemporder/',
    SAVE_SHOPSE_TEMP_ORDER = 'order/savetempordershopse',
    POST_PRODUCT_ORDER = 'order/postProductOrder/',
    SAVE_DDCHECK_ORDER = 'order/saveDDCheckOrder/',
    SAVE_TEMP_ORDER_RECURRING = "order/savetemporderrecurring",
    POST_ORDER_RECURRING = "order/PostProductOrderrecurring",
    INVOICE='payment/getinvoice/',
    ADD_WISHLIST='myAccount/postWishList',
    CATEGORIZED_PRODUCT_FROM_INDEX = 'BookStoreProductDetails/GetProductsIndexList/',
    SUBJECT_INDEX_LIST = 'BookStoreProductDetails/GetSubjectIndexList/',
    SUBJECT_CATEGORY_NAME = 'BookStoreProductDetails/GetCategorySubjectName/',
    BOOKSTORE_SUGGESTION = 'BookStoreProductDetails/GetBookstoreSuggestion/',
    SHARE_PRODUCT_VIA_EMAIL = 'BookStoreProductDetails/shareProductOnEmail',
    SUBJECT_NAME_BY_ID = 'BookStoreProductDetails/GetSubjectNameById/',
    CATEGORIZED_PRODUCT_MASTERLIST_FROM_INDEX = 'BookStoreProductDetails/GetProductsMasterIndexList/',
    ADD_MULTIPLE_PRODUCTS     = 'BookStoreProductDetails/AddMultipleProductToCart',
    PRODUCT_SEARCH_FROM_INDEX = 'BookStoreProductDetails/GetProductsSearchFromIndex/',
    BOOKSTORE_TRACK_ORDER = 'myAccount/trackMyOrder/',
    TRACK_SHIPMENT = 'shiprocket/trackShipmentByCourierNo',
    CATALOGUES = 'Catalogue/getCatalogue/',
    GET_KIT_FILE_ADIT = "adit/GetFile",
    ADIT_FAQS = "BookStoreADIT/getADITFAQs/500",
    ADIT_FBOOKS = "BookStoreADIT/getADITFeaturedBooks/500",
    PINCODE = "myAccount/getStateCitybyPIN/",
    GET_DESIGNATION = 'myAccount/getDesignationList',
    GET_CART_DETAILS_NEW = 'BookStoreProductDetails/GetCartDetails',
    GET_STUDENTS_CART_DETAILS_NEW = 'cart/getUserCartDetails',
    ADD_ITEM_STUDENT_CART =  'cart/addProductsToUserCart',
    GET_SAMPLE_ISSSUE = 'BookStoreProductDetails/getSampleIssueList',
    POST_SAMPLE_ISSUE = 'BookStoreProductDetails/postSampleIssueList',
    GET_ADIT_CALLBACK = 'adit/ADITCallBack',
    ADD_GST_INFO ='myAccount/addGstinInfo',
    ADD_GST_INFO_NOT_AVAILABLE ='myAccount/updategstnflag',
    CHECK_COD_PINCODE = 'BookStoreProductDetails/checkcod/',
    LEFT_MENU_VIRTUAL_BOOK = 'VirtualBooksLeftMenu/allCategories',
    ALL_CATEGORIES_VIRTUAL_BOOK = 'VirtualBooksCustomProducts/getHomePageTopLatestProducts/6',
    RECOMMENDED_VIRTUAL_BOOK = 'recommendationForYou',
    SPECIFIC_VIRTUAL_PRODUCT = 'VirtualBooksCustomProducts/getHomePageAllProducts/',
    VIRTUAL_PRODUCT_DETAIL = 'VirtualBookProductDetails/getProductsDetails/',
    VIRTUAL_SUBJECT_INDEX_LIST = 'VirtualBooksProductDetails/GetSubjectIndexList/',
    VIRTUAL_SUBJECT_CATEGORY_NAME = 'VirtualBooksProductDetails/GetCategorySubjectName/',
    VIRTUAL_CATEGORIZED_PRODUCT_FROM_INDEX = 'VirtualBooksProductDetails/GetProductsIndexList/',
    ACTIVATE_VIRTUAL_BOOK_BY_KEY = 'ebookreader/activatekey',
    GET_READER_BOOK_LIST= 'eBookreader/geteBookreaderList',
    USER_ACCOUNT_INFO= 'myAccount/getuserInfo ',
    ACTIVATE_FREE_TRIAL= 'VirtualBookProductDetails/activatevirtualbookfreetrial/',
    ALL_CATEGORIES_VIRTUAL_JOURNAL = 'VirtualJournalsProducts/getHomePageTopLatestProducts/',
    VIRTUAL_JOURNAL_PRODUCT_DETAIL = 'VirtualJournalsProducts/getProductsDetails/',
    ACTIVATE_VIRTUAL_JOURNAL_FREE_TRIAL= 'VirtualJournalsProducts/activatevirtualjournalfreetrial/',
    GET_VIRTUAL_JOURNAL_READER_BOOK_LIST= 'VirtualJournalsProducts/getMyCollection/',
    GET_VIRTUAL_JOURNAL_ISSUES_LIST= 'VirtualJournalsProducts/getMyCollectionIssues',
    VIRTUAL_BOOK_SUGGESTION = 'VirtualBooksProductDetails/GetBookstoreSuggestion/',
    VIRTUAL_BOOK_PRODUCTS= 'VirtualBooksProductDetails/GetSubjectIndexList/',
    VIRTUAL_JOURNAL_PREVIOUS_ISSUES = 'VirtualJournalsProducts/getAllIssuesByJournal/',
    VIRTUAL_PRODUCT_SEARCH_FROM_INDEX = 'VirtualBooksProductDetails/GetProductsSearchFromIndex/',
    RECOMMENDED_BOOK_NEW = 'recommendationForYou',
    RECOMMENDED_BOOK_NEW_POST = 'recommendation',
    RAZORPAY_SECRET_KEY = 'payment/getRpAccesskey/',
    GET_PRODUCT_CATALOGUE = 'productcatalogue/getProductCatalogues',
    ADD_MULTIPLE_CART_ITEMS = 'productcatalogue/addMultipleProductToCart',
    GET_PRODUCT_OFFERS = 'icai/geticaiproductlist',
    GET_ICAI_USER_DETAILS = 'icai/geticaiuserdetails',
    POST_ICAI_MEMBER_DETAILS = 'icai/validateicaimember',
    GET_DEALER_PRODUCT_CATALOGUE = 'productcatalogue/GetDealerCatalogues',
    ENCODE_DEALER_ID = 'productcatalogue/GetIDEncoded/',
    CHECK_HYPER_DELIVERY = 'BookStoreProductDetails/getDeliveryCheckWithHyperLocal',
    WEBINAR_DETIALS= 'Webinaar/postuserdata',
    WEBINAR_DATA_AFTERWARDS= 'Webinaar/postuserdataaftersuccess',
    ACTIVATE_VIRTUAL_JORNAL_BY_KEY = 'VirtualJournalsProducts/activatekey',
    POPULAR_PRODUCTS = 'externalurl/popularproductgroupwise',
    SHOPSE_ORDER_SAVE = 'order/saveshopseresponse',
    SAVE_UTM_DETAILS ='taxmann/SaveUTMDetails',
    SAVE_BUDGET_QR_USER = 'budget/SaveBudgetQRSacnerUsers',
    ACTIVATE_30_DAYS_PRACTICE_QR = 'budget/assigntrialpracticewithbudgetqr',
    ACTIVATE_BUDGET_VIRTUAL_BOOK_BY_QR_KEY = 'ebookreader/activatekey',
    CHECK_QR_COUPON_REEDEMED = 'budget/GetBudgetQRVBStatus',
	PUBLICATION_RAZORPAY_SECRET_KEY = 'payment/getTPPLRpAccesskey/',
    GET_TOTAL_CART_ITEMS = 'cart/getTotalCartItems',
    STUDENTS_INVOICE= "userAccount/getOrderDetails",

}
