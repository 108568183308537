import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalUtility } from '../utility/global-utility';
import { LocalStorageDataModel } from '../../constants/local-storage-data-model';

@Injectable({
    providedIn: 'root'
})
export class RestrictAuthGuard implements CanActivate {

    constructor( private globalUtility: GlobalUtility) { }
// modified according to the Home page redirection need
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
            if(state.url === "/home"){
                return true;
            }else{
            window.open('/research','_self')
            return false;
            }
        }else if((this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') && this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)){
            if(state.url === "/home"){
                return true;
            }else{
                window.open('/research', '_self')
                return false;
            }
        } else {
            return true
        }
    }


}