import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'goToStory'
})
export class GoToStoryPipe implements PipeTransform {

    constructor() { }
    transform(data: any): any {
        let str = `/research/${data.CatUrl}/top-story/${data.FileID}/${data.FileURL || data.Heading}`
        return str;
    }
}