import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberLocalePipe'
})
export class NumberLocalePipe implements PipeTransform {

    constructor() { }
    transform(str: any): any {
        if (str) {
            let num = +str;
            if (!isNaN(num)) {
                str = (num).toLocaleString('en-IN')
            }
        }
        return str;
    }
}