import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupComponent } from './components/popup/popup.component';
import { KeyboardEvent } from './directives/keyboard-event.directive';
import { ConfirmComponent } from './components/confirm/confirm.component'
import { TrustHtmlPipe } from './pipes/trustResource.pipe';
import { SubstringPipe } from './pipes/substring.pipe';
import { CustomFilterDatePipe } from './pipes/customFIlterDate.pipe';
import { ClickOutside } from './directives/click-outside.directive';
import { AsDefNumberPipe } from './pipes/asDefOutputNumber.pipe';
import { AbsoluteNumberPipe } from './pipes/absoluteNum.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { TitlePipe } from './pipes/title.pipe';
import { GoToStoryPipe } from './pipes/gotoStory.pipe';
import { NumberLocalePipe } from './pipes/numberLocaleFormat.pipe';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { TextCutter } from './pipes/text-cutter.pipe';
import { AlphaNumericDirective } from './directives/block-special-char.directive';
import { SafeHtmlPipe } from './pipes/safe-html.ppe';
@NgModule({
  declarations: [
    AlphaNumericDirective,
    PopupComponent,
    KeyboardEvent,
    ConfirmComponent,
    TrustHtmlPipe,
    SubstringPipe,
    CustomFilterDatePipe,
    ClickOutside,
    AsDefNumberPipe,
    AbsoluteNumberPipe,
    OnlyNumberDirective,
    TitlePipe,
    GoToStoryPipe,
    NumberLocalePipe,
    AutofocusDirective,
    TextCutter,
    SafeHtmlPipe,

  ],
  imports: [
    CommonModule
  ],
  exports: [
    AlphaNumericDirective,
    PopupComponent,
    KeyboardEvent,
    ConfirmComponent,
    TrustHtmlPipe,
    SubstringPipe,
    CustomFilterDatePipe,
    ClickOutside,
    AsDefNumberPipe,
    AbsoluteNumberPipe,
    OnlyNumberDirective,
    TitlePipe,
    GoToStoryPipe,
    NumberLocalePipe,
    AutofocusDirective,
    TextCutter,
    SafeHtmlPipe
  ]
})
export class TaxmannCommonModule { }
