export const TaxAuditSchemaConfig = {
	url: "/bookstore/product/6724-tax-audit",
	className: "structured-data-website",
	schema: {
		"@context": "https://schema.org/",
		"@type": "Product",
		"name": "Tax Audit",
		"image": "https://ttpl.imgix.net/9789356220638L.jpg",
		"description": "This book provides a detailed commentary/clause-by-clause analysis on provisions relating to Tax Audit and clauses of Form 3CA, 3CB and 3CD, along-with Guidance Notes issued by ICAI & Tax Audit Reckoner. This book is amended by the Finance Act, 2022.",
		"brand": {
			"@type": "Brand",
			"name": "Taxmann"
		},
		"aggregateRating": {
			"@type": "AggregateRating",
			"ratingValue": "5",
			"bestRating": "5",
			"worstRating": "3.5",
			"ratingCount": "6"
		}
	}
}

export const BudgetVideoSchemaConfif = {
	url: "/budget",
	className: "structured-data-website",
	schema: {
		"@context": "https://schema.org",
		"@type": "ItemList",
		"itemListElement": [
			{
				"@type": "VideoObject",
				"position": 1,
				"name": "Union Budget 2023 Analysis | Indirect Tax Expectations and the Need for Rate Rationalization",
				"description": "In this video, Saloni Roy, Partner at @deloitte India, discusses the need for rate rationalization in the upcoming Union Budget 2023. She highlights that from an indirect tax perspective, changes are expected to be more focused on customs. Additionally, she believes that there should be an announcement to set up GST Tribunals in order to curb the increased litigations that have been seen in recent years.She also provides insights on what the industry can expect from the budget and how it will impact businesses. She also touches on the importance of rationalizing tax rates to create a more efficient and fair tax system for all. This is a must-watch video for anyone who wants to stay informed about the latest developments in the world of indirect taxes.",
				"thumbnailUrl": "https://i.ytimg.com/vi/36ras5ijFEs/maxresdefault.jpg",
				"uploadDate": "2023-01-24",
				"contentUrl": "https://www.youtube.com/watch?v=36ras5ijFEs&ab_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 2,
				"name": "Union Budget 2023 Analysis | Resolving the Double Taxation Issue on Equalization Levy",
				"description": "In this video, Bahroze Kamdin, Partner at Deloitte India, discusses the important issue of double taxation on equalization levy paid by non-residents in certain areas. The equalization levy is a tax imposed on digital services provided by non-residents to Indian customers, but some non-residents are facing double taxation on the same levy. This is a crucial issue that needs to be addressed in the upcoming Union Budget 2023. Double taxation not only creates an unjust situation for non-residents but also puts a burden on their businesses. Bahroze Kamdin discusses the reasons behind this issue and possible solutions to resolve it in the budget. This video is a must-watch for anyone who is interested in the Indian tax system and how it affects non-residents.",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/20230127125236770maxresdefault_re.jpg",
				"uploadDate": "2023-01-24",
				"contentUrl": "https://www.youtube.com/watch?v=c0CGqdFyeb8_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 3,
				"name": "Union Budget 2023 Analysis | Exploring and Unveiling the New Legal Aspects in the Budget 2023",
				"description": "In this video, Sandeep Jhunjhunwala, Partner at Nangia Andersen LLP, discusses the emerging law facets expected to be addressed in the upcoming Union Budget 2023-24. The government has been working towards creating a comprehensive framework around cryptocurrencies to balance public interest, and this is something that many in the legal industry will be watching closely. Additionally, the government is expected to revitalize the regulations surrounding Special Economic Zones (SEZs). There is a buzz around the Digital India Act as a step towards catalyzing the Digital India ambition. Another key area of focus is the revamping of data privacy norms, which is becoming increasingly important in today's digital age. With all these areas on the radar, it will be interesting to see how the government lays the foundation for the enforcement and implementation of these emerging laws. This video is a must-watch to get insights on the latest developments and what to expect in the upcoming budget.",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/20230127125108589maxresdefault_re.jpg",
				"uploadDate": "2023-01-23",
				"contentUrl": "https://www.youtube.com/watch?v=2tX8HLjaGHU_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 4,
				"name": "Union Budget 2023 Analysis | Focusing on Sustainability for a Sustainable Future",
				"description": "In this video, Neha Malhotra, Partner – Sustainability & Development Solutions, Nangia Andersen LLP discusses the importance of sustainability in the upcoming Union Budget 2023. She highlights the need to meet present needs without compromising resources for future generations and how a warming planet is a major challenge for sustainability. She also emphasizes India's transition to renewable energy sources as one of the most critical transitions for a sustainable future. The budget is expected to announce Second Sovereign Green Bond on Waste to Energy to address the challenges of climate finance and support sustainable development. This video is a must-watch for anyone interested in understanding the role of budget in driving sustainable development. ",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/20230127124610412maxresdefault_re.jpg",
				"uploadDate": "2023-01-23",
				"contentUrl": "https://www.youtube.com/watch?v=0yrqiWU4eTg_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 5,
				"name": "Union Budget 2023 Analysis | Personal and Corporate Tax Expectations for Budget 2023",
				"description": "In this video, Rajan Sachdev, Partner at Nangia Andersen LLP, discusses the expectations from the Union Budget 2023 from a personal and corporate tax perspective. As the Indian economy is recovering from the impact of the COVID-19 pandemic, the Union Budget 2023-24 is expected to be liberal towards taxpayers, focusing on maintaining the growth path while keeping fiscal deficit and inflation in check. He provides insights on what taxpayers and businesses can expect from Finance Minister Nirmala Sitharaman's budget proposal. He also analyses the budget's impact on personal and corporate tax and how it will affect individuals and businesses. This video is a must-watch for anyone interested in understanding the tax implications of the Union Budget 2023.",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/20230123103338624sddefault_re.jpg",
				"uploadDate": "2023-01-21",
				"contentUrl": "https://www.youtube.com/watch?v=sZs-Pv94o_Q_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 6,
				"name": "Union Budget 2023 Analysis | Uncovering the Grey Areas of Direct Taxation",
				"description": "In this video, Mayank Mohanka, FCA, Partner in S M Mohanka & Associates & Founder of TaxAaram India, shares his Budget 2023 Wishlist. He highlights some important gaps and grey areas in the Income Tax Act that need attention and redressal by the legislature in the upcoming Union Budget 2023. He explains the critical issues that need to be addressed and how they can be addressed in the upcoming budget. This video is a must-watch for anyone interested in understanding the critical issues facing the Income Tax Act and how they can be addressed in the upcoming budget.",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/202301231030009071920-1080 (1)_re.jpg",
				"uploadDate": "2023-01-21",
				"contentUrl": "https://www.youtube.com/watch?v=W4dIved1nkc_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 7,
				"name": "Union Budget 2023 Analysis | A Comprehensive Breakdown of What Taxpayers Can Expect",
				"description": "In this video, Aseem Chawla, the founder of ASC Legal, Solicitors & Advocates, shares his insights on what taxpayers can expect from the upcoming Union Budget for 2023-24, set to be unveiled by Finance Minister Nirmala Sitharaman on February 1st, 2023. Mr Chawla provides an in-depth analysis of the key areas that may be impacted, including tax rates, GST, and other financial policies. He also offers his predictions on how the budget may affect businesses, individuals, and the economy as a whole. This video is a must-watch for anyone looking to stay informed on the latest budget developments and how they may impact their finances.",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/202301201438413131920-1080_re.jpg",
				"uploadDate": "2023-01-20",
				"contentUrl": "https://www.youtube.com/watch?v=6Z6gbaeiQt0_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 8,
				"name": "Union Budget 2023 Analysis | Examining the Impact on Energy, Healthcare, and Technology Sectors",
				"description": "The Union Budget 2023 is expected to focus on the energy, healthcare, and technology sectors. These sectors are considered to be key drivers of economic growth and development, and the budget is likely to include measures to support their growth and expansion. This may include investments in renewable energy sources, funding for healthcare infrastructure and services, and measures to promote the development and adoption of new technologies. In this video, Poonam Kaura, Partner – Government & Public Sector Advisory, Nangia Andersen LLP shares her expectations for the Union Budget 2023. She highlights that sectors to watch out for include energy, healthcare, and technology, with a focus on new age technologies such as IoT, AI, machine learning, and other advanced computing systems. The budget should be attractive and lucrative for both foreign and domestic investors, as India is seen as an emerging market with a low likelihood of recession. With a major recession impacting manufacturing countries, the government is expected to announce measures to support the 'Make in India' initiative, especially for the MSME sector. The budget should also include easier lines of credit to support the growth of the Indian economy.",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/20230119110838339Thumbnail-(Budget) Poonam Kaura_re.jpg",
				"uploadDate": "2023-01-19",
				"contentUrl": "https://www.youtube.com/watch?v=DrGWTyPQGxw_channel=Taxmann",
			},
			{
				"@type": "VideoObject",
				"position": 9,
				"name": "#TaxmannBudget | Need for tax recovery mechanism on sale of securitised assets | Union Budget 2023",
				"description": "In this video, CA Ridhima Bhatia, Senior Manager, Research & Advisory Department | Taxmann, has outlined her suggestions for the Union Budget 2023. She describes the need for a proper amendment to guarantee that the tax liability is properly collected from the sale of securitized assets.",
				"thumbnailUrl": "https://ttplimages.imgix.net/budgetimages/20230117192336693sddefault_re.jpg",
				"uploadDate": "2023-01-12",
				"contentUrl": "https://www.youtube.com/watch?v=hZZ6489pqZk_channel=Taxmann",
			}
		]
	}
}


