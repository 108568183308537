import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxmannCustomerStoriesComponent } from './taxmann-customer-stories.component';
import { TaxmannCarouselModule } from '../taxmann-carousel/taxmann-carousel.module';

@NgModule({
  declarations: [TaxmannCustomerStoriesComponent],
  imports: [
    CommonModule,
    TaxmannCarouselModule
  ],
  exports: [TaxmannCustomerStoriesComponent]
})
export class TaxmannCustomerStoriesModule { }
