import { Component, OnInit } from '@angular/core';
import { PricingService } from '../../pricing.service';
import { TaxmannHelperService } from 'src/app/service/taxmann-service/taxmann-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { Router } from '@angular/router';
import { UserDeviceTrackingService } from 'src/app/service/taxmann-service/userDeviceTrack.service';
import { GeneralPricingComponent } from '../general-pricing/general-pricing.component';
import { CommonAlertService } from 'src/app/service/common-alert.service';
import { AuditPriceMetaData } from 'src/constants/metatag-constant';
import { MetaService } from 'src/app/service/meta.service';
@Component({
  selector: 'app-adit-pricing',
  templateUrl: './adit-pricing.component.html',
  styleUrls: ['./adit-pricing.component.scss']
})
export class AditPricingComponent extends GeneralPricingComponent implements OnInit {

  sectionHeading = 'Your Journey towards Certified Accounting Professional Starts...';
  sectionSubHeading = '';
  imageUrl = this.globalUtility.makeImageUrl();

  constructor(protected pricingService: PricingService,
    protected _taxmannHelperService: TaxmannHelperService,
    protected globalUtility: GlobalUtility,
    private metaService:MetaService,
    protected router: Router,
   protected userDeviceTrackingService: UserDeviceTrackingService,protected commonAlertService: CommonAlertService) {
    super(pricingService, _taxmannHelperService, globalUtility, router, userDeviceTrackingService,commonAlertService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.metaService.updateMetaInfoInCurrentPage(AuditPriceMetaData.metaTitle, AuditPriceMetaData.metaDescription, window.location.href, null, AuditPriceMetaData.metaDescription,AuditPriceMetaData.metaKeywords,AuditPriceMetaData.metaTitle, '', AuditPriceMetaData.metaRobots);

  }

  getPricing() {
    this.pricingService.getAditPricing().subscribe((response: any) => {
      let pricingData = response.data;

      this.tabList = pricingData.TabList;
      this.productList = pricingData.ProductList;
      this.comboData = pricingData.PricingData;
      this.tabSelectEvent(this.tabList[0].id);
      this.showData = true
    });
  }

}
