import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxmannOrganizationComponent } from './taxmann-organization.component';

@NgModule({
  declarations: [TaxmannOrganizationComponent],
  imports: [
    CommonModule
  ],
  exports: [TaxmannOrganizationComponent]
})
export class TaxmannOrganizationModule { }
