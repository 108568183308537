import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { LocalStorageDataModel } from '../../../constants/local-storage-data-model';
import { Router } from '@angular/router';
import { HomeService } from '../../service/bookstore-service/home.service';
import { CommonAlertService } from '../../service/common-alert.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { CheckoutFrom, PublishEvents, PaymentPlatformType, RegisterPlatformType } from 'src/constants/constant';
import { ProductType, ReactCartProductType } from 'src/enums/enum';
import { CheckoutInformation } from 'src/interfaces/interface';

@Injectable({
  providedIn: 'root'
})
export class TaxmannHelperService {

  searchFilterData: any = {};
  mobileFilterCloseBtn = new Subject<boolean>();
  showMobileFilterIcon = new Subject<boolean>();

  private _signInPopup = new Subject<string>();
  signInevent = this._signInPopup.asObservable();

  private _toggleSidebarDivOrAddToCart = new Subject<any>();
  toggleSidebarLayoutOrAddToCartEvent = this._toggleSidebarDivOrAddToCart.asObservable();

  private _cartCounterSubject = new Subject<any>();
  cartCounterEvent = this._cartCounterSubject.asObservable();

  private _addressPopup = new Subject<string>();
  addressEvent = this._addressPopup.asObservable();

  private _gstinPopup = new Subject<string>();
  gstinEvent = this._gstinPopup.asObservable();

  private _toggleNavSubject = new Subject<string>();
  toggleNavEvent = this._toggleNavSubject.asObservable();

  private _addressUpdated = new Subject<string>();
  addressUpdatedEvent = this._addressUpdated.asObservable();

  private _gstinUpdated = new Subject<string>();
  gstinUpdatedEvent = this._gstinUpdated.asObservable();

  private _confirmDialog = new Subject<string>();
  confirmDialogEvent = this._confirmDialog.asObservable();

  private _bookstoreSearchFilter = new BehaviorSubject(this.searchFilterData);
  bookstoreSearchFilterEvent = this._bookstoreSearchFilter.asObservable();

  mobileFilterToggle = new Subject<boolean>();

  constructor(
    private _homeService: HomeService,
    private _commonAlertService: CommonAlertService,
    private router: Router,
    private globalUtility: GlobalUtility
  ) { }

  /**
   * publish events
   */

  publishEvent(action: string, data?: any) {

    let actionArray = [
      PublishEvents.CART,
      PublishEvents.ADD_TO_CART,
      PublishEvents.UPADTE_CART,
      PublishEvents.TRUE,
      PublishEvents.FALSE,
      PublishEvents.MOBILE_TOGGLE_ON,
      PublishEvents.MOBILE_TOGGLE_OFF
    ];
    if (actionArray.includes(action)) { this._toggleSidebarDivOrAddToCart.next(action); }
    else if (action == PublishEvents.ADDRESS) {
      this._addressPopup.next(data);
    }
    else if (action == PublishEvents.ADDRESS_UPDATED) {
      this._addressUpdated.next(data);
    }
    else if (action == PublishEvents.GSTIN_UPDATED) {
      this._gstinUpdated.next(action);
    }
    else if (action == PublishEvents.GSTIN) {
      this._gstinPopup.next(data);
    }
    else if (action == PublishEvents.TOGGLE_NAV) {
      this._toggleNavSubject.next(data);
    }
    else if (action == PublishEvents.CONFIRM_DIALOG) {
      this._confirmDialog.next(data);
    }
    else if (action == PublishEvents.BOOKSTORE_SEARCH_FILTER) {
      this.searchFilterData = data;
      this._bookstoreSearchFilter.next(this.searchFilterData);
    }
    else if (action == PublishEvents.FILTER_TOGGLE) {
      this.mobileFilterToggle.next(data);
    }
    else this._signInPopup.next(action);
  }

  /**
   * add to cart
   * @param productId
   * @param type
   */

  addToCart(productId: number, type?: number) {
    if (this.globalUtility.isLoggedIn()) {
      let cartItemModel = {
        'prodID': productId,
        'qTy': 0,
        'cartSource': CheckoutFrom.CART
      };
      this._homeService.addUpdateCartItem(cartItemModel).subscribe((response) => {
        if (response.ResponseType == 'SUCCESS') {
          this.publishEvent(PublishEvents.ADD_TO_CART);
          this._commonAlertService.successToast('Item added to your cart');
          if (type === ProductType.JOURNAL) {
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
            this.globalUtility.redirectToReactCart();
          }else if (type === ProductType.BOOK) {
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
          }else if(type === ProductType.COMPLIANCE) {
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.COMPLIANCE);
          }else if(type === ProductType.RESEARCH_OFFLINE) {
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.RESEARCH_OFFLINE);
          }else if(type === ProductType.VIRTUAL_BOOK) {
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.VIRTUAL_BOOK);
          }else if(type === ProductType.VIRTUAL_JOURNAL) {
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.VIRTUAL_JOURNAL);
          }else if(productId === ProductType.RESEARCH) {
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.RESEARCH);
          }

        }
      });
    } else {
      this.setRegistrationReferrer(type);
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
		this.globalUtility.redirectToReactLogin()
    }
  }

  /**
   * buy now
   * @param productId
   * @param categoryId
   */
  buyNow(productId: number, categoryId?: number, isresearchrenewal?:number) {
    if (categoryId == ProductType.JOURNAL) {
      this.addToCart(productId, categoryId);
    } else {
      if (this.globalUtility.isLoggedIn()) {
        let cartItemModel = {
          'prodID': productId,
          'qTy': 0,
          'cartSource': CheckoutFrom.BUY_NOW
        };
        if(isresearchrenewal){
        cartItemModel['isresearchrenewal'] = isresearchrenewal;
        }
        this._homeService.addUpdateCartItem(cartItemModel).subscribe((response) => {
          if (response.ResponseType == 'SUCCESS') {
            this.publishEvent(PublishEvents.ADD_TO_CART);
            this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TYPE_FROM, CheckoutFrom.BUY_NOW);
            console.log("product id recieved", productId);
            if (categoryId === ProductType.JOURNAL) {
              this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
            }else if (categoryId === ProductType.BOOK) {
              this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.BOOKSTORE);
            }else if(categoryId === ProductType.COMPLIANCE) {
              this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.COMPLIANCE);
            }else if(categoryId === ProductType.RESEARCH_OFFLINE) {
              this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.RESEARCH_OFFLINE);
            }else if(categoryId === ProductType.VIRTUAL_BOOK) {
              this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.VIRTUAL_BOOK);
            }else if(categoryId === ProductType.VIRTUAL_JOURNAL) {
              this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.VIRTUAL_JOURNAL);
            }else if(categoryId === ProductType.RESEARCH) {
              this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, PaymentPlatformType.RESEARCH);
            }
            this.globalUtility.redirectToReactCart()
          }
        });
      } else {
        this.setRegistrationReferrer(categoryId);
        this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
		  this.globalUtility.redirectToReactLogin()
      }
    }
  }

  setRegistrationReferrer(categoryId){
    if (categoryId === ProductType.JOURNAL) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.BOOKSTORE);
    }else if (categoryId === ProductType.BOOK) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.BOOKSTORE);
    }else if(categoryId === ProductType.COMPLIANCE) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.COMPLIANCE);
    }else if(categoryId === ProductType.RESEARCH_OFFLINE) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.RESEARCH);
    }else if(categoryId === ProductType.VIRTUAL_BOOK) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.VIRTUAL_BOOKS);
    }else if(categoryId === ProductType.VIRTUAL_JOURNAL) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.VIRTUAL_BOOKS);
    }else if(categoryId === ProductType.RESEARCH) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.RESEARCH);
    } else if(categoryId === ProductType.PRACTICE) {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, RegisterPlatformType.PRACTICE);
    }

  }
  /**
   * buy Now Multiple
   * @param productIdList
   * @param type
   */
  buyNowMultiple(productIdList: any, paymentReferrer: string, isresearchrenewal?:number, env ?: string) {
    if (this.globalUtility.isLoggedIn()) {
      let cartItemModel = {}; 
      cartItemModel = {
        'prodID': 0,
        'qTy': 0,
        'cartSource': CheckoutFrom.BUY_NOW,
        'ProductList': productIdList,
      };
      if(isresearchrenewal){
        cartItemModel['isresearchrenewal'] = isresearchrenewal
      }
      if(env == 'node') {
        cartItemModel = {
          "productEditionId": productIdList,
          "cartSource": CheckoutFrom.BUY_NOW,
          "quantity": 1,
          "type": "RESEARCH"
        }
      }
      this._homeService.buySubscription(cartItemModel, env).subscribe((response) => {
        if (response.ResponseType == 'SUCCESS') {
          this.publishEvent(PublishEvents.ADD_TO_CART);
          this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TYPE_FROM, CheckoutFrom.BUY_NOW);
          this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, paymentReferrer);
          if (!this.globalUtility.getDataFromLocalStorage('checkoutData')) {
						this.globalUtility.initializeCheckoutData();
					}
					const checkoutData: CheckoutInformation = JSON.parse(this.globalUtility.getDataFromLocalStorage('checkoutData'));
					checkoutData.checkoutCart = 'GENERAL_PRODUCT'
					checkoutData.cartSource = 'B'
					checkoutData.type = ReactCartProductType.RESEARCH
          if(isresearchrenewal) {
            checkoutData.showChangePlanBtn = 'Y'
          }
					this.globalUtility.setDataOnLocalStorage('checkoutData', JSON.stringify(checkoutData));
          this.globalUtility.redirectToReactCart()
        }
      });
    } else {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, paymentReferrer);
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
		this.globalUtility.redirectToReactLogin()
    }
  }

    buyNowMultipleCheckout(productIdList: any, paymentReferrer: string, isresearchrenewal?:number) {
    if (this.globalUtility.isLoggedIn()) {
      let cartItemModel = {
        'prodID': 0,
        'qTy': 0,
        'cartSource': CheckoutFrom.BUY_NOW,
        'ProductList': productIdList,
      };
      if(isresearchrenewal){
        cartItemModel['isresearchrenewal'] = isresearchrenewal
      }
      this._homeService.buySubscription(cartItemModel).subscribe((response) => {
        if (response.ResponseType == 'SUCCESS') {
          this.publishEvent(PublishEvents.ADD_TO_CART);
          this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TYPE_FROM, CheckoutFrom.BUY_NOW);
          this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PAYMENT_REFERRER, paymentReferrer);
          if (!this.globalUtility.getDataFromLocalStorage('checkoutData')) {
						this.globalUtility.initializeCheckoutData();
					}
					const checkoutData: CheckoutInformation = JSON.parse(this.globalUtility.getDataFromLocalStorage('checkoutData'));
					checkoutData.checkoutCart = 'GENERAL_PRODUCT'
					checkoutData.cartSource = 'B'
					checkoutData.type = ReactCartProductType.RESEARCH
					this.globalUtility.setDataOnLocalStorage('checkoutData', JSON.stringify(checkoutData));
          // this.router.navigate(['store/checkout']);
          this.globalUtility.redirectToReactCart();
        }
      });
    } else {
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.REGISTRATION_REFERRER, paymentReferrer);
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
      // this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, 'store/checkout');
      this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TYPE_FROM, CheckoutFrom.BUY_NOW);
      // let ppcPracticeInterestedModulesData = productIdList.join(',')
      // this.globalUtility.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.PPC_PRACTICE_INTERESTED_MODULES_DATA, JSON.stringify(productIdList), 15 * 60 * 1000 );
    //  this.globalUtility.setItemInSessionStorege(LocalStorageDataModel.CHECKOUT_DATA, productIdList.join());
      // this.router.navigate(['auth/login']);
      this.globalUtility.redirectToReactLogin();
    }
  }

}
