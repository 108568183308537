import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalUtility } from '../utility/global-utility';
import { LocalStorageDataModel } from '../../constants/local-storage-data-model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private globalUtility: GlobalUtility) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
      return true;
    }else if((this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') && this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)){
      return true;
  } else{
    window.open('gp/auth/login'+`?returnUrl=${state.url}`, '_self');
      return false;
    }
   
  }


}