import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxmannUrl } from 'src/app/api-url/taxmann-url.enum';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { APIResponse } from '../../models/interfaces/api-response';
import { HttpHelperService } from '../http-helper.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceType } from 'src/constants/constant';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceTrackingService {

  constructor(private _httpHelperService:HttpHelperService,private globalUtility :GlobalUtility,     private deviceService: DeviceDetectorService    ) {}

   postUserSiteTracking(body: any): Observable<APIResponse> {
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.USER_SITE_TRACKING);
    return this._httpHelperService.post(url, body, true);
  }

  getUserSiteTrackingData(fileId ?: string): any {
    let deviceType =  this.deviceService.isDesktop() ? DeviceType.DESKTOP : this.deviceService.isTablet() ? DeviceType.TABLET : this.deviceService.isMobile() ? DeviceType.MOBILE : ''  ;
    let componentLocation = decodeURIComponent(location.href).replace(/'/g, "");
    return  {
			"ipAddress": this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP),
			"userAgent": this.deviceService.getDeviceInfo()['userAgent'],
			"device": this.deviceService.device,
			"os": this.deviceService.os,
			"osVersion": this.deviceService.os_version,
			"browser": this.deviceService.browser,
			"browserVersion": this.deviceService.browser_version,
			"deviceType": deviceType,
			"componentURL": fileId ? componentLocation + `/${fileId}` : componentLocation
    }
  }

}
