import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxmannNewsUpdateComponent } from './taxmann-news-update.component';
import { TaxmannCarouselModule } from '../modules/taxmann-carousel/taxmann-carousel.module';
import { TaxmannCommonModule } from '../taxmann-common/taxmann-common.module';
import { SignedLandingService } from '../research/modules/signed-landing/signed-landing.service';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [TaxmannNewsUpdateComponent],
  imports: [
    CommonModule,
    TaxmannCarouselModule,
    TaxmannCommonModule,
    RouterModule
  ],
  exports:[TaxmannNewsUpdateComponent],
  providers:[SignedLandingService]
})
export class TaxmannNewsUpdateModule { }
