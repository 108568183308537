import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SafePipeModule } from 'safe-pipe';
import { InputTextModule } from 'primeng/inputtext';

import { GlobalSearchComponent } from './global-search.component';
import { BookstoreComponent } from './bookstore-search/bookstore-search.component';

import { TaxmannCommonModule } from 'src/app/taxmann-common/taxmann-common.module';
import { ResearchSearchComponent } from './research-search/research-search.component';
import { VirtualSearchComponent } from './virtual-search/virtual-search.component';
import { TaxpracticeSearchComponent } from './taxpractice-search/taxpractice-search.component';
import { TaxPracticeSuggestionService } from "./services/tax-practice-suggestions.service";
import { JoyrideModule } from 'ngx-joyride';

@NgModule({
	declarations: [
		GlobalSearchComponent,
		BookstoreComponent,
		ResearchSearchComponent,
		VirtualSearchComponent,
		TaxpracticeSearchComponent
	],
	imports: [
		CommonModule,
		SafePipeModule,
		InputTextModule,
		ReactiveFormsModule,
		TaxmannCommonModule,
		JoyrideModule.forChild()
	],
	exports: [
		GlobalSearchComponent
	],
	providers: [
		TaxPracticeSuggestionService
	]
})
export class GlobalSearchModule { }
