import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxmannBannerComponent } from './taxmann-banner.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TaxmannBannerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [TaxmannBannerComponent]
})
export class TaxmannBannerModule { }
